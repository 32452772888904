import axiosClient from "utils/api";

export const getMe = async () => {
  try {
    const response = await axiosClient().request({
      url: "/admin/auth/getProfile",
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error at Get ME Service", error);
    throw error;
  }
};

export const getMeForWeb = async () => {
  try {
    const response = await axiosClient().request({
      url: "/profile/getMyProfile",
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error at Get ME Service For Web", error);
    throw error;
  }
};

export const updateMeForWeb = async (payload) => {
  try {
    const response = await axiosClient().request({
      url: "/profile/editProfile",
      method: "put",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error at Update ME Service For Web", error);
    throw error;
  }
};

export const updateMileage = async (payload) => {
  try {
    const response = await axiosClient().request({
      url: "/profile/mileage",
      method: "put",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error at Update ME Service For Web", error);
    throw error;
  }
};

export const deleteAccount = async (payload) => {
  try {
    let { id, ...rest } = payload;
    const response = await axiosClient().request({
      url: `/profile/deleteAccount/${id}`,
      method: "delete",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error at Update ME Service For Web", error);
    throw error;
  }
};

export const updateMe = async (payload) => {
  let { id, ...rest } = payload;
  try {
    const response = await axiosClient().request({
      url: `/admin/auth/updateProfile/${id}`,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error at Update Account Service", error);
    throw error;
  }
};

export const changePassword = async (payload) => {
  try {
    const response = await axiosClient().request({
      url: "/admin/auth/changePassword",
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error at Change Password Service", error);
    throw error;
  }
};

export const getGallery = async () => {
  try {
    const response = await axiosClient().request({
      url: "/profile/gallery",
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error at Get Gallery", error);
    throw error;
  }
};

export const addGallery = async (payload) => {
  try {
    const response = await axiosClient().request({
      url: "/profile/gallery",
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error at Add Gallery", error);
    throw error;
  }
};

export const deleteGallery = async (payload) => {
  try {
    const response = await axiosClient().request({
      url: `/profile/gallery/${payload.id}`,
      method: "delete",
    });
    return response.data;
  } catch (error) {
    console.error("Error at Delete Gallery", error);
    throw error;
  }
};

export const getRevenue = async (payload) => {
  try {
    const response = await axiosClient().request({
      url: "/profile/revenue",
      method: "get",
      params: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error at Get Gallery", error);
    throw error;
  }
};

export const getEarnings = async (payload) => {
  try {
    const response = await axiosClient().request({
      url: "/profile/professionalEarnings",
      method: "get",
      params: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error at Get Gallery", error);
    throw error;
  }
};

export const getEarningDetail = async (payload) => {
  try {
    const response = await axiosClient().request({
      url: `/profile/professionalEarningsDetails/${payload.id}`,
      method: "get",
      params: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error at Get Earning Details", error);
    throw error;
  }
};
