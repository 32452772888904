import React, { useEffect, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import MuiBox from "../MuiBox";
import MuiTypography from "../MuiTypography";
import FormError from "../FormError";

const CustomDatePicker = ({
  label,
  value,
  onChange,
  views,
  error,
  errorMsg,
  onError,
  required = true,
  format = "",
  ...rest
}) => {
  const [showMsg, setShowMsg] = useState("");

  useEffect(() => {
    if (error) {
      setShowMsg(errorMsg);
    } else {
      setShowMsg("");
    }
  }, [error, errorMsg]);
  // Default to all views if none are provided
  const resolvedViews = views || ["year", "month", "day"];

  // Determine the appropriate format based on resolvedViews
  const getFormat = () => {
    if (resolvedViews.length === 3) return "DD-MM-YYYY"; // Full date format
    if (resolvedViews.includes("day") && resolvedViews.includes("month"))
      return "DD MMMM"; // Day and month
    if (resolvedViews.includes("month") && resolvedViews.includes("year"))
      return "MMMM YYYY"; // Month and year
    if (resolvedViews.includes("day")) return "DD"; // Only day
    if (resolvedViews.includes("month")) return "MMMM"; // Only month
    if (resolvedViews.includes("year")) return "YYYY"; // Only year
    return "DD MMMM YYYY"; // Fallback to full date
  };

  return (
    <MuiBox>
      {label && (
        <MuiTypography variant="baseFont" fontWeight="bold">
          {label} {required && <span style={{ color: "red" }}>*</span>}
        </MuiTypography>
      )}
      <MuiBox sx={{ mt: label ? "8px" : "0" }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            className="date-picker-custom"
            value={value}
            slotProps={{
              textField: {
                error: error,
              },
            }}
            onChange={onChange}
            views={resolvedViews}f
            format={format ? format : getFormat()}
            onError={(e) => {
              if (onError) {
                onError(e);
              }
              if (
                e === "invalidDate" ||
                e === "disableFuture" ||
                e === "disablePast"
              ) {
                setShowMsg("Please enter valid Date");
              } else if (e === "maxDate " || e === "minDate") {
                setShowMsg("Invalid Date Range");
              } else {
                setShowMsg(errorMsg);
              }
            }}
            sx={{
              bgcolor: "#fff",
              borderRadius: "12px",
              width: "100%",
            }}
            {...rest}
          />
          {showMsg && <FormError errorMsg={showMsg} />}
        </LocalizationProvider>
      </MuiBox>
    </MuiBox>
  );
};

export default CustomDatePicker;
