import React from "react";
import MuiBox from "../MuiBox";
import { Button, IconButton } from "@mui/material";
import MuiTypography from "../MuiTypography";
import blankImg from "assets/images/blank.png";
import { useDispatch } from "react-redux";
import { openDrawer } from "store/slices/common/drawer.slice";
import { EmojiReviewsModal } from "components/molecules";
import MsgDots from "assets/images/SVG/msgDots.svg";

const ChatButton = ({ user, onClick, chatSelected }) => {
  const dispatch = useDispatch();
  return (
    <MuiBox
      key={user?.id}
      component={Button}
      onClick={onClick}
      sx={{
        p: { xs: " 15px" },
        display: "flex",
        alignItems: "center",
        gap: "10px",
        bgcolor: "#fff",
        borderRadius: "12px",
        border : chatSelected === user?.id ? "1px solid #0ca8df"  : "1px solid #fff"
      }}
    >
      <MuiBox
        sx={{
          width: "50px",
          height: "50px",
          overflow: "hidden",
          borderRadius: "50%",
          flexShrink: 0,
        }}
      >
        <img
          src={
            user?.adminProfileImageUrl ? user?.adminProfileImageUrl : blankImg
          }
          className="img-cover"
        />
      </MuiBox>
      <MuiBox sx={{ display: "flex", flexDirection: "column" }}>
        <MuiBox
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <MuiTypography
            variant="baseFont"
            color="#000000"
            fontWeight="bold"
            lineHeight="22px"
          >
            {user?.adminName ?? "Admin"}
          </MuiTypography>
        </MuiBox>
        <MuiBox
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
          }}
        >
          <MuiTypography fontSize="13px" color="sliderText.light">
            {user?.lastMessage}
          </MuiTypography>
          {!user?.expeirence && (
            <IconButton
              sx={{ p: "4px" }}
              onClick={() =>
                dispatch(
                  openDrawer({
                    content: <EmojiReviewsModal type="deleteMessage" chatId={user?.id} />,
                    width: "500px",
                  })
                )
              }
            >
              <img src={MsgDots} />
            </IconButton>
          )}
        </MuiBox>
      </MuiBox>
    </MuiBox>
  );
};

export default ChatButton;
