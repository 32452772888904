import React from "react";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { Input, SubmitBtn } from "components/atoms";
import { AddLanguageAction, UpdateLanguageAction } from "store/middlewares/language";
import { Name } from "utils/validation/AddLanguage";

function ModalEditLanguage({ row }) {
  const dispatch = useDispatch();

  const AddSpecialitySchema = Yup.object({
    language: Name,
  });

  const handleSubmit = (values) => {
    if (row) {
      dispatch(UpdateLanguageAction({ id: row.id, ...values }));
    } else {
      dispatch(AddLanguageAction(values));
    }
  };

  const formik = useFormik({
    initialValues: {
      language: row?.language ? row?.language : "",
    },
    validationSchema: AddSpecialitySchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Input
        inputClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 mt-2"
        label={"Name"}
        error={formik.errors.language && formik.touched.language}
        errorMsg={formik.errors.language}
        value={formik.values.language}
        type="text"
        name="language"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        placeholder="Enter Language"
      />
      <SubmitBtn title={row ? "Update" : "Add"} />
    </form>
  );
}

export default ModalEditLanguage;
