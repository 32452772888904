import React, { useMemo } from "react";
import {
    Chart as ChartJS,
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Tooltip,
    Legend,
    Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import dayjs from "dayjs";

// Register Chart.js components, including Filler
ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend, Filler);

const LineChart = ({ revenueType, revenueData }) => {
    const dynamicLabels = useMemo(() => {
        if (revenueType === "weekly") {
            return ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        } else if (revenueType === "monthly") {
            const currentDate = dayjs();
            const daysInMonth = currentDate.daysInMonth();
            return Array.from({ length: daysInMonth }, (_, i) => `Day ${i + 1}`);
        } else if (revenueType === "yearly") {
            return ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        } else {
            return [];
        }
    }, [revenueType]);

    const dynamicDatasetLabel = useMemo(() => {
        if (revenueType === "weekly") return "Daily";
        if (revenueType === "monthly") return "Monthly";
        if (revenueType === "yearly") return "Yearly";
        return "Revenue";
    }, [revenueType]);

    // Use useMemo to create the data object
    const data = useMemo(() => {
        return {
            labels: dynamicLabels,
            datasets: [
                {
                    label: dynamicDatasetLabel,
                    data: [revenueData?.map((data) => data.revenue)],
                    borderColor: "#FF3030",
                    backgroundColor: (context) => {
                        const ctx = context.chart.ctx;
                        const gradient = ctx.createLinearGradient(0, 0, 0, context.chart.height);
                        gradient.addColorStop(0, "rgba(126, 72, 193, 0.3)");
                        gradient.addColorStop(1, "rgba(254, 86, 89, 0.3)");
                        return gradient;
                    },
                    fill: "start",
                    pointStyle: "circle",
                    pointRadius: 8,
                    pointHoverRadius: 12,
                    pointBackgroundColor: "#FFFFFF",
                    pointBorderColor: "#FF3030",
                    pointBorderWidth: 2,
                },
            ],
        };
    }, [dynamicLabels, dynamicDatasetLabel]);

    // Chart options with boundaries
    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
            },
            tooltip: {
                enabled: true,
            },
        },
        scales: {
            y: {
                min: 10,
                max: 100,
                grid: {
                    borderDash: [5, 5],
                    color: "rgba(200, 200, 200, 0.5)",
                },
            },
            x: {
                grid: {
                    borderDash: [5, 5],
                    color: "rgba(200, 200, 200, 0.5)",
                },
            },
        },
    };

    return <Line data={data} options={options} />;
};

export default LineChart;
