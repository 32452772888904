import { openToast } from "store/slices/common/toast.slice";
import { getAllSettings, updateSettings,getWebSettings } from "store/services/settings";

import { createAsyncThunk } from "@reduxjs/toolkit";
import { handleAuthError } from "utils/handleAuthError";

export const GetAllSettingsAction = createAsyncThunk(
  "settings/getSettings",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getAllSettings(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

export const GetWebSettingsAction = createAsyncThunk(
  "settings/getWebSettings",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getWebSettings(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      handleAuthError(error?.response?.status, true);
      return rejectWithValue(error);
    }
  }
);

export const UpdateSettingsAction = createAsyncThunk(
  "settings/updateSettings",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await updateSettings(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(openToast({ type: "success", message: response?.message }));
        return fulfillWithValue(response);
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.response?.data ? error?.response?.data?.message : error?.message }));
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);
