import React from "react";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { description, Rate, Title } from "utils/validation/AddJobTitle";
import { AddJobTitleAction, UpdateJobTitleAction } from "store/middlewares/jobTitle";
import { useFormik } from "formik";
import { Input, SubmitBtn } from "components/atoms";

function ModalEditJobTitle({ row }) {
  const dispatch = useDispatch();

  const EditJobTitleSchema = Yup.object({
    title: Title,
    rate: Rate,
    description: description,
  });

  const handleSubmit = (values) => {
    if (row) {
      dispatch(UpdateJobTitleAction({ id: row.id, ...values }));
    } else {
      dispatch(AddJobTitleAction(values));
    }
  };

  const formik = useFormik({
    initialValues: {
      title: row?.title ? row?.title : "",
      rate: row?.rate ? row?.rate : "",
      description: row?.description ? row?.description : '',
    },
    validationSchema: EditJobTitleSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Input
        inputClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 mt-2"
        label={"Job Title"}
        type="text"
        name="title"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.title && formik.touched.title}
        errorMsg={formik.errors.title}
        value={formik.values.title}
        placeholder="Enter Title"
      />
      <Input
        step=".01"
        className={"!py-0"}
        inputClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 mt-2"
        label="Rate"
        type="number"
        name="rate"
        placeholder="Enter rate"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik?.errors?.rate && formik?.touched?.rate}
        errorMsg={formik.errors.rate}
        value={formik.values?.rate}
      />
      <Input
        inputClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 mt-2"
        label={"Tooltip"}
        type="text"
        name="description"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.description && formik.touched.description}
        errorMsg={formik.errors.description}
        value={formik.values.description}
        placeholder="Enter tooltip"
      />
      <SubmitBtn title={row ? "Update" : "Add"} />
    </form>
  );
}

export default ModalEditJobTitle;
