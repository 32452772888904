// Function to format date as "dd/mm/yyyy"
export const FormatDate = (dateString) => {
  const date = new Date(dateString);
  const day = `0${date.getUTCDate()}`.slice(-2);
  const month = `0${date.getUTCMonth() + 1}`.slice(-2);
  const year = date.getUTCFullYear();
  return `${day}/${month}/${year}`;
}

export const FormatDateReverce = (dateString) => {
  const date = new Date(dateString);
  const day = `0${date.getUTCDate()}`.slice(-2);
  const month = `0${date.getUTCMonth() + 1}`.slice(-2);
  const year = date.getUTCFullYear();
  return `${year}-${month}-${day}`;
}
