import { MuiBox, MuiTypography } from "components/atoms";
import React from "react";
import { Link } from "react-router-dom";
import { IconButton } from "@mui/material";

import ApplicantImg from "assets/images/PNG/applicant2.png";
import ChatIcon from "assets/images/SVG/Bubble chat.svg";
import PhoneIcon from "assets/images/SVG/Smartphone.svg";
import CalanderBlack from "assets/images/SVG/CalendarBlack.svg";
const ApplicantHiredCard = () => {
  return (
    <MuiBox
      component={Link}
      to="/hospital/professional/application-details"
      sx={{
        borderRadius: "12px",
        bgcolor: "#fff",
        p: { xs: "8px", sm: "12px" },
        display: "flex",
        flexDirection: "column",
        gap: "12px",
      }}
    >
      <MuiBox
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "12px",
          flexWrap: "wrap",
        }}
      >
        <MuiBox
          sx={{
            width: "115px",
            height: "115px",
            borderRadius: "9px",
            overflow: "hidden",
            flexShrink: 0,
          }}
        >
          <img src={ApplicantImg} alt="Applicant" className="img-cover" />
        </MuiBox>
        <MuiBox
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            maxWidth: "11em",
          }}
        >
          <MuiTypography color="text.main" variant="baseFont" fontWeight="bold">
            Aspen Press
          </MuiTypography>
          <MuiTypography color="text.contrastText" fontSize="14px">
            MA (Medical Assistant)
          </MuiTypography>
          <MuiBox sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <MuiBox
              sx={{
                width: "fit-content",
                p: "5px 12px",
                bgcolor: "#007AFF26",
                borderRadius: "30px",
              }}
            >
              <MuiTypography
                color="#2D2635"
                fontSize="12px"
                sx={{ whiteSpace: "nowrap" }}
              >
                Patient safety
              </MuiTypography>
            </MuiBox>
            <MuiBox
              sx={{
                width: "fit-content",
                p: "5px 12px",
                bgcolor: "#007AFF26",
                borderRadius: "30px",
              }}
            >
              <MuiTypography
                color="#2D2635"
                fontSize="12px"
                sx={{ whiteSpace: "nowrap" }}
              >
                Full Time
              </MuiTypography>
            </MuiBox>
          </MuiBox>
        </MuiBox>
      </MuiBox>
      <MuiBox sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <MuiBox sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <MuiBox
            sx={{
              height: "42px",
              width: "42px",
              bgcolor: "#E8F0F3",
              flexShrink: 0,
              borderRadius: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img src={CalanderBlack} />
          </MuiBox>
          <MuiBox
            sx={{
              display: "flex",
              gap: "1px",
              maxWidth: "8em",
              flexDirection: "column",
            }}
          >
            <MuiTypography color="text.main" fontSize="15px" fontWeight="bold">
              07/03/2024
            </MuiTypography>
            <MuiTypography
              color="text.contrastText"
              fontSize="13px"
              variant="baseFont"
            >
              07:00 AM - 11:00 AM
            </MuiTypography>
          </MuiBox>
        </MuiBox>
        <MuiBox sx={{ display: "flex", gap: "12px", width: "fit-content" }}>
          <IconButton
            aria-label="phone"
            sx={{
              height: "30px",
              width: "30px",
              bgcolor: "darkButton.main",
              transition: "all 0.3s",
              borderRadius: "6px",
              overflow: "hidden",
              p: "5px",
              "&:hover": { bgcolor: "#2f3f5a", borderRadius: "50%" },
            }}
          >
            <img src={PhoneIcon} />
          </IconButton>
          <IconButton
            aria-label="phone"
            sx={{
              height: "30px",
              width: "30px",
              bgcolor: "darkButton.main",
              transition: "all 0.3s",
              borderRadius: "6px",
              overflow: "hidden",
              p: "5px",
              "&:hover": { bgcolor: "#2f3f5a", borderRadius: "50%" },
            }}
          >
            <img src={ChatIcon} />
          </IconButton>
        </MuiBox>
      </MuiBox>
    </MuiBox>
  );
};

export default ApplicantHiredCard;
