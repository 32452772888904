import React from "react";
import CongratulationsIcon from "../../../assets/images/SVG/CongratulationsIcon.svg";
import { SubmitBtn } from "components/atoms";
import { useDispatch } from "react-redux";
import { closeAdminModal } from "store/slices/common/adminModal.slice";

function SuccessModalAdmin({ msg, onClick }) {
  const dispatch = useDispatch();

  return (
    <>
      <div>
        <div className="flex justify-center">
          <img
            src={CongratulationsIcon}
            className="size-[200px]"
            alt="Congratulations"
          />
        </div>
        <div>
          <p className="text-center text-[22px] font-semibold">{msg}</p>
        </div>
        <div
          className="flex justify-center mt-5"
          onClick={() => {
            if (onClick) {
              onClick(); // Execute the additional onClick logic if provided
            }
            dispatch(closeAdminModal()); // Dispatch close modal action
          }}
        >
          <SubmitBtn title={"Ok, got it!"} />
        </div>
      </div>
    </>
  );
}

export default SuccessModalAdmin;
