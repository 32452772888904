import { createSlice } from "@reduxjs/toolkit";
import { GetAllSettingsAction, GetWebSettingsAction,UpdateSettingsAction } from "store/middlewares/settings/settings";

export const settingsSlice = createSlice({
  name: "settings",
  initialState: {
    settingsLoading: false,
    items: null,
  },
  extraReducers(builder) {
    builder
      // Get All Settings
      .addCase(GetAllSettingsAction.pending, (state) => {
        state.settingsLoading = true;
        state.items = null;
      })
      .addCase(GetAllSettingsAction.fulfilled, (state, action) => {
        state.settingsLoading = false;
        state.items = action.payload.response;
      })
      .addCase(GetAllSettingsAction.rejected, (state) => {
        state.settingsLoading = false;
        state.items = null;
      })

      // Get Web All Settings
      .addCase(GetWebSettingsAction.pending, (state) => {
        state.settingsLoading = true;
        state.items = null;
      })
      .addCase(GetWebSettingsAction.fulfilled, (state, action) => {
        state.settingsLoading = false;
        state.items = action.payload.response;
      })
      .addCase(GetWebSettingsAction.rejected, (state) => {
        state.settingsLoading = false;
        state.items = null;
      })
      
      // Update Settings
      .addCase(UpdateSettingsAction.fulfilled, (state, action) => {
        let { items } = state;
        state.items = { ...items, ...action.payload.response };
      });
  },
});

export default settingsSlice.reducer;
