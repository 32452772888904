import React from "react";
import CountUp from "react-countup";
import SimplePie from "../SimplePie";
import { Hospital, SquareUserRound } from "lucide-react";

const DashboardSection = ({ title, dashbordData }) => {
  const { totalHospitalUsers, totalProfessionalUsers } = dashbordData;
  return (
    <>
      <div className="card">
        <div className="bg-slate-200 card-body rounded-t-md">
          <h6 className="text-15 font-bold">Total Users</h6>
        </div>
        <div className="card-body">
          <div className="grid grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-2 items-center">
            <div className="flex flex-col gap-6 justify-between">
              <div className="p-6 text-center flex items-center justify-between bg-orange-100 border border-orange-200 rounded-md">
                <div className="flex items-center justify-center size-12 rounded-md bg-orange-50 text-15 d">
                  <Hospital className="text-orange-500  " />
                </div>
                <div className="flex flex-col items-end">
                  <h4 className="mb-1 text-orange-500 text-4xl">
                    <CountUp
                      className="counter-value"
                      end={totalHospitalUsers}
                    />
                  </h4>
                  <p className="mb-0 text-orange-500">{`HOSPITAL ${title}`}</p>
                </div>
              </div>
              <div className="p-6 text-center flex items-center justify-between bg-blue-100 border border-blue-200 rounded-md">
                <div className="flex items-center justify-center size-12 rounded-md bg-blue-50 text-15 d">
                  <SquareUserRound className="text-blue-500" />
                </div>
                <div className="flex flex-col items-end">
                  <h4 className="mb-1 text-blue-500 text-4xl">
                    <CountUp
                      className="counter-value"
                      end={totalProfessionalUsers}
                    />
                  </h4>
                  <p className="mb-0 text-blue-500">{`PROFESSIONAL ${title}`}</p>
                </div>
              </div>
            </div>
            <div>
              <SimplePie
                chartId="simplePie"
                dataSeries={[
                  totalHospitalUsers ? totalHospitalUsers : 0,
                  totalProfessionalUsers ? totalProfessionalUsers : 0,
                ]}
                dataLabelsChart={["Hospital Users", "Professional Users"]}
                dataColorCodes='["bg-orange-200","bg-blue-200"]'
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardSection;
