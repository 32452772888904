import { MuiBox, MuiTypography, Textfield } from "components/atoms";
import React, { useState } from "react";
import { Button, Checkbox } from "@mui/material";

import FilledStar from "assets/images/SVG/filled star.svg";
import NullStar from "assets/images/SVG/null star.svg";
import Reviews from "assets/images/SVG/reviews img.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { GiveReviewsProfessionalAction } from "store/middlewares/users/professionals";
const ReviewsModal = ({ reviewData }) => {
  const dispatch = useDispatch();

  // For Rating
  const [rating, setRating] = useState(1);
  const handleRatingChange = (value) => {
    setRating(value);
  };

  // Validation Schema using Yup
  const validationSchema = Yup.object({
    // rating: Yup.number().required("Rating is required").min(1, "Minimum rating is 1").max(5, "Maximum rating is 5"),
    comment: Yup.string().required("Comments are required"),
  });

  const formik = useFormik({
    initialValues: {
      comment: "",
    },
    validationSchema,
    onSubmit: (values) => {
      let tempObj = {
        ...values,
        jobId: reviewData?.jobId,
        professionalId: reviewData?.professionalId,
        ratings: rating,
      }
      dispatch(GiveReviewsProfessionalAction(tempObj));
      console.log(tempObj, "tempObj");

    },
  })
  return (
    <MuiBox
      component="form"
      onSubmit={formik.handleSubmit}
      sx={{
        px: { xs: "15px", sm: "30px", md: "60px" },
        py: 5,
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      <MuiTypography textAlign="center" variant="h6" fontWeight="bold">
        Reviews
      </MuiTypography>
      <MuiBox sx={{ mt: { xs: 2, sm: 5, md: 10 } }}>
        <MuiBox sx={{ maxWidth: "276px", m: "0 auto" }}>
          <img src={Reviews} />
        </MuiBox>
      </MuiBox>
      <MuiBox
        sx={{
          mt: 3,
          bgcolor: "#fff",
          borderRadius: "12px",
          p: "3px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {[1, 2, 3, 4, 5].map((star) => (
          <Checkbox
            key={star}
            icon={<img src={NullStar} alt={`Star ${star}`} />}
            checkedIcon={<img src={FilledStar} alt={`Star ${star}`} />}
            checked={rating >= star}
            onChange={() => handleRatingChange(star)}
          />
        ))}
      </MuiBox>

      <MuiBox sx={{ mt: 2 }}>
        <Textfield
          label={"Comments/information"}
          className={"cancel-modal-textfield"}
          rows={6}
          name={"comment"}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.comment}
          errorMsg={formik.errors.comment}
          error={formik.touched.comment && formik.errors.comment}
        />
      </MuiBox>

      <MuiBox sx={{ textAlign: "center", mt: 2 }}>
        <Button type="submit" variant="lightButton" sx={{ maxWidth: "350px" }}>
          Send
        </Button>
      </MuiBox>
    </MuiBox>
  );
};

export default ReviewsModal;
