import React, { useCallback, useEffect, useState } from "react";
import debounceFunc from "utils/debounceFunc";
import isElementInViewport from "utils/isElementInViewport";

const InfiniteScrolling = ({
  children,
  updateState,
  items,
  loadingState,
  totalPages,
}) => {
  const [page, setPage] = useState(1);

  useEffect(() => {  
    updateState(page);
  }, [page]);

  // Infinite scroll handler
  const handleScroll = useCallback(() => {
    if (
      document.getElementById("refresh-button") &&
      isElementInViewport(document.getElementById("refresh-button")) &&
      !loadingState
    ) {
      if (!totalPages) {
        setPage((prevPage) => prevPage + 1);
      }
    }
  }, [items, loadingState, totalPages]);

  const debouncedScrollHandler = useCallback(debounceFunc(handleScroll, 50), [
    handleScroll,
  ]);

  // Add and remove scroll event listener
  useEffect(() => {
    window.addEventListener("scroll", debouncedScrollHandler);
    return () => window.removeEventListener("scroll", debouncedScrollHandler);
  }, [debouncedScrollHandler]);

  return <div>{children}</div>;
};

export default InfiniteScrolling;
