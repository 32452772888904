import { Typography } from "@mui/material";
import { GetUserAllTransHistory } from "store/middlewares/transactions";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { CustomLoader, TableContainer } from "components/atoms";

const HospitalGalleryTab = ({ id }) => {
  const { transactions, transactionsLoading } = useSelector(
    (state) => state.me
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      dispatch(GetUserAllTransHistory({ id }));
    }
  }, [dispatch, id]);
  const columns = React.useMemo(
    () => [
      {
        header: "No.",
        cell: ({ row }) => <Typography variant="p">{row.index + 1}</Typography>,
      },
      {
        header: "Transaction Id",
        accessorKey: "transactionNo",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Professional Name",
        accessorKey: "JobTimeSchedule.professionalTimeSchedule.name",
        enableColumnFilter: false,
        enableSorting: true,
        cell: ({ row }) => (
          <Typography variant="p" className="capitalize">
            {`${row?.original?.JobTimeSchedule?.professionalTimeSchedule?.name} ${row?.original?.JobTimeSchedule?.professionalTimeSchedule?.lastName}`}
          </Typography>
        ),
      },
      {
        header: "Payment Type",
        accessorKey: "paymentType",
        enableColumnFilter: false,
        enableSorting: true,
        cell: ({ row }) => (
          <Typography variant="p" className="capitalize">
            {row?.original?.paymentType ? row?.original?.paymentType : "N/A"}
          </Typography>
        ),
      },
      {
        header: "Transaction Type",
        accessorKey: "transactionType",
        enableColumnFilter: false,
        enableSorting: true,
        cell: ({ row }) => (
          <Typography variant="p" className="capitalize">
            {row?.original?.transactionType
              ? row?.original?.transactionType
              : "N/A"}
          </Typography>
        ),
      },
      {
        header: "Shift Id",
        accessorKey: "JobTimeSchedule.shiftId",
        enableColumnFilter: false,
        enableSorting: true,
        cell: ({ row }) => (
          <p>
            {row.original?.JobTimeSchedule?.shiftId
              ? row.original.JobTimeSchedule?.shiftId
              : "N/A"}
          </p>
        ),
      },
      {
        header: "Credit",
        accessorKey: "credit",
        enableColumnFilter: false,
        enableSorting: true,
        cell: ({ row }) => (
          <p>
            {row.original?.credit ? row.original.credit.toFixed(2) : "0.00"}
          </p>
        ),
      },

      {
        header: "Debit",
        accessorKey: "debit",
        enableColumnFilter: false,
        enableSorting: true,
        cell: ({ row }) => (
          <p>{row.original?.debit ? row.original.debit.toFixed(2) : "0.00"}</p>
        ),
      },
    ],
    []
  );

  return (
    <div className="Hospital-User-page h-full w-full">
      {!transactionsLoading ? (
        <div className="card mt-5">
          <div className="card-body p-3">
            <TableContainer
              isPagination={true}
              isSelect={true}
              isGlobalFilter={true}
              columns={columns}
              data={transactions ? transactions : []}
              customPageSize={10}
              divclassName="my-2 col-span-12 overflow-x-auto lg:col-span-12"
              tableclassName="hover group dataTable w-full text-sm align-middle whitespace-nowrap no-footer"
              theadclassName="border-b border-slate-200 "
              trclassName="group-[.stripe]:even:bg-slate-50 group-[.stripe]:dark:even:bg-zink-600 transition-all duration-150 ease-linear group-[.hover]:hover:bg-slate-50 [&.selected]:bg-custom-500  [&.selected]:text-custom-50 "
              thclassName="p-3 group-[.bordered]:border group-[.bordered]:border-slate-200 sorting px-3 py-4 text-slate-900 bg-slate-200/50 font-semibold text-left"
              tdclassName="p-3 group-[.bordered]:border group-[.bordered]:border-slate-200 max-w-[200px] overflow-hidden text-wrap overflow-break-word"
              PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
            />
          </div>
        </div>
      ) : (
        <CustomLoader />
      )}
    </div>
  );
};

export default HospitalGalleryTab;
