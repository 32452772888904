import React from "react";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { Input, SubmitBtn } from "components/atoms";
import { Code, Name } from "utils/validation/AddCountry";
import {
  AddCountryAction,
  UpdateCountryAction,
} from "store/middlewares/countries";

function ModalEditCountries({ row }) {
  const dispatch = useDispatch();

  const EditCategorySchema = Yup.object({ name: Name, code: Code });

  const handleSubmit = (values) => {
    if (row) {
      dispatch(UpdateCountryAction({ id: row.id, ...values }));
    } else {
      dispatch(AddCountryAction(values));
    }
  };

  const formik = useFormik({
    initialValues: {
      name: row?.name ? row?.name : "",
      code: row?.code ? row?.code : "",
    },
    validationSchema: EditCategorySchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Input
        inputClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 mt-2"
        label={"Name"}
        type="text"
        name="name"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.name && formik.touched.name}
        errorMsg={formik.errors.name}
        value={formik.values.name}
        placeholder="Enter Name"
      />
      <Input
        inputClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 mt-2"
        label={"Code"}
        type="text"
        name="code"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.code && formik.touched.code}
        errorMsg={formik.errors.code}
        value={formik.values.code}
        placeholder="Enter code"
      />
      <SubmitBtn title={row ? "Update" : "Add"} />
    </form>
  );
}

export default ModalEditCountries;
