import { MuiBox, MuiTypography } from "components/atoms";
import React, { useEffect, useState } from "react";

import LocationIcon from "assets/images/SVG/LocationIcon.svg";
import Hospital1 from "assets/images/PNG/hospital1.png";
import { Link } from "react-router-dom";
const HospitalCard = ({ hospital }) => {
  const [defaultAddress, setDefaultAddress] = useState(null);
  useEffect(() => {
    setDefaultAddress(hospital?.addressDetails);
  }, [hospital]);

  return (
    <MuiBox
      component={Link}
      to={`/professional/hospital-details/${hospital?.id}`}
      sx={{
        p: { xs: "10px", sm: "15px" },
        bgcolor: "#fff",
        borderRadius: "20px",
        display: "flex",
        flexDirection: "column",
        gap: "12px",
      }}
    >
      <MuiBox
        sx={{ height: "180px", borderRadius: "12px", overflow: "hidden" }}
      >
        <img
          src={hospital ? hospital?.profileImageUrl : Hospital1}
          alt="Hospital 1"
          className="img-cover"
        />
      </MuiBox>
      <MuiTypography variant="baseFont" fontWeight="bold" color="text.main">
        {hospital?.name}
      </MuiTypography>
      <MuiBox sx={{ display: "flex", gap: "6px", alignItems: "flex-start" }}>
        <img src={LocationIcon} />
        <MuiTypography
          color="sliderText.light"
          variant="baseFont"
          sx={{ width: "100%", overflow: "hidden", whiteSpace: "nowrap" }}
        >
          {[
            defaultAddress?.firstAddress,
            defaultAddress?.secondAddress,
            defaultAddress?.landmark,
            defaultAddress?.unitId,
            defaultAddress?.city,
            defaultAddress?.state,
            defaultAddress?.country,
            defaultAddress?.zipCode,
          ]
            .filter(Boolean)
            ?.join(", ")}
        </MuiTypography>
      </MuiBox>
    </MuiBox>
  );
};

export default HospitalCard;
