import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getProfessionalUserShiftDetail,
  getProfessionalUserAllShifts,
  getProfessionalUserScheduleDetail,
  cancelProfessionalUserSchedule,
  cancelProfessionalUserShift,
  getAllUserShifts,
  cancelShiftProfessional,
  getShiftDetails,
  getScheduleDetails,
  getIndividualShift,
  professionalClockIn,
  professionalClockOut,
  hospitalCompleteJob,
  getMyShiftsProfessional,
  getMyShiftsCountProfessional,
  getOnGoingShiftList,
} from "store/services/users/schedule";
import { uploadFile } from "store/services/utilities";
import { closeDrawer } from "store/slices/common/drawer.slice";
import { handleAuthError } from "utils/handleAuthError";

// For Get Shifts Data For User
export const GetProfessionalUserAllShifts = createAsyncThunk(
  "schedule/getProfessionalUserAllShifts",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getProfessionalUserAllShifts(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

// For Get Shifts detail Data For User
export const GetProfessionalUserShiftDetail = createAsyncThunk(
  "schedule/getProfessionalUserShiftDetail",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getProfessionalUserShiftDetail(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

// For Get Shifts detail Data For User
export const GetProfessionalUserScheduleDetail = createAsyncThunk(
  "schedule/getProfessionalUserScheduleDetail",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getProfessionalUserScheduleDetail(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

// For Get Shifts detail Data For User
export const CancelProfessionalUserSchedule = createAsyncThunk(
  "schedule/cancelProfessionalUserScheduleDetail",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await cancelProfessionalUserSchedule(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue({ ...response, ...payload });
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

// For Get Shifts detail Data For User
export const CancelProfessionalUserShift = createAsyncThunk(
  "schedule/cancelProfessionalUserShift",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await cancelProfessionalUserShift(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue({ ...response, ...payload });
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

// For Get Shifts Data For User
export const GetAllShiftsForUser = createAsyncThunk(
  "schedule/getAllShiftsForUser",
  async (payload, { rejectWithValue, getState, fulfillWithValue }) => {
    try {
      const { me } = getState();
      const response = await getAllUserShifts({
        ...payload,
        role: me?.me?.role,
      });
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        let tempArray = response.response;
        if (payload.page > 1) {
          tempArray = [...me.schedules.items, ...tempArray];
        }
        let responseObj = { items: tempArray, totalPages: null };
        return fulfillWithValue({ response: responseObj });
      }
      let responseObj = {}
      if (payload.page === 1) {
        responseObj = {
          items: response.response,
          totalPages: payload.page,
        };
      } else {
        responseObj = {
          items: me.schedules.items,
          totalPages: payload.page,
        };
      }
      let tempObj = { message: response?.message, response: responseObj };
      return rejectWithValue(tempObj);
    } catch (error) {
      handleAuthError(error?.response?.status, true);
      return rejectWithValue(error);
    }
  }
);

// For Get Shifts Data For User
export const GetShiftDetailsAction = createAsyncThunk(
  "schedule/getShiftDetails",
  async (payload, { rejectWithValue, getState, fulfillWithValue }) => {
    try {
      const { me } = getState();
      const response = await getShiftDetails({
        ...payload,
        role: me?.me?.role,
      });
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        let responseObj = response?.response;
        let singleShiftObj = {};
        singleShiftObj = {
          scheduleId: responseObj?.id,
          jobId: responseObj?.jobPostId,
          status: responseObj?.status,
          currentShift: {
            ...responseObj?.currentShift,
            jobTitle: responseObj?.jobTitle,
            employeeHourRate:
              responseObj?.jobPost?.JobPostDetails?.employeeHourRate,
            shiftType: responseObj?.jobPost?.JobPostDetails?.shiftType,
            shiftId: responseObj?.shiftId,
            scheduleCurrentDate: responseObj?.scheduleCurrentDate,
            startTime: responseObj?.startTime,
            endTime: responseObj?.endTime,
          },
        };
        if (me?.me?.role === "professional") {
          singleShiftObj = {
            ...singleShiftObj,
            user: {
              ...responseObj?.jobPost?.users,
              addressDetails:
                responseObj?.jobPost?.JobPostDetails?.addressDetails,
              jobTitle: responseObj?.jobTitle,
            },
          };
        } else {
          singleShiftObj = {
            ...singleShiftObj,
            user: {
              ...responseObj?.professionalTimeSchedule,
              addressDetails:
                responseObj?.professionalTimeSchedule?.addressDetails,
              jobTitle: responseObj?.professionalTimeSchedule?.jobTitleName,
            },
          };
        }
        return fulfillWithValue(singleShiftObj);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      handleAuthError(error?.response?.status, true);
      return rejectWithValue(error);
    }
  }
);

// For Get Shifts Data For User
export const GetScheduleDetailsAction = createAsyncThunk(
  "schedule/getScheduleDetails",
  async (payload, { rejectWithValue, getState, fulfillWithValue }) => {
    try {
      const { me } = getState();
      const response = await getScheduleDetails({
        ...payload,
        role: me?.me?.role,
      });
      if (response.status === 1) {
        let responseObj = response?.response;
        console.log("responseObj", responseObj);
        let singleShiftObj = {};
        singleShiftObj = {
          ...singleShiftObj,
          jobPost: responseObj?.jobPost,
          professionalId: responseObj?.completedShift?.professionalId,
          status: responseObj?.scheduleDetails?.status?.toLowerCase(),
          user: {
            ...responseObj?.users,
            addressDetails: responseObj?.jobPost?.addressDetails,
            jobTitle: responseObj?.jobPost?.jobTitleName,
          },
          payrollDetails: responseObj?.payrollDetails
            ? responseObj?.payrollDetails
            : null,
          currentShift: null,
          nextShift: null,
          // nextShift: { ...responseObj?.nextShift, jobTitle: responseObj?.jobPost?.jobTitleName, employeeHourRate: responseObj?.jobPost?.employeeHourRate, shiftType: responseObj?.jobPost?.shiftType },
        };
        if (responseObj?.nextShift) {
          singleShiftObj = {
            ...singleShiftObj,
            jobId: responseObj?.nextShift?.jobPostId,
            professionalId: responseObj?.nextShift?.professionalId,
            nextShift: {
              ...responseObj?.nextShift,
              jobTitle: responseObj?.jobPost?.jobTitleName,
              employeeHourRate: responseObj?.jobPost?.employeeHourRate,
              shiftType: responseObj?.jobPost?.shiftType,
              user: {
                ...responseObj?.users,
                addressDetails: responseObj?.jobPost?.addressDetails,
                jobTitle: responseObj?.jobPost?.jobTitleName,
              },
            },
          };
        }
        if (responseObj?.completedShift.length > 0) {
          singleShiftObj = {
            ...singleShiftObj,
            jobId: responseObj?.completedShift?.[0]?.jobPostId,
            professionalId: responseObj?.completedShift?.[0]?.professionalId,
            review: responseObj?.review,
            currentShift: {
              ...responseObj?.completedShift?.[0],
              jobTitle: responseObj?.jobPost?.jobTitleName,
              employeeHourRate: responseObj?.jobPost?.employeeHourRate,
              shiftType: responseObj?.jobPost?.shiftType,
            },
          };
        }
        if (responseObj?.currentShift) {
          singleShiftObj = {
            ...singleShiftObj,
            jobId: responseObj?.currentShift?.jobPostId,
            professionalId: responseObj?.currentShift?.professionalId,
            currentShift: {
              ...responseObj?.currentShift,
              jobTitle: responseObj?.jobPost?.jobTitleName,
              employeeHourRate: responseObj?.jobPost?.employeeHourRate,
              shiftType: responseObj?.jobPost?.shiftType,
            },
          };
        }
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(singleShiftObj);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      handleAuthError(error?.response?.status, true);
      return rejectWithValue(error);
    }
  }
);

// For Get Shifts detail Data For User
export const CancelShiftByProfessional = createAsyncThunk(
  "schedule/cancelShiftByProfessional",
  async (
    payload,
    { rejectWithValue, dispatch, getState, fulfillWithValue }
  ) => {
    try {
      const { me } = getState();
      const response = await cancelShiftProfessional({
        ...payload,
        role: me?.me?.role,
      });
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeDrawer());
        return fulfillWithValue({ ...response, ...payload });
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      handleAuthError(error?.response?.status, true);
      return rejectWithValue(error);
    }
  }
);

// For Get Individuals shifts For User
export const GetIndividualShiftAction = createAsyncThunk(
  "schedule/getIndividualShift",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await getIndividualShift(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeDrawer());
        return fulfillWithValue({ ...response, ...payload });
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      handleAuthError(error?.response?.status, true);
      return rejectWithValue(error);
    }
  }
);

// For Get Individuals shifts For User
export const GetMyShiftsProfessionalAction = createAsyncThunk(
  "shifts/getMyShifts",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await getMyShiftsProfessional(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeDrawer());
        return fulfillWithValue({ ...response, ...payload });
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      handleAuthError(error?.response?.status, true);
      return rejectWithValue(error);
    }
  }
);

// For Get Individuals shifts For User
export const GetMyShiftsCountProfessional = createAsyncThunk(
  "shifts/getMyShiftsCount",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await getMyShiftsCountProfessional(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeDrawer());
        return fulfillWithValue({ ...response, ...payload });
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      handleAuthError(error?.response?.status, true);
      return rejectWithValue(error);
    }
  }
);

// For Get Individuals shifts For User
export const ProfessionalClockInAction = createAsyncThunk(
  "schedule/professionalClockIn",
  async (
    payload,
    { rejectWithValue, dispatch, getState, fulfillWithValue }
  ) => {
    try {
      const { me } = getState();
      let { file, ...rest } = payload;
      const uploadFilesResponse = await uploadFile({
        file,
        uploadFor: "image",
      });
      rest = {
        ...rest,
        clockInSelfie: uploadFilesResponse?.response?.urls?.[0],
      };
      const response = await professionalClockIn({
        ...rest,
        role: me?.me?.role,
      });
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeDrawer());
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      handleAuthError(error?.response?.status, true);
      return rejectWithValue(error);
    }
  }
);

// For Get Individuals shifts For User
export const ProfessionalClockOutAction = createAsyncThunk(
  "schedule/professionalClockOut",
  async (
    payload,
    { rejectWithValue, dispatch, getState, fulfillWithValue }
  ) => {
    try {
      const { me } = getState();
      let { file, ...rest } = payload;
      const uploadFilesResponse = await uploadFile({
        file,
        uploadFor: "image",
      });
      rest = {
        ...rest,
        clockOutSelfie: uploadFilesResponse?.response?.urls?.[0],
      };
      const response = await professionalClockOut({
        ...rest,
        role: me?.me?.role,
      });
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeDrawer());
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      handleAuthError(error?.response?.status, true);
      return rejectWithValue(error);
    }
  }
);

// For Get Individuals shifts For User
export const HospitalCompleteJobAction = createAsyncThunk(
  "schedule/hospitalCompleteJob",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await hospitalCompleteJob(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeDrawer());
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      handleAuthError(error?.response?.status, true);
      return rejectWithValue(error);
    }
  }
);


// For Get on Going Shift List 
export const GetOnGoingShiftListAction = createAsyncThunk(
  "schedule/getOnGoingShiftListAction",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getOnGoingShiftList(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);
