import React from "react";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import {
  Input,
  SelectComponent,
  SubmitBtn,
  VisiblitySwitch,
} from "components/atoms";
import { Name, Features, Duration } from "utils/validation/AddPlans";
import { AddPlansAction, UpdatePlansAction } from "store/middlewares/plans";
import { Box } from "@mui/material";

function ModalEditPlans({ row }) {
  const dispatch = useDispatch();

  const { features } = useSelector((state) => state.subscriptionManagement);

  const AddRoleSchema = Yup.object({
    planTitle: Name,
    featureIds: Features,
    price: Yup.number().when("planType", (isSwitchOn, schema) => {
      if (isSwitchOn[0] === "paid") {
        return schema
          .required("Price is required")
          .min(1, "Price must be greater than zero")
          .typeError("Invalid Price");
      }
      return schema.nullable();
    }),
    duration: Duration,
  });

  const handleSubmit = (values) => {
    if (row) {
      dispatch(
        UpdatePlansAction({
          id: row.id,
          ...values,
          planType: values?.price === 0 ? "free" : "paid",
        })
      );
    } else {
      dispatch(AddPlansAction(values));
    }
  };

  const formik = useFormik({
    initialValues: {
      planTitle: row?.planTitle ? row?.planTitle : "",
      featureIds:
        row?.PlanFeature.length > 0
          ? row?.PlanFeature?.map((acc) => acc?.feature?.id)
          : [],
      price: row?.price ? row?.price : 0,
      duration: row?.duration ? row?.duration : 0,
      planType: row?.planType ? row?.planType : "free",
    },
    validationSchema: AddRoleSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Input
        inputClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 mt-2"
        label={"Title"}
        type="text"
        name="planTitle"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.planTitle && formik.touched.planTitle}
        errorMsg={formik.errors.planTitle}
        value={formik.values.planTitle}
        placeholder="Enter Plan Title"
      />
      <Input
        inputClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 mt-2"
        label={"Duration (In Days)"}
        type="number"
        name="duration"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.duration && formik.touched.duration}
        errorMsg={formik.errors.duration}
        value={formik.values.duration}
        placeholder="Enter No. of Days"
      />
      <SelectComponent
        className={"px-0"}
        label={"Features"}
        multiSelect={true}
        options={features?.map((country) => {
          return {
            label: country?.name,
            value: country?.id,
          };
        })}
        placeholder="Select Features"
        name={"featureIds"}
        value={formik.values.featureIds}
        error={formik.errors.featureIds && formik.touched.featureIds}
        errorMsg={formik.errors.featureIds}
        onChange={(selectedOption) => {
          formik.setFieldValue(
            "featureIds",
            selectedOption ? selectedOption.map((option) => option.value) : ""
          );
        }}
      />
      <Box className="mt-2">
        <label className={`font-medium`}>Plan Type</label>
        <div className="flex items-center">
          <h6
            className={`${
              formik.values.planType === "paid" ? "" : "font-semibold"
            }`}
          >
            Free
          </h6>
          <VisiblitySwitch
            checked={formik.values.planType === "paid"}
            onChange={(e) => {
              if (e.target.checked) {
                formik.setFieldValue("planType", "paid");
              } else {
                formik.setFieldValue("planType", "free");
              }
            }}
          />
          <h6
            className={`${
              formik.values.planType === "paid" ? "font-semibold" : ""
            }`}
          >
            Paid
          </h6>
        </div>
      </Box>
      {formik.values.planType === "paid" && (
        <Input
          inputClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 mt-2"
          label={"Price"}
          type="number"
          name="price"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.price && formik.touched.price}
          errorMsg={formik.errors.price}
          value={formik.values.price}
          placeholder="Enter Price"
        />
      )}
      <SubmitBtn title={row ? "Add" : "Update"} />
    </form>
  );
}

export default ModalEditPlans;
