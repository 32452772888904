import { createSlice } from "@reduxjs/toolkit";
import { GetDashboardAction } from "store/middlewares/dashboard";
import { GetHospitalHomeAction } from "store/middlewares/users/hospitals";
import { GetProfessionalHomeAction } from "store/middlewares/users/professionals";

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    dashboardLoading: false,
    dashboard: [],
    home: [],
    homeLoading: false,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      // For get dashboard data
      .addCase(GetDashboardAction.pending, (state) => {
        state.dashboardLoading = true;
        state.dashboard = [];
      })
      .addCase(GetDashboardAction.fulfilled, (state, action) => {
        state.dashboardLoading = false;
        state.dashboard = action.payload.response;
      })
      .addCase(GetDashboardAction.rejected, (state) => {
        state.dashboardLoading = false;
        state.dashboard = [];
      })

      // For Get Professional Home Page 
      .addCase(GetProfessionalHomeAction.pending, (state) => {
        state.home = null;
        state.homeLoading = true;
      })
      .addCase(GetProfessionalHomeAction.fulfilled, (state, action) => {
        state.home = action.payload.response;
        state.homeLoading = false;
      })
      .addCase(GetProfessionalHomeAction.rejected, (state) => {
        state.home = null;
        state.homeLoading = false;
      })

      // For Get Professional Home Page 
      .addCase(GetHospitalHomeAction.pending, (state) => {
        state.home = null;
        state.homeLoading = true;
      })
      .addCase(GetHospitalHomeAction.fulfilled, (state, action) => {
        state.home = action.payload.response;
        state.homeLoading = false;
      })
      .addCase(GetHospitalHomeAction.rejected, (state) => {
        state.home = null;
        state.homeLoading = false;
      })
  },
});

export default dashboardSlice.reducer;
