function formatTime(seconds) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  // Format seconds to always be two digits
  const formattedSeconds =
    remainingSeconds < 10 ? "0" + remainingSeconds : remainingSeconds;
  return `0${minutes}:${formattedSeconds}`;
}

export default formatTime;

// for Format Time AM-PM
export const formatTimeAMPM = (timeString, includeSeconds = true) => {
  if (timeString) {
    const [hours, minutes, seconds] = timeString.split(":").map(Number);

    const ampm = hours >= 12 ? "PM" : "AM";

    const convertedHours = hours % 12 || 12;
    const zeroPadHours =
      convertedHours < 10 ? `0${convertedHours}` : convertedHours;

    const minutesStr = minutes < 10 ? `0${minutes}` : minutes;
    const secondsStr = seconds < 10 ? `0${seconds}` : seconds;

    return includeSeconds
      ? `${zeroPadHours}:${minutesStr}:${secondsStr} ${ampm}`
      : `${zeroPadHours}:${minutesStr} ${ampm}`;
  } else {
    return "00:00 AM"
  }
};
