import { Button } from "@mui/material";
import { MuiBox, MuiTypography, Select, Textfield } from "components/atoms";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { GetAllDeleteReasonsWebAction } from "store/middlewares/deleteReason";
import Radius from "assets/images/SVG/radius mile.svg";
import { DeleteAccountAction } from "store/middlewares/me";

const DeleteAccountModal = () => {
  const dispatch = useDispatch();

  const { deleteReasons } = useSelector((state) => state.deleteReasons);
  const { me } = useSelector((state) => state.me);

  const MileageSchema = Yup.object().shape({
    reasonId: Yup.string().required("Please select a reason"),
  });

  useEffect(() => {
    dispatch(GetAllDeleteReasonsWebAction());
  }, [dispatch]);

  const handleSubmit = (values) => {
    dispatch(DeleteAccountAction({ id: me?.id, ...values }));
  };

  const formik = useFormik({
    initialValues: { reasonId: "", additionalDeleteNote: null },
    validationSchema: MileageSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <MuiBox
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "25px",
          alignItems: "center",
          px: "40px",
        }}
      >
        <MuiTypography color={"#FF5C5C"} fontSize="24px" fontWeight="bold">
          Delete Account
        </MuiTypography>

        <MuiTypography fontSize="16px" align="center">
          Are you sure you want to delete your account? <br />
          This action is permanent and cannot be undone.
        </MuiTypography>

        <Select
          variant="plain"
          label="Delete Reason"
          multiple={false}
          required={true}
          name="reasonId"
          value={formik.values.reasonId}
          error={formik.errors.reasonId && formik.touched.reasonId}
          errorMsg={formik.errors.reasonId}
          icon={<img src={Radius} />}
          placeholder="Select delete reason"
          options={deleteReasons?.map((item) => {
            return {
              label: item?.reason,
              value: item?.id,
            };
          })}
          onChange={(e) => {
            formik.setFieldValue("reasonId", e);
          }}
        />

        <Textfield
          name={"additionalDeleteNote"}
          placeholder={"Enter Notes"}
          value={formik.values.additionalDeleteNote}
          onChange={formik.handleChange}
          bgcolor={"#fff"}
          required={false}
          onBlur={formik.handleBlur}
          label="Additional Note"
          rows="5"
        />

        <Button
          variant="lightButton"
          type="submit"
          sx={{
            maxWidth: "338px",
            bgcolor: "#FF5C5C",
            "&:hover": { bgcolor: "#b51e1e" },
          }}
        >
          {"Delete Account"}
        </Button>
      </MuiBox>
    </form>
  );
};

export default DeleteAccountModal;
