import { MuiBox, MuiTypography } from "components/atoms";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import React, { useState, useEffect } from "react";

dayjs.extend(duration);

function TimerClock({ scheduleCurrentDate, startTime }) {
  const [timeRemaining, setTimeRemaining] = useState(null);

  useEffect(() => {
    if (!scheduleCurrentDate || !startTime) return;

    const shiftStart = dayjs(startTime);

    const updateCountdown = () => {
      const now = dayjs(); // Current time based on the PC
      const diff = shiftStart.diff(now);

      if (diff > 0) {
        const remaining = dayjs.duration(diff);
        setTimeRemaining({
          hours: Math.floor(remaining.asHours()),
          minutes: remaining.minutes(),
          seconds: remaining.seconds(),
        });
      } else {
        setTimeRemaining(null); // Shift has started or passed
      }
    };

    updateCountdown(); // Initial calculation
    const interval = setInterval(updateCountdown, 1000); // Update every second

    return () => clearInterval(interval); // Cleanup on unmount
  }, [scheduleCurrentDate, startTime]);

  if (timeRemaining === null) {
    return (
      null
    );
  }

  const { hours, minutes, seconds } = timeRemaining;

  return (
    <MuiBox
      id="clock"
      sx={{
        width: "fit-content",
        display: "flex",
        alignItems: "center",
        gap: "5px",
      }}
    >
      {[hours, minutes, seconds].map((time, index) => (
        <React.Fragment key={index}>
          <MuiBox
            sx={{
              height: "54px",
              width: "54px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              bgcolor: "#E8F0F3",
              borderRadius: "6px",
            }}
          >
            <MuiTypography variant="h5" fontWeight="bold">
              {String(time).padStart(2, "0")}
            </MuiTypography>
          </MuiBox>
          {index < 2 && (
            <MuiTypography variant="h5" fontWeight="bold">
              :
            </MuiTypography>
          )}
        </React.Fragment>
      ))}
    </MuiBox>
  );
}

export default TimerClock;
