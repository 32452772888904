import { TextField } from "@mui/material";
import React from "react";
import MuiBox from "../MuiBox";
import MuiTypography from "../MuiTypography";
import FormError from "../FormError";

const Textfield = ({
  label,
  className,
  fullWidth = true,
  disabled = false,
  onChange,
  onBlur,
  value,
  error,
  placeholder,
  name,
  errorMsg,
  // variant = 'plain',
  rows = 3,
  bgcolor,
  required = true,
}) => {
  return (
    <>
      <MuiBox>
        <MuiTypography variant="baseFont" fontWeight={"bold"}>
          {label} {required && <span style={{ color: "red" }}>*</span>}
        </MuiTypography>
        <TextField
          multiline
          rows={rows}
          variant="outlined"
          className={className}
          id={name}
          fullWidth={fullWidth}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          error={Boolean(error)}
          placeholder={placeholder ? placeholder : `Enter ${label}`}
          name={name}
          disabled={disabled}
          sx={{
            mt: "8px",
            bgcolor: bgcolor ? bgcolor : undefined,
            overflow: "hidden",
            borderRadius: "12px",
          }}
        />
      {error && <FormError errorMsg={errorMsg} />}
      </MuiBox>
    </>
  );
};

export default Textfield;
