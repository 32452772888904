import { AuthLayout, SubscriptionLayout } from "components/molecules";
import useDocumentTitle from "hooks/useDocumentTitle";
import React, { lazy, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { validateTokenWeb } from "store/middlewares/session/auth";
import { getDecryptedToken } from "utils/CryptoJS";
import ScrollToTop from "utils/ScrollToTop";

// Pages
const Login = lazy(() => import("pages/Web/Login"));
const SignUp = lazy(() => import("pages/Web/SignUp"));
const ResetPassword = lazy(() => import("pages/Web/ResetPassword"));
const ProfessionalSignUp = lazy(() => import("pages/Web/ProfessionalSignUp"));
const OTP = lazy(() => import("pages/Web/otp"));
const Subscription = lazy(() => import("pages/Web/Subscription"));
const CMS = lazy(() => import("pages/Web/CMS"));
const AdminLogin = lazy(() => import("pages/Login/Login"));

const basePath = "";
const generatePath = (slug) => {
  return `${basePath}/${slug}`;
};
const adminRoutes = [
  {
    path: generatePath("admin"),
    element: <AdminLogin />,
  },
  {
    path: generatePath(""),
    element: <Login />,
  },
  {
    path: generatePath("signup"),
    element: <SignUp />,
  },
  {
    path: generatePath("otp"),
    element: <OTP />,
  },
  {
    path: generatePath("reset-password"),
    element: <ResetPassword />,
  },
  {
    path: generatePath("professional-sign-up"),
    element: <ProfessionalSignUp />,
  },
];

const staticPagesRoutes = [
  {
    path: generatePath("cms/:userType/:type"),
    element: <CMS />,
  },
];

const authNavigation = () => {
  useDocumentTitle("Vital Medical Staffing");

  const user = JSON.parse(localStorage.getItem("aboutcare-web"));

  const dispatch = useDispatch();

  const { isTokenValid } = useSelector((state) => state.auth);
  const checkToken = useCallback(() => {
    if (user) {
      dispatch(validateTokenWeb({ token: getDecryptedToken(user?.token) }));
    }
  }, [dispatch, user]);

  useEffect(() => {
    checkToken();
  }, [checkToken]);

  return (
    <>
      <ScrollToTop />
      <Routes>
        {/* if user is logged in redirect to dashboard */}
        <Route
          path=""
          element={
            !user || !isTokenValid ? (
              <AuthLayout>
                <Login />
              </AuthLayout>
            ) : (
              <>
                {/* //Protection for when a User tries to access a Creator Page  */}
                {user?.role === "hospital" ? (
                  <Navigate to="/hospital/home" />
                ) : (
                  <Navigate to="/professional/home" />
                )}
              </>
            )
          }
        />
        {/* if user is not present show unauthorized ui */}
        <Route element={<AuthLayout />}>
          {adminRoutes.map((route, index) => {
            return (
              <Route
                key={`${route.path}${index}`}
                path={route.path}
                element={route.element}
              />
            );
          })}
          {/* all undefined routes are handled here for admin */}
        </Route>
        {staticPagesRoutes.map((route, index) => {
          return (
            <Route
              key={`${route.path}${index}`}
              path={route.path}
              element={route.element}
            />
          );
        })}
        <Route element={<SubscriptionLayout />}>
          <Route path={"subscription"} element={<Subscription />} />
        </Route>
        <Route
          path="*"
          element={!user || !isTokenValid ? <Navigate to="/" /> : <></>}
        />
      </Routes>
    </>
  );
};

export default authNavigation;
