import axiosClient from "utils/api";
const URI = "/jobPost";

// For get all jobs
export const getJobsForWeb = async (payload) => {
  let URL = `${URI}/jobPost`;
  try {
    const res = await axiosClient().request({
      url: URL,
      method: "GET",

      params: payload,
    });

    return res.data;
  } catch (error) {
    console.error("Error occuured Get All Jobs For Web:", error.message);
    throw new Error(error.message);
  }
};

// edit jobs in Web
export const editJobForWeb = async (payload) => {
  let { id, ...rest } = payload;
  let URL = `${URI}/jobPost/${id}`;
  try {
    const res = await axiosClient().request({
      url: URL,
      method: "put",
      data: rest,
    });

    return res.data;
  } catch (error) {
    console.error("Error occuured Get Edit Job For Web:", error.message);
    throw new Error(error.message);
  }
};

// delete jobs in Web
export const deleteJobForWeb = async (payload) => {
  let URL = `${URI}/jobPost/${payload.id}`;
  try {
    const res = await axiosClient().request({
      url: URL,
      method: "delete",
    });

    return res.data;
  } catch (error) {
    console.error("Error occuured Delete Job For Web:", error.message);
    throw new Error(error.message);
  }
};

// add jobs in Web
export const addJobForWeb = async (payload) => {
  let URL = `${URI}/jobPost`;
  try {
    const res = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
    });

    return res.data;
  } catch (error) {
    console.error("Error occuured Add Job For Web:", error.message);
    throw new Error(error.message);
  }
};

// get jobs by id in Web
export const getJobByIdForWeb = async (payload) => {
  let URL = `${URI}/jobPost/${payload.id}`;
  try {
    const res = await axiosClient().request({
      url: URL,
      method: "get",
    });

    return res.data;
  } catch (error) {
    console.error("Error occuured Get Job By Id For Web:", error.message);
    throw new Error(error.message);
  }
};

// get applicants of jobs in Web
export const getJobApplicantsForWeb = async (payload) => {
  let { id, ...rest } = payload;
  let URL = `${URI}/applicants/${id}`;
  try {
    const res = await axiosClient().request({
      url: URL,
      method: "get",
      params: rest,
    });

    return res.data;
  } catch (error) {
    console.error("Error occuured Get Edit Job For Web:", error.message);
    throw new Error(error.message);
  }
};

// get applicants of jobs in Web
export const updateScheduleOfJobForWeb = async (payload) => {
  let { id, ...rest } = payload;
  let URL = `${URI}/updateDateSchedule/${id}`;
  try {
    const res = await axiosClient().request({
      url: URL,
      method: "put",
      data: { schedule: rest },
    });

    return res.data;
  } catch (error) {
    console.error(
      "Error occuured Get Edit Schedule Of Job For Web:",
      error.message
    );
    throw new Error(error.message);
  }
};

// get applicants of jobs in Web
export const getJobSummary = async (payload) => {
  let { id } = payload;
  let URL = `${URI}/summery/${id}`;
  try {
    const res = await axiosClient().request({
      url: URL,
      method: "get",
    });

    return res.data;
  } catch (error) {
    console.error("Error occuured Get Summary Of Job For Web:", error.message);
    throw new Error(error.message);
  }
};

// get jobs by id in Web
export const getApplicantDetail = async (payload) => {
  let { id } = payload;
  let URL = `${URI}/getProfessionalDetails/${id}`;
  try {
    const res = await axiosClient().request({
      url: URL,
      method: "get",
    });

    return res.data;
  } catch (error) {
    console.error(
      "Error occuured Get Applicant Details For Web:",
      error.message
    );
    throw new Error(error.message);
  }
};

// get jobs by id in Web
export const approveApplicationForWeb = async (payload) => {
  let { id, ...rest } = payload;
  let URL = `${URI}/approveUserJob/${id}`;
  try {
    const res = await axiosClient().request({
      url: URL,
      method: "put",
      data: rest,
    });

    return res.data;
  } catch (error) {
    console.error("Error occuured Get Job By Id For Web:", error.message);
    throw new Error(error.message);
  }
};

// For Get Payment Details Summery For User
export const getJobSummeryById = async (payload) => {
  let { id } = payload;
  let URL = `${URI}/summery/${id}`;
  try {
    const res = await axiosClient().request({
      url: URL,
      method: "GET",
    });

    return res.data;
  } catch (error) {
    console.error(
      `Error occuured  Get Payment Details Summery For User:`,
      error.message
    );
    throw new Error(error.message);
  }
};