import { Button } from "@mui/material";
import { MuiBox, MuiTypography, CustomDatePicker } from "components/atoms";
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import * as Yup from "yup";
import { useFormik } from "formik";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
// import customParseFormat from "dayjs/plugin/customParseFormat";

// dayjs.extend(customParseFormat);

dayjs.extend(utc);
dayjs.extend(timezone);
const TransactionFilterModal = ({ params, onSubmit }) => {
  const [valSchema, setValSchema] = useState(null);
  const handleSubmit = (values) => {
    onSubmit({
      ...values,
      startDate: dayjs(values?.startDate)
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss[Z]"),
      endDate: dayjs(values?.endDate).utc().format("YYYY-MM-DDTHH:mm:ss[Z]"),
    });
  };

  const formik = useFormik({
    initialValues: {
      startDate: params?.startDate
        ? dayjs.utc(params?.startDate).local().format("YYYY-MM-DD")
        : "",
      endDate: params?.endDate
        ? dayjs.utc(params?.endDate).local().format("YYYY-MM-DD")
        : "",
    },
    validationSchema: Yup.object().shape(valSchema),
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (formik.values.startDate || formik.values.endDate) {
      let tempObj = {
        startDate: Yup.string().required("Please enter start Date"),
        endDate: Yup.string().required("Please enter end Date"),
      };
      setValSchema(tempObj);
    }
  }, [formik]);

  return (
    <MuiBox sx={{ p: { xs: "16px 10px", sm: "16px 32px" } }}>
      <MuiTypography variant="h6" fontWeight={"bold"} align={"center"}>
        Filter
      </MuiTypography>
      <MuiBox
        component={"form"}
        onSubmit={formik.handleSubmit}
        sx={{ display: "flex", flexDirection: "column", gap: 4 }}
      >
        <MuiBox sx={{ mt: 5 }}>
          <MuiTypography variant="subtitle1" fontWeight={"bold"}>
            Choose Date
          </MuiTypography>
          <Grid container spacing={"15px"} sx={{ mt: "20px" }}>
            <Grid xs={6}>
              <CustomDatePicker
                label={"Start Date"}
                format="YYYY-MM-DD"
                maxDate={
                  formik?.values?.endDate
                    ? dayjs(formik?.values?.endDate)
                    : null
                }
                onChange={(e) => {
                  formik.setFieldValue(
                    `startDate`,
                    dayjs(e).format("YYYY-MM-DD")
                  );
                }}
                error={formik.touched.startDate && formik.errors.startDate}
                value={
                  formik?.values?.startDate
                    ? dayjs(formik?.values?.startDate, "YYYY-MM-DD")
                    : null
                }
                errorMsg={formik.errors.startDate}
              />
            </Grid>
            <Grid xs={6}>
              <CustomDatePicker
                label={"End Date"}
                format="YYYY-MM-DD"
                minDate={
                  formik?.values?.startDate
                    ? dayjs(formik?.values?.startDate, "YYYY-MM-DD")
                    : null
                }
                onChange={(e) => {
                  formik.setFieldValue(
                    "endDate",
                    dayjs(e).format("YYYY-MM-DD")
                  );
                }}
                value={
                  formik?.values?.endDate
                    ? dayjs(formik?.values?.endDate, "YYYY-MM-DD")
                    : null
                }
                error={formik.touched?.endDate && formik.errors?.endDate}
                errorMsg={formik.errors?.endDate}
              />
            </Grid>
          </Grid>
        </MuiBox>

        <Button variant="lightButton" type="submit">
          Apply
        </Button>
      </MuiBox>
    </MuiBox>
  );
};

export default TransactionFilterModal;
