import axiosClient from "utils/api";
const URI = "/credential";

//avatar
export const getCredentialAvatarsForWeb = async () => {
  const URL = `${URI}/avatar`;

  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred get all avatars:", error);
    throw error;
  }
};

//add avatar
export const addCredentialAvatarsForWeb = async (payload) => {
  const URL = `${URI}/avatar`;

  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred delete avatars:", error);
    throw error;
  }
};

//edit avatar
export const editCredentialAvatarsForWeb = async (payload) => {
  let { id, ...rest } = payload;
  const URL = `${URI}/avatar/${id}`;

  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred delete avatars:", error);
    throw error;
  }
};

//delete avatar
export const deleteCredentialAvatarsForWeb = async (payload) => {
  const URL = `${URI}/avatar/${payload.id}`;

  try {
    const response = await axiosClient().request({
      url: URL,
      method: "delete",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred delete avatars:", error);
    throw error;
  }
};

//get physical address
export const getCredentialPhysicalAddressForWeb = async () => {
  const URL = `${URI}/address`;

  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred get all avatars:", error);
    throw error;
  }
};

//add physical address
export const addCredentialPhysicalAddressForWeb = async (payload) => {
  const URL = `${URI}/address`;

  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred add Physical Address:", error);
    throw error;
  }
};

//edit physical address
export const editCredentialPhysicalAddressForWeb = async (payload) => {
  let { id, ...rest } = payload;
  const URL = `${URI}/address/${id}`;

  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Edit Physical Address:", error);
    throw error;
  }
};

//set default physical address
export const setDefaultCredentialPhysicalAddressForWeb = async (payload) => {
  const URL = `${URI}/address/${payload.id}/default`;

  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Set Default Physical Address:", error);
    throw error;
  }
};

//edit physical address
export const deleteCredentialPhysicalAddressForWeb = async (payload) => {
  const URL = `${URI}/address/${payload.id}`;

  try {
    const response = await axiosClient().request({
      url: URL,
      method: "delete",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Delete Physical Address:", error);
    throw error;
  }
};

//get Job Titles
export const getCredentialJobTitleForWeb = async () => {
  const URL = `${URI}/jobTitle`;

  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred get Job Title For Credential:", error);
    throw error;
  }
};

//update Job Titles
export const updateCredentialJobTitleForWeb = async (payload) => {
  let { id, ...rest } = payload;
  const URL = `${URI}/jobTitle/${id}`;

  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred update Job Title For Credential:", error);
    throw error;
  }
};

//update Job Titles
export const addCredentialJobTitleForWeb = async (payload) => {
  const URL = `${URI}/jobTitle`;

  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Add Job Title For Credential:", error);
    throw error;
  }
};

//update Job Titles
export const getCredentialDriverLicenceForWeb = async () => {
  const URL = `${URI}/licence`;

  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Get Driving Licence For Credential:", error);
    throw error;
  }
};

//add Driver Licence
export const addCredentialDriverLicenceForWeb = async (payload) => {
  const URL = `${URI}/licence`;

  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Add Driving Licence For Credential:", error);
    throw error;
  }
};

//edit Driver Licence
export const editCredentialDriverLicenceForWeb = async (payload) => {
  let {id, ...rest} = payload
  const URL = `${URI}/licence/${id}`;

  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
      data: rest
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Edit Driving Licence For Credential:", error);
    throw error;
  }
};

//get All Resume
export const getCredentialResumeForWeb = async () => {
  const URL = `${URI}/resume`;

  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Get Resume For Credential:", error);
    throw error;
  }
};

//create Resume
export const addCredentialResumeForWeb = async (payload) => {
  const URL = `${URI}/resume`;

  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Add Resume For Credential:", error);
    throw error;
  }
};

//get Resume By id
export const getCredentialResumeByIdForWeb = async (payload) => {
  const URL = `${URI}/resume/${payload.id}`;

  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Get Resume By Id For Credential:", error);
    throw error;
  }
};

//create Resume
export const editCredentialResumeForWeb = async (payload) => {
  let { id, ...rest } = payload;
  const URL = `${URI}/resume/${id}`;

  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Edit Resume For Credential:", error);
    throw error;
  }
};

//edit Resume
export const deleteCredentialResumeForWeb = async (payload) => {
  const URL = `${URI}/resume/${payload.id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "delete",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Edit Resume For Credential:", error);
    throw error;
  }
};

//edit Resume
export const setDefaultResumeForWeb = async (payload) => {
  let { id, ...rest } = payload;
  const URL = `${URI}/resume/${id}/default`;

  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Set Default Resume For Credential:", error);
    throw error;
  }
};

//get Pro Licence
export const getCredentialProLicenceForWeb = async () => {
  const URL = `${URI}/serviceLicence`;

  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred get all Pro Licence:", error);
    throw error;
  }
};

//add Pro Licence
export const addCredentialProLicenceForWeb = async (payload) => {
  const URL = `${URI}/serviceLicence`;

  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred add Pro Licence:", error);
    throw error;
  }
};

//edit Pro Licence
export const editCredentialProLicenceForWeb = async (payload) => {
  let { id, ...rest } = payload;
  const URL = `${URI}/serviceLicence/${id}`;

  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred edit Pro Licence:", error);
    throw error;
  }
};

//get Pro Licence
export const deleteCredentialProLicenceForWeb = async (payload) => {
  const URL = `${URI}/serviceLicence/${payload.id}`;

  try {
    const response = await axiosClient().request({
      url: URL,
      method: "delete",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred get all Pro Licence:", error);
    throw error;
  }
};

//get Certificate
export const getCredentialCertificateForWeb = async () => {
  const URL = `${URI}/certificate`;

  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred get all Certificates:", error);
    throw error;
  }
};

//add Pro Licence
export const addCredentialCertificateForWeb = async (payload) => {
  const URL = `${URI}/certificate`;

  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred add Pro Licence:", error);
    throw error;
  }
};

//edit Pro Licence
export const editCredentialCertificateForWeb = async (payload) => {
  let { id, ...rest } = payload;
  const URL = `${URI}/certificate/${id}`;

  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred edit Pro Licence:", error);
    throw error;
  }
};

//delete Certificate
export const deleteCredentialCertificateForWeb = async (payload) => {
  const URL = `${URI}/certificate/${payload.id}`;

  try {
    const response = await axiosClient().request({
      url: URL,
      method: "delete",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred delete Certificate:", error);
    throw error;
  }
};

//get physical address
export const getCredentialSocSecForWeb = async () => {
  const URL = `${URI}/socialSecurityNo`;

  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred get all Certificates:", error);
    throw error;
  }
};

//add Social Security
export const addCredentialSocSecForWeb = async (payload) => {
  const URL = `${URI}/socialSecurityNo`;

  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred add Social Security:", error);
    throw error;
  }
};

//edit Social Security
export const editCredentialSocSecForWeb = async (payload) => {
  let { id, ...rest } = payload;
  const URL = `${URI}/reuploadSocialSecurityNo/${id}`;

  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred edit Social Security:", error);
    throw error;
  }
};
