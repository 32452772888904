import React, { useState } from "react";
import MuiTypography from "@mui/material/Typography";
import MuiButton from "@mui/material/Button";

const CommentBox = ({expanded=false,comment,variant, sx = {}, maxChar = 100  }) => {
  const [isExpanded, setIsExpanded] = useState(expanded);
  const maxCharCount = maxChar; // Maximum characters to show when collapsed

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const truncatedText =
    comment?.length > maxCharCount && !isExpanded
      ? `${comment.slice(0, maxCharCount)}.....`
      : comment;

  return (
      <MuiTypography variant={variant} sx={{...sx}}>
        {truncatedText}

        {comment?.length > maxCharCount && (
          <MuiButton
            size="small"
            sx={{ml:"5px",p:0,color: "#1976d2", textTransform: "none" }}
            onClick={handleToggle}
          >
            {isExpanded ? "Read Less" : "Read More"}
          </MuiButton>
        )}
      </MuiTypography>
  );
};

export default CommentBox;
