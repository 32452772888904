import axiosClient from "utils/api";

export const getAllSettings = async () => {
  const URL = "admin/setting/getSettings";
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during login:", error);
    throw error;
  }
};

export const updateSettings = async (payload) => {
  const URL = "/admin/setting/updateSettings";
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during login:", error);
    throw error;
  }
};



export const getWebSettings = async () => {
  const URL = "/setting/appSettings";
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during get web setting:", error);
    throw error;
  }
};