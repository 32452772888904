import React, { useEffect } from "react";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { DeleteHospitalAction } from "store/middlewares/users/hospitals";
import { DeleteProfessionalAction } from "store/middlewares/users/professionals";
import { SelectComponent, SubmitBtn } from "components/atoms";
import { Reason } from "utils/validation/AddDeleteReason";
import { GetAllDeleteReasonsAction } from "store/middlewares/deleteReason";
import { closeAdminModal } from "store/slices/common/adminModal.slice";
function ModalDeleteHospital({ row, type = "hospital" }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetAllDeleteReasonsAction());
  }, [dispatch]);

  const { deleteReasons } = useSelector((state) => state.deleteReasons);

  const intitalVal = {
    reasonId: "",
  };

  const AddSpecialitySchema = Yup.object({ reasonId: Reason });

  const handleSubmit = (values) => {
    if (type.toLowerCase() === "hospital") {
      dispatch(DeleteHospitalAction({ id: row.id, ...values }));
    }
    if (type.toLowerCase() === "professional") {
      dispatch(DeleteProfessionalAction({ id: row.id, ...values }));
    }
    dispatch(closeAdminModal());
  };

  const formik = useFormik({
    initialValues: intitalVal,
    validationSchema: AddSpecialitySchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <SelectComponent
        className={"px-0"}
        label={"Reason"}
        // options={deleteReasons?.map((country) => {
        //   return {
        //     label: country?.reason,
        //     value: country?.id,
        //   };
        // })}
        options={deleteReasons
          ?.filter((item) => item.isVisible)
          .map((item) => {
            return { label: item?.reason, value: item?.id };
          })}
        placeholder="Select Reason"
        name={"reasonId"}
        value={formik.values.reasonId}
        error={formik.errors.reasonId && formik.touched.reasonId}
        errorMsg={formik.errors.reasonId}
        onChange={(selectedOption) => {
          formik.setFieldValue(
            "reasonId",
            selectedOption ? selectedOption?.value : ""
          );
        }}
      />
      <SubmitBtn title={"Delete"} />
    </form>
  );
}

export default ModalDeleteHospital;
