import React, {  useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers";
import MuiBox from "../MuiBox";
import MuiTypography from "../MuiTypography";
import FormError from "../FormError";

const CustomTimePicker = ({
  label,
  onChange,
  value,
  name,
  errorMsg,
  error,
  format = "HH:mm",
  onError,
  ...rest
}) => {
  const [showMsg, setShowMsg] = useState("");
  // Error message mapping function
  const getErrorMessage = (errorType) => {
    const messages = {
      invalidDate: "Please enter a valid time",
      disableFuture: "Time cannot be in the future",
      disablePast: "Time cannot be in the past",
      minTime: "Invalid Time Range",
      maxTime: "Invalid Time Range",
      default: errorMsg,
    };
    return messages[errorType] || messages.default;
  };

  // // Update error message dynamically
  // useEffect(() => {
  //   if (error) {
  //     setShowMsg(errorMsg);
  //   } else {
  //     setShowMsg("");
  //   }
  // }, [error, errorMsg]);

  return (
    <MuiBox>
      {/* Label */}
      <MuiTypography variant="baseFont" fontWeight="bold">
        {label}
      </MuiTypography>

      {/* Time Picker */}
      <MuiBox sx={{ mt: "8px" }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimePicker
            value={value}
            name={name}
            timezone="system"
            format={format}
            slotProps={{
              textField: {
                error: error,
              },
            }}
            onChange={(e) => {
              onChange(e); // Propagate the change event
            }}
            onError={(errorType) => {
              if (onError) {
                onError(errorType); // Pass error to parent if needed
              }
              setShowMsg(getErrorMessage(errorType)); // Handle error message
            }}
            className="time-picker-custom"
            sx={{
              bgcolor: "#fff",
              borderRadius: "12px",
              width: "100%",
            }}
            {...rest}
          />
          {/* Error Message Display */}
          {showMsg && <FormError errorMsg={showMsg} />}
        </LocalizationProvider>
      </MuiBox>
    </MuiBox>
  );
};

export default CustomTimePicker;
