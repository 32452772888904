import React, { useEffect } from "react";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { SubmitBtn, InputTextarea, SelectComponent } from "components/atoms";
import { BlockProfessionalAction } from "store/middlewares/users/professionals";
import { GetAllBlockReasonAction } from "store/middlewares/blockReason";
import { closeAdminModal } from "store/slices/common/adminModal.slice";

function ModalBlockUser({ row }) {
  const dispatch = useDispatch();
  const { blockReasons } = useSelector((state) => state.blockReasons);

  useEffect(() => {
    dispatch(GetAllBlockReasonAction());
  }, [dispatch]);

  const intitalVal = {
    blockReason: "",
    reasonId: "",
  };

  const AddJobTitleSchema = Yup.object().shape({
    reasonId: Yup.string().required("Reason ID is required"),
    blockReason: Yup.string().test(
      "is-required-if-other",
      "Reason is required",
      function (value) {
        const { reasonId } = this.parent; // Access other fields
        if (reasonId === "other") {
          return !!value; // Ensure blockReason is non-empty
        }
        return true; // No validation needed for other values
      }
    ),
  });

  const handleSubmit = (values) => {
    const filterValues = {
      blockReason:
        values.reasonId === "other" ? values.blockReason : values.reasonId,
    };
    dispatch(closeAdminModal());
    dispatch(BlockProfessionalAction({ id: row.id, blockReason: filterValues })
    );
  };

  const formik = useFormik({
    initialValues: intitalVal,
    validationSchema: AddJobTitleSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <SelectComponent
        className={"px-0"}
        label={"Reason"}
        // options={[
        //   { label: "Other", value: "other" },
        //   ...blockReasons
        //     ?.filter((item) => item.isVisible)
        //     .map((item) => {
        //       return { label: item?.reason, value: item?.id };
        //     }),
        // ]}
        options={[
          ...(Array.isArray(blockReasons)
            ? blockReasons
                .filter(
                  (item) => item.isVisible && item.type === "professional"
                )
                .map((opt) => ({
                  label: opt.reason,
                  value: opt.id,
                }))
            : []),
          { label: "Other", value: "other" },
        ]}
        placeholder="Select Reason"
        name={"reasonId"}
        value={formik.values.reasonId}
        error={formik.errors.reasonId && formik.touched.reasonId}
        errorMsg={formik.errors.reasonId}
        onChange={(selectedOption) => {
          formik.setFieldValue(
            "reasonId",
            selectedOption ? selectedOption?.value : ""
          );
        }}
      />
      {formik.values.reasonId === "other" && (
        <InputTextarea
          textareaClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 mt-2 no-scrollbar"
          label={"Block Reason"}
          name="blockReason"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.blockReason && formik.touched.blockReason}
          errorMsg={formik.errors.blockReason}
          value={formik.values.blockReason}
          placeholder="Provide a reason for blocking"
        />
      )}
      <SubmitBtn title={"Block"} />
    </form>
  );
}

export default ModalBlockUser;
