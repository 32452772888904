import axiosClient from "utils/api";
const URI = "/admin";
const WEB = "/hospital";

export const getAllHospitals = async (payload) => {
  let { type, ...rest } = payload;
  let tempObj = {};
  Object.keys(rest)?.forEach((key) => {
    if (rest[key]) {
      tempObj = { ...tempObj, [`${key}`]: rest[key] };
    }
  });
  const URL = `${URI}/hospital/hospitals/${type}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
      params: tempObj,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Get All Hospitals:", error);
    throw error;
  }
};

export const getHospitalById = async (payload) => {
  let { id } = payload;
  const URL = `${URI}/hospital/hospital/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Get Hospital:", error);
    throw error;
  }
};

export const addHospital = async (payload) => {
  const URL = `${URI}/hospital/hospital`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Add Hospital:", error);
    throw error;
  }
};

export const editHospital = async (payload) => {
  let { id, ...rest } = payload;
  const URL = `${URI}/hospital/hospital/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Edit Hospital:", error);
    throw error;
  }
};

export const activeHospital = async (payload) => {
  const { id } = payload;
  const URL = `${URI}/hospital/active/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Active Hospital:", error);
    throw error;
  }
};

export const deleteHospital = async (payload) => {
  let { id, ...rest } = payload;
  const URL = `${URI}/hospital/hospital/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "delete",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Delete Hospital:", error);
    throw error;
  }
};

export const blockHospital = async (payload) => {
  let { id, blockReason } = payload;
  const URL = `${URI}/hospital/block/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
      data: blockReason,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Block Hospital:", error);
    throw error;
  }
};

export const unblockHospital = async (payload) => {
  let { id } = payload;
  const URL = `${URI}/hospital/unblock/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Unblock Hospital:", error);
    throw error;
  }
};

export const restoreHospital = async (payload) => {
  const { id } = payload;
  const URL = `${URI}/hospital/restore/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Restore Hospital:", error);
    throw error;
  }
};

export const getSubscriptionHospital = async (payload) => {
  const URL = `${URI}/subscriptionData/subscriberData/${payload.id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Subscription Hospital:", error);
    throw error;
  }
};

// get Address
export const getAddressHospital = async (payload) => {
  let { id } = payload;
  const URL = `${URI}/hospitalAddress/addressById/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during get Address Hospital:", error);
    throw error;
  }
};

export const editAddressHospital = async (payload) => {
  let { id, ...rest } = payload;

  const URL = `${URI}/hospitalAddress/address/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Edit Address Hospital:", error);
    throw error;
  }
};

export const hospitalHome = async () => {
  const URL = `${WEB}/hospitalHome`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Hospital Home:", error);
    throw error;
  }
};

export const getNearByProfessionals = async (payload) => {
  const URL = `${WEB}/nearByProfessionals`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
      params: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Hospital Home:", error);
    throw error;
  }
};

export const getAllOngoingShift = async (payload) => {
  const URL = `${WEB}/getAllShifts`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
      params: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during get on-going shifts:", error);
    throw error;
  }
};

export const getNearByProfessionalById = async (payload) => {
  let { id, ...rest } = payload;
  const URL = `${WEB}/nearByProfessionals/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
      params: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Hospital Home:", error);
    throw error;
  }
};
