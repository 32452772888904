import { createSlice } from "@reduxjs/toolkit";

export const errorMessageSlice = createSlice({
  name: "errorMessage",
  initialState: {
    errorMessage: "",
    field: "",
  },
  reducers: {
    setErrorMessage: (state, { payload }) => {
      state.errorMessage = payload.message;
      state.field = payload.field;
    },
    resetErrorMessage: (state) => {
      state.errorMessage = "";
      state.field = "";
    },
  },
});

export const { setErrorMessage, resetErrorMessage } = errorMessageSlice.actions;

export default errorMessageSlice.reducer;
