import { Box, IconButton } from "@mui/material";
import { ChevronsRight, CircleX } from "lucide-react";
import React from "react";
import { useDispatch } from "react-redux";
import DeleteIconSVGModel from "assets/images/deleteIconSVGModel.svg";
import Swal from "sweetalert2";
import { TooltipProvider } from "components/atoms";
import { useNavigate } from "react-router-dom";
import { CancelProfessionalUserShift } from "store/middlewares/users/schedule";

const Shift = ({ row }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <Box className="flex h-full items-center justify-end gap-3">
      <Box>
        {row?.status !== "cancelled" && row?.status !== "completed" && (
          <IconButton
            color="error"
            // sx={{ padding: "0 !important" }}
            disableRipple
            onClick={() => {
              Swal.fire({
                title: "Are you sure you want to Cancel This Shift?",
                imageUrl: DeleteIconSVGModel,
                imageWidth: 100,
                imageHeight: 100,
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Confirm",
                customClass: {
                  title: "Swal-title-class",
                  popup: "custom-swal-popup",
                },
              }).then((result) => {
                if (result.isConfirmed) {
                  dispatch(CancelProfessionalUserShift({ id: row.id }));
                }
              });
            }}
          >
            <TooltipProvider title="Cancel Shift">
              <CircleX />
            </TooltipProvider>
          </IconButton>
        )}
        <IconButton
          color="info"
          disableRipple
          onClick={() => navigate(`/admin/shift/${row?.id}`)}
        >
          <TooltipProvider title="Detail">
            <ChevronsRight />
          </TooltipProvider>
        </IconButton>
      </Box>
    </Box>
  );
};

export default Shift;
