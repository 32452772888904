import axiosClient from "utils/api";
const URI = "/admin/dynamicMenu";

export const getDynamicMenu = async () => {
  const URL = `${URI}/dynamicMenu`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Get All Dynamic Menu:", error);
    throw error;
  }
};

export const getWebDynamicMenu = async () => {
  const URL = `/dynamicMenu/dynamicMenu`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Get All Web Dynamic Menu:", error);
    throw error;
  }
};

export const updateDynamicMenu  = async (payload) => {
  let { id, ...rest } = payload;
  const URL = `${URI}/dynamicMenu/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Update DynamicMenu :", error);
    throw error;
  }
};

