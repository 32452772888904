import { createSlice } from "@reduxjs/toolkit";
import {GetAllDynamicMenu,GetWebAllDynamicMenu,UpdateDynamicMenuAction} from "store/middlewares/dynamicMenu";

// isDeleteMeFulFilled
export const dynamicMenuSlice = createSlice({
  name: "dynamicMenu",
  initialState: {
    dynamicMenuLoading: false,
    dynamicMenu: [],
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(GetAllDynamicMenu.pending, (state) => {
        state.dynamicMenuLoading = true;
        state.dynamicMenu = [];
      })
      .addCase(GetAllDynamicMenu.fulfilled, (state, action) => {
        state.dynamicMenuLoading = false;
        state.dynamicMenu = action.payload.response;
      })
      .addCase(GetAllDynamicMenu.rejected, (state) => {
        state.dynamicMenuLoading = false;
        state.dynamicMenu = [];
      })
      //web
      .addCase(GetWebAllDynamicMenu.pending, (state) => {
        state.dynamicMenuLoading = true;
        state.dynamicMenu = [];
      })
      .addCase(GetWebAllDynamicMenu.fulfilled, (state, action) => {
        state.dynamicMenuLoading = false;
        state.dynamicMenu = action.payload.response;
      })
      .addCase(GetWebAllDynamicMenu.rejected, (state) => {
        state.dynamicMenuLoading = false;
        state.dynamicMenu = [];
      })
      //Update  dynamic menu
      .addCase(UpdateDynamicMenuAction.fulfilled, (state, action) => {
        let {
          response: { id, ...rest },
        } = action.payload;
        
        let tempArray = state.dynamicMenu?.map((title) => {
          
          if (title.id === id) {
            return { ...title, ...rest };
          }
          return { ...title };
        });
        state.dynamicMenuLoading = false;
        state.dynamicMenu = tempArray;
      })

  },
});

export default dynamicMenuSlice.reducer;
