import { MuiBox, MuiTypography, WebInput } from "components/atoms";
import React, { useEffect } from "react";
import * as Yup from "yup";
import ForgotPassword from "assets/images/SVG/ForgotPassword.svg";
import DarkEmailIcon from "assets/images/SVG/DarkEmailIcon.svg";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { email } from "utils/validation/web/LoginForm";
import { ForgotPasswordWebAction } from "store/middlewares/session/auth";
import { resetErrorMessage } from "store/slices/common/errorMessage.slice";

const ForgotPasswordForm = () => {
  const dispatch = useDispatch();

  const { errorMessage, field } = useSelector((state) => state.errorMessage);

  const handleSubmit = (values) => {
    dispatch(ForgotPasswordWebAction(values));
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: email,
    }),
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (errorMessage) {
      formik.setFieldError(field, errorMessage);
    }
  }, [errorMessage, field, formik]);

  return (
    <MuiBox className="forgot-container">
      <MuiBox sx={{ maxWidth: "266px", flexShrink: 1, margin: "68px auto 0" }}>
        <img src={ForgotPassword} alt="about care" className="img-contain" />
      </MuiBox>
      <MuiTypography variant="h4" align={"center"} fontWeight="bold">
        Forgot Password
      </MuiTypography>
      <form className="forgot-form" onSubmit={formik.handleSubmit}>
        <WebInput
          type="email"
          variant="plain"
          label={"Email"}
          name={"email"}
          placeholder="Enter Your Email"
          onChange={(e) => {
            formik.handleChange(e);
            if (errorMessage) {
              dispatch(resetErrorMessage());
            }
          }}
          onBlur={formik.handleBlur}
          value={formik.values.email || ""}
          errorMsg={formik.errors.email}
          error={formik.touched.email && formik.errors.email}
          icon={<img src={DarkEmailIcon} alt="email" />}
          required={true}
        />
        <Button variant="lightButton" type="submit">
          <MuiTypography
            variant="baseFont"
            fontWeight={"bold"}
            textTransform={"capitalize"}
          >
            Done
          </MuiTypography>
        </Button>
      </form>
    </MuiBox>
  );
};

export default ForgotPasswordForm;
