import { Button, Checkbox } from "@mui/material";
import { MuiBox, MuiTypography } from "components/atoms";
import React from "react";

import ToggleSwitch from "assets/images/SVG/Toggle switch.svg";
import ToggleSwitchOn from "assets/images/SVG/Toggle switch on.svg";

const HeadingBar = ({
  title,
  onClickFunc,
  onChangeToggle,
  btn = true,
  toggleBtn = false,
  CheckedValue,
  btnText = "View All",
}) => {

  return (
    <MuiBox
      sx={{
        display: "flex",
        gap: 1,
        alignItems: "center",
        justifyContent: "space-between",
        mb: "22px",
        px: 1,
      }}
    >
      <MuiTypography variant="h6" fontWeight="bold">
        {title}
      </MuiTypography>
      {btn && (
        <Button
          sx={{
            backgroundColor: "button.main",
            color: "white",
            fontWeight: 600,
            textTransform: "none",
            borderRadius: "12px",
            p: "7px 10px",
            "&:hover": { backgroundColor: "#225f75" },
          }}
          onClick={onClickFunc}
        >
          {btnText}
        </Button>
      )}
      {toggleBtn && (
        <Checkbox
          icon={<img src={ToggleSwitch} />}
          checkedIcon={<img src={ToggleSwitchOn} />}
          checked={CheckedValue}
          onChange={onChangeToggle}
        />
      )}
    </MuiBox>
  );
};

export default HeadingBar;
