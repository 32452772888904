import React from "react";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { SuccessModalAdmin } from "components/molecules";
import {
  getAllProfessional,
  addProfessional,
  editProfessional,
  getProfessionalById,
  getHolidaysForProfessional,
  deleteProfessional,
  blockProfessionalUser,
  getProfessionalUserHolidays,
  unblockProfessionalUser,
  restoreProfessionalUser,
  activeProfessionalUser,
  addUploadFile,
  getAllProfessionalNotification,
  getProfessionalExperience,
  getAllNearbyHospitals,
  getHospitalsDetails,
  getProfessionalHome,
  getHospitalJobDetails,
  applyHospitalJob,
  getHospitalJobsById,
  updateProfessionalAvailability,
  getAllScheduleDateAndTime,
  giveReviewsProfessional,
  getAllProfessionalReviews,
} from "store/services/users/professionals";

import {
  closeAdminModal,
  openAdminModal,
} from "store/slices/common/adminModal.slice.js";
import { openToast } from "store/slices/common/toast.slice.js";
import { handleAuthError } from "utils/handleAuthError.js";
import { closeDrawer } from "store/slices/common/drawer.slice";

export const GetAllProfessionalAction = createAsyncThunk(
  "professionals/getAllProfessional",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getAllProfessional(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

export const GetProfessionalHomeAction = createAsyncThunk(
  "professionals/getProfessionalHome",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getProfessionalHome();
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      handleAuthError(error?.response?.status, true);
      return rejectWithValue(error);
    }
  }
);

export const GetProfessionalByIdAction = createAsyncThunk(
  "professionals/getProfessionalByIdAction",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await getProfessionalById(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.message }));
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

export const GetProfessionalExperience = createAsyncThunk(
  "professionals/getProfessionalExperience",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getProfessionalExperience(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

export const AddProfessionalAction = createAsyncThunk(
  "professionals/addProfessionalAction",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      let { file, uploadFor, ...rest } = payload;
      let url = "";
      if (file) {
        const uploadFileResponse = await addUploadFile({ file, uploadFor });
        if (uploadFileResponse.status === 1) {
          url = uploadFileResponse?.response?.urls[0];
        }
      }
      let addPayload = { ...rest };
      if (url) {
        addPayload = { ...addPayload, profileImage: url };
      }

      const response = await addProfessional(addPayload);
      if (response.status === 1) {
        window.location = "/admin/professional/active";
        return fulfillWithValue(response);
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

export const EditProfessionalAction = createAsyncThunk(
  "professionals/editProfessionalAction",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      let { file, uploadFor, ...rest } = payload;
      let url = "";
      if (typeof file !== "string" && file !== null) {
        const uploadFileResponse = await addUploadFile({ file, uploadFor });
        if (uploadFileResponse.status === 1) {
          url = uploadFileResponse?.response?.urls[0];
        }
      }
      let editPayload = { ...rest };
      if (url) {
        editPayload = { ...editPayload, profileImage: url };
      }

      const response = await editProfessional(editPayload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        window.history.go(-1);
        dispatch(openToast({ type: "success", message: response?.message }));
        return fulfillWithValue(response);
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

export const DeleteProfessionalAction = createAsyncThunk(
  "users/deleteProfessionalAction",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await deleteProfessional(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        // dispatch(openToast({ type: "success", message: response?.message }));
        dispatch(
          openAdminModal({
            maxWidth: "xs",
            content: (
              <SuccessModalAdmin
                msg={response?.message}
                onClick={() => {
                  window.location = "/admin/professional/active";
                }}
              />
            ),
          })
        );
        // window.location = "/admin/professional/active";
        return fulfillWithValue(response);
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

export const GetHolidaysForProfessional = createAsyncThunk(
  "professionals/getHolidaysForProfessional",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await getHolidaysForProfessional(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.message }));
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

// For Active User
export const ActiveProfessionalAction = createAsyncThunk(
  "professionals/activeProfessionalAction",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await activeProfessionalUser(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeAdminModal());
        dispatch(
          openAdminModal({
            maxWidth: "xs",
            content: <SuccessModalAdmin msg={response?.message} />,
          })
        );
        // dispatch(openToast({ type: "success", message: response?.message }));
        return fulfillWithValue(response);
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

// For Block User
export const BlockProfessionalAction = createAsyncThunk(
  "professionals/blockProfessionalAction",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await blockProfessionalUser(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeAdminModal());
        // dispatch(openToast({ type: "success", message: response?.message }));
        dispatch(
          openAdminModal({
            maxWidth: "xs",
            content: <SuccessModalAdmin msg={response?.message} />,
          })
        );
        return fulfillWithValue(response);
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

// For Unblock User
export const UnblockProfessionalAction = createAsyncThunk(
  "professionals/unblockProfessionalAction",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await unblockProfessionalUser(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeAdminModal());
        // dispatch(openToast({ type: "success", message: response?.message }));
        dispatch(
          openAdminModal({
            maxWidth: "xs",
            content: <SuccessModalAdmin msg={response?.message} />,
          })
        );
        return fulfillWithValue(response);
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

// For Restore User
export const RestoreProfessionalAction = createAsyncThunk(
  "professionals/restoreProfessionalAction",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    let { goBack, ...rest } = payload;
    try {
      const response = await restoreProfessionalUser(rest);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeAdminModal());
        if (goBack) {
          window.location = "/admin/professional/active";
        }
        dispatch(
          openAdminModal({
            maxWidth: "xs",
            content: <SuccessModalAdmin msg={response?.message} />,
          })
        );
        // dispatch(openToast({ type: "success", message: response?.message }));
        return fulfillWithValue(response);
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

// For Get Holidays Data For User
export const GetProfessionalUserHolidays = createAsyncThunk(
  "professionals/getProfessionalUserHolidays",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getProfessionalUserHolidays(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

//get professional notification
export const GetNotificationForProfessional = createAsyncThunk(
  "professionals/getNotificationForProfessional",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await getAllProfessionalNotification(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.message }));
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

export const GetAllNearByHospitalAction = createAsyncThunk(
  "professionals/getAllNearByHospital",
  async (payload, { rejectWithValue, getState, fulfillWithValue }) => {
    try {
      const response = await getAllNearbyHospitals(payload);
      const { users } = getState();
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        let tempArray = response.response;
        if (payload.page > 1) {
          let prevItems = users?.users?.items ? users?.users?.items : [];
          tempArray = [...prevItems, ...tempArray];
        }
        let responseObj = {
          items: tempArray,
          totalPages: response?.response?.length === 0 ? payload.page : null,
        };
        return fulfillWithValue({ response: responseObj });
      }
      let responseObj = {
        items: users?.users?.items,
        totalPages: users?.users?.totalPages
          ? users?.users?.totalPages
          : payload.page,
      };
      let tempObj = { message: response?.message, response: responseObj };
      return rejectWithValue(tempObj);
    } catch (error) {
      handleAuthError(error?.response?.status, true);
      return rejectWithValue(error);
    }
  }
);

export const GetHospitalDetailsAction = createAsyncThunk(
  "professionals/getHospitalDetails",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getHospitalsDetails(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      handleAuthError(error?.response?.status, true);
      return rejectWithValue(error);
    }
  }
);

// For Get All Hospital's Job List By Id
export const GetHospitalJobByIdAction = createAsyncThunk(
  "professionals/getHospitalJobsById",
  async (payload, { rejectWithValue, getState, fulfillWithValue }) => {
    try {
      const response = await getHospitalJobsById(payload);
      const { jobs } = getState();
      if (response.status === 1) {
        let tempArray = response.response;
        if (payload.page > 1) {
          tempArray = [...jobs.jobs.items, ...tempArray];
        }
        let responseObj = { items: tempArray, totalPages: null };
        return fulfillWithValue({ response: responseObj });
      }
      let responseObj = {
        items: payload.page === 1 ? response.response : jobs.jobs.items,
        totalPages: payload.page,
      };
      let tempObj = { message: response?.message, response: responseObj };
      return rejectWithValue(tempObj);
    } catch (error) {
      console.log(error, "error");
      handleAuthError(error?.response?.status, true);
      return rejectWithValue(error);
    }
  }
);

export const GetHospitalJobDetailsAction = createAsyncThunk(
  "professionals/getHospitalJobDetails",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getHospitalJobDetails(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      handleAuthError(error?.response?.status, true);
      return rejectWithValue(error);
    }
  }
);

// For Get Schedule's All Shifts Date And Time
export const GetAllScheduleDateAndTimeAction = createAsyncThunk(
  "professionals/getAllScheduleDateAndTime",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getAllScheduleDateAndTime(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      handleAuthError(error?.response?.status, true);
      return rejectWithValue(error);
    }
  }
);

export const ApplyHospitalJobAction = createAsyncThunk(
  "professionals/applyHospitalJob",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await applyHospitalJob(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue({ ...response, id: payload.jobId });
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      handleAuthError(error?.response?.status, true);
      return rejectWithValue(error);
    }
  }
);

export const UpdateProfessionalAvailability = createAsyncThunk(
  "professionals/updateProfessionalAvailability",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await updateProfessionalAvailability(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      handleAuthError(error?.response?.status, true);
      return rejectWithValue(error);
    }
  }
);

// For Give Reviews
export const GiveReviewsProfessionalAction = createAsyncThunk(
  "professionals/giveReviewsProfessional",
  async (payload, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await giveReviewsProfessional(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeDrawer());
        window.location.reload();
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      handleAuthError(error?.response?.status, true);
      return rejectWithValue(error);
    }
  }
);

// Get professional's all reviews
export const GetAllProfessionalReviewsAction = createAsyncThunk(
  "professionals/getAllProfessionalReviews",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getAllProfessionalReviews(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);