import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { MuiBox, MuiContainer, MuiTypography } from "components/atoms";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import { Outlet } from "react-router-dom";
import AssistedCare from "assets/images/SVG/AssistedCare.svg";
import HeathCare from "assets/images/SVG/Heath&Care.svg";

const AuthLayout = ({ children }) => {
  return (
    <MuiContainer className="auth-layout-container">
      <Grid container sx={{ minHeight: "100dvh" }}>
        <Grid xs={12} md={4} lg={4} className="auth-layout-grid-item">
          <Swiper
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            pagination={true}
            modules={[Autoplay, Pagination]}
            className="auth-swiper"
            slidesPerView={1}
          >
            {swiperSlides.map((item, index) => (
              <SwiperSlide key={index}>
                <MuiBox
                  sx={{ p: 2, backgroundColor: `${item.bg}` }}
                  className="auth-swiper-slide"
                >
                  <MuiBox sx={{ height: "auto" }} className="auth-swiper-img">
                    <img
                      src={item.img}
                      alt={`${item.alt}`}
                      className="img-cover"
                    />
                  </MuiBox>
                  <MuiBox sx={{ height: "auto", maxWidth: "465px" }}>
                    <MuiTypography
                      variant="h2"
                      sx={{ color: "sliderText.main", mb: 2 }}
                      className="text-center"
                    >
                      {item.title}
                    </MuiTypography>
                    <MuiTypography
                      variant="basefont"
                      sx={{
                        color: "sliderText.light",
                        display: "flex",
                        mb: 2,
                        lineHeight: "20px",
                      }}
                      className="text-center"
                    >
                      {item.text}
                    </MuiTypography>
                  </MuiBox>
                </MuiBox>
              </SwiperSlide>
            ))}
          </Swiper>
        </Grid>
        <Grid xs={12} md={8} lg={8} className="auth-layout-grid-item">
          <MuiContainer className="auth-box">
            <MuiBox
              sx={{
                height: { xs: "100%", md: "100dvh" },
                p: "15px",
                overflowY: "auto",
              }}
            >
              {children ? children : <Outlet />}
            </MuiBox>
          </MuiContainer>
        </Grid>
      </Grid>
    </MuiContainer>
  );
};

export default AuthLayout;

const swiperSlides = [
  {
    bg: "#D0ECF5",
    img: AssistedCare,
    alt: "Assisted Living Care",
    title: "Assisted living care",
    text: "Empowering independence with compassionate care, tailored support, and a warm community. Your loved ones deserve the best at every stage.",
  },
  {
    bg: "#E9E7F7",
    img: HeathCare,
    alt: "Health & Care",
    title: "Health Care for Older people",
    text: "Comprehensive, compassionate health care for older adults. Prioritizing wellness, dignity, and quality of life every step of the way.",
  },
];
