import React from "react";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { Input, SelectComponent, SubmitBtn } from "components/atoms";
import { Name, Province } from "utils/validation/AddCity";
import { AddCityAction, UpdateCityAction } from "store/middlewares/cities";

function ModalEditCities({ row }) {
  const dispatch = useDispatch();

  const EditCategorySchema = Yup.object({ name: Name, provinceId: Province });

  const { provinces } = useSelector((state) => state.provinces);

  const handleSubmit = (values) => {
    if (row) {
      dispatch(UpdateCityAction({ id: row.id, ...values }));
    } else {
      dispatch(AddCityAction(values));
    }
  };

  const formik = useFormik({
    initialValues: {
      name: row?.name ? row?.name : "",
      provinceId: row?.province_id ? row?.province_id : "",
    },
    validationSchema: EditCategorySchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Input
        inputClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 mt-2"
        label={"Name"}
        type="text"
        name="name"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.name && formik.touched.name}
        errorMsg={formik.errors.name}
        value={formik.values.name}
        placeholder="Enter Name"
      />
      <SelectComponent
        className={"px-0"}
        label={"Province"}
        options={provinces?.map((country) => {
          return {
            label: country?.name,
            value: country?.id,
          };
        })}
        placeholder="Select Province"
        name={"provinceId"}
        value={formik.values.provinceId}
        error={formik.errors.provinceId && formik.touched.provinceId}
        errorMsg={formik.errors.provinceId}
        onChange={(selectedOption) => {
          formik.setFieldValue(
            "provinceId",
            selectedOption ? selectedOption.value : ""
          );
        }}
      />
      <SubmitBtn title={row ? "Update" : "Add"} />
    </form>
  );
}

export default ModalEditCities;
