import React, { createContext, useContext, useState, useEffect } from "react";

const GooglePlacesContext = createContext();

export const GooglePlacesProvider = ({ children }) => {
  const [placeData, setPlaceData] = useState({});
  const [isInitialized, setIsInitialized] = useState(false);

  const loadGooglePlacesAPI = () => {
   
    // eslint-disable-next-line no-undef
    const googleMapsURL = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;

    if (document.querySelector(`script[src="${googleMapsURL}"]`)) {
      setIsInitialized(true);// Script already loaded
      return;
    }

    const script = document.createElement("script");
    script.src = googleMapsURL;
    script.async = true;
    script.onload = () => setIsInitialized(true);
    script.onerror = () => console.error("Failed to load Google Maps script.");
    document.head.appendChild(script);
  };

  useEffect(() => {
    loadGooglePlacesAPI();
  }, []);

  const initializePlaces = (inputRef, onPlaceChange) => {
    if (!isInitialized || !window.google || !inputRef?.current) {
      console.error("Google Maps API is not fully loaded or inputRef is invalid.");
      return;
    }

    if (inputRef.current.autocomplete) {
      console.log("Autocomplete already initialized");
      return;
    }

    const autoCompleteInstance = new window.google.maps.places.Autocomplete(
      inputRef.current,
      { componentRestrictions: { country: "IN" } }
    );

    inputRef.current.autocomplete = autoCompleteInstance;

    autoCompleteInstance.addListener("place_changed", () => {
      const place = autoCompleteInstance.getPlace();
      const components = place?.address_components || [];

      const getComponent = (type) => {
        const component = components.find((comp) => comp.types.includes(type));
        return component ? component.long_name : null;
      };

      const placeDetails = {
        firstAddress: place?.name,
        secondAddress: getComponent("route"),
        landmark: getComponent("neighborhood"),
        city: getComponent("locality"),
        state: getComponent("administrative_area_level_1"),
        country: getComponent("country"),
        zipCode: getComponent("postal_code"),
        latLng: {
          lat: place?.geometry?.location?.lat(),
          lng: place?.geometry?.location?.lng(),
        },
      };

      setPlaceData((prev) => ({
        ...prev,
        [inputRef.current.id]: placeDetails,
      }));

      if (onPlaceChange) onPlaceChange(placeDetails);
    });
  };

  return (
    <GooglePlacesContext.Provider value={{ initializePlaces, placeData }}>
      {children}
    </GooglePlacesContext.Provider>
  );
};

export const useGooglePlaces = () => {
  const context = useContext(GooglePlacesContext);
  if (!context) {
    throw new Error("useGooglePlaces must be used within a GooglePlacesProvider");
  }
  return context;
};
