import { MuiBox, MuiTypography } from "components/atoms";
import React from "react";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination } from "swiper/modules";

import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
const InOutSelfieModals = ({ shift }) => {
  return (
    <MuiBox
      sx={{
        px: { xs: "15px", sm: "25px", md: "45px" },
        py: 5,
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      <MuiTypography variant="h6" fontWeight={"bold"} align={"center"}>
        Selfie Photo
      </MuiTypography>

      <MuiBox>
        <Swiper
          pagination={{
            type: "fraction",
          }}
          // navigation={true}
          modules={[Pagination]}
          className="selfie-swiper"
        >
          <SwiperSlide>
            <MuiBox
              sx={{ height: "751px", overflow: "hidden", borderRadius: "5px" }}
            >
              <img src={shift?.clockInSelfieUrl} className="img-cover" />
            </MuiBox>
          </SwiperSlide>
          {shift?.clockOutSelfieUrl &&
            <SwiperSlide>
              <MuiBox
                sx={{ height: "751px", overflow: "hidden", borderRadius: "5px" }}
              >
                <img src={shift?.clockOutSelfieUrl} className="img-cover" />
              </MuiBox>
            </SwiperSlide>
          }
        </Swiper>
      </MuiBox>

      <MuiBox sx={{ p: "0 16px" }}>
        <MuiBox>
          <MuiTypography variant="h6" fontWeight="bold" color="#0CA8DF">
            Clock in selfie picture
          </MuiTypography>
          <MuiTypography variant="baseFont">
            {dayjs(shift?.scheduleCurrentDate).format("MMM D, YYYY")} {" "}
            {dayjs(shift?.clockInTime).format(
              "hh:mm A"
            )}{" "}
            {/* {shift.clockOutTime &&
              <>
                -{" "}
                {dayjs(shift?.clockOutTime).format(
                  "hh:mm A"
                )}
              </>
            } */}
          </MuiTypography>
        </MuiBox>
        {shift.clockOutTime &&
          <MuiBox sx={{ mt: 2 }}>
            <MuiTypography variant="h6" fontWeight="bold" color="#0CA8DF">
              Clock Out selfie picture
            </MuiTypography>
            <MuiTypography variant="baseFont">
              {dayjs(shift?.scheduleCurrentDate).format("MMM D, YYYY")} {" "}
              {dayjs(shift?.clockOutTime).format(
                "hh:mm A"
              )}
            </MuiTypography>
          </MuiBox>
        }
      </MuiBox>
    </MuiBox>
  );
};

export default InOutSelfieModals;
