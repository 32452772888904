import React from "react";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { Input, Radio, SubmitBtn } from "components/atoms";
import { AddDeleteReasonAction, UpdateDeleteReasonAction } from "store/middlewares/deleteReason";
import { Reason } from "utils/validation/AddDeleteReason";

function ModalEditDeleteReason({ row }) {
  const dispatch = useDispatch();

  const AddDeleteReasonSchema = Yup.object({ reason: Reason });
  const handleSubmit = (values) => {
    if (row) {
      dispatch(UpdateDeleteReasonAction({ id: row?.id, ...values }));
    } else {
      dispatch(AddDeleteReasonAction(values));
    }
  };

  const formik = useFormik({
    initialValues: {
      reason: row?.reason ? row?.reason : "",
      type: row?.type ? row?.type : "hospital",
    },
    validationSchema: AddDeleteReasonSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  const radioOptions = [
    { value: "hospital", label: "Hospital" },
    { value: "professional", label: "Professional" },
  ];

  return (
    <form onSubmit={formik.handleSubmit}>
      <Input
        inputClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 mt-2"
        label={"Reason"}
        error={formik.errors.reason && formik.touched.reason}
        errorMsg={formik.errors.reason}
        value={formik.values.reason}
        type="text"
        name="reason"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        placeholder="Enter Reason"
      />
      <Radio
        label="Type"
        RadioOption={radioOptions}
        name="type"
        value={formik.values.type}
        onChange={(e) => {
          formik.setFieldValue("type", e.target.value);
        }}
      />
      <SubmitBtn title={"Update"} />
    </form>
  );
}

export default ModalEditDeleteReason;
