import * as React from "react";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";
import { MuiBox, MuiTypography } from "components/atoms";

// const data = [
//   { label: "Job Posted", value: 18, color: "#0A1E41" }, // Blue
//   { label: "Healthcare Professional", value: 25, color: "#0CA8DF" }, // Green
// ];

const sizing = {
  margin: { right: 0 },
  width: 340,
  height: 400,
  legend: { hidden: true },
};

function Legend({ data }) {
  return (
    <MuiBox
      sx={{
        display: "flex",
        flexDirection: "column",
        marginLeft: "20px",
        gap: "10px",
        maxWidth: { xs: "12em", sm: "18em" },
        width: "100%",
      }}
    >
      {data.map((item, index) => (
        <MuiBox
          key={index}
          sx={{ display: "flex", alignItems: "flex-start", gap: "10px" }}
        >
          <MuiBox
            sx={{
              width: { xs: "13px", sm: "24px" },
              height: { xs: "13px", sm: "24px" },
              backgroundColor: item.color,
              // marginRight: ,
              borderRadius: "50%",
              flexShrink: 0,
            }}
          />
          <MuiBox
            sx={{
              width: "fit-content",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <MuiTypography
              sx={{
                fontSize: { xs: "14px", sm: "16px" },
                fontWeight: "bold",
                lineHeight: "19px",
              }}
            >
              {item.label}
            </MuiTypography>
            <MuiTypography
              sx={{
                fontSize: { xs: "20px", sm: "32px" },
                fontWeight: "bold",
                color: "#404D6E",
              }}
            >
              {item.value}
            </MuiTypography>
          </MuiBox>
        </MuiBox>
      ))}
    </MuiBox>
  );
}

export default function PieChartWithCustomizedLabel({ data }) {
  const TOTAL = data.map((item) => item.value).reduce((a, b) => a + b, 0);

  const getArcLabel = (params) => {
    const percent = params.value / TOTAL;
    return `${(percent * 100).toFixed(0)}%`;
  };
  return (
    <>
      <MuiBox
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        <PieChart
          className="responsive-pie-chart"
          series={[
            {
              outerRadius: 150,
              data,
              arcLabel: getArcLabel,
              colorField: "color",
            },
          ]}
          sx={{
            [`& .${pieArcLabelClasses.root}`]: {
              fill: "white",
              fontSize: 25,
            },
            flexShrink: 1,
          }}
          {...sizing}
        />
        <Legend data={data} />
      </MuiBox>
    </>
  );
}
