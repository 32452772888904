export const ImagesFileTypes = [
    "image/jpeg", "image/jpg", "image/png", "image/gif", "image/bmp",
    "image/tiff", "image/webp", "image/heif", "image/heic",
    "image/x-canon-cr2", "image/x-nikon-nef", "image/x-sony-arw",
    "image/jfif", "image/avif", "image/bpg"
];

export const ImagesFileTypesAndPdf = [
    "image/jpeg", "image/jpg", "image/png", "image/gif", "image/bmp",
    "image/tiff", "image/webp", "image/heif", "image/heic",
    "image/x-canon-cr2", "image/x-nikon-nef", "image/x-sony-arw",
    "image/jfif", "image/avif", "image/bpg", "application/pdf"
];