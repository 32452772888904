import React, { useContext } from "react";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { SelectComponent, SubmitBtn } from "components/atoms";
import { FilterPopoverContext } from "context/PopoverContext";
import { setRolesFilterProps } from "store/slices/admin/accessManagement/roles";

const RolesFilter = () => {
  const dispatch = useDispatch();
  const { handleClose } = useContext(FilterPopoverContext);
  const { access } = useSelector((state) => state.access);
  const { rolesFilterProps } = useSelector((state) => state.roles);

  const intitalVal = {
    isVisible: "",
    access: "",
  };

  const visiblityOptions = [
    { label: "On", value: true },
    { label: "Off", value: false },
  ];

  const AddRoleSchema = Yup.object().shape({
    isVisible: Yup.string().nullable(),
    access: Yup.string().nullable(),
  });

  const handleSubmit = (values) => {
    dispatch(
      setRolesFilterProps({
        ...values,
        isVisible: values.isVisible === "" ? null : values.isVisible,
        access: values.access === "" ? null : values.access,
      })
    );
  };

  const formik = useFormik({
    initialValues: { ...intitalVal, ...rolesFilterProps },
    validationSchema: AddRoleSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
      onReset={() => {
        dispatch(setRolesFilterProps(null));
      }}
    >
      <div className="p-4 flex flex-col gap-2">
        <p className="font-semibold">Filter </p>
        <SelectComponent
          selectclassName={"mt-2"}
          className={"px-0"}
          label={"By Visiblity"}
          isRequired={false}
          options={visiblityOptions}
          placeholder="Select Visiblity"
          name={"isVisible"}
          value={formik.values.isVisible}
          error={formik.errors.isVisible && formik.touched.isVisible}
          errorMsg={formik.errors.isVisible}
          onChange={(selectedOption) => {
            formik.setFieldValue(
              "isVisible",
              selectedOption ? selectedOption.value : ""
            );
          }}
        />
        <SelectComponent
          selectclassName={"mt-2"}
          className={"px-0"}
          label={"By Access"}
          isRequired={false}
          options={access?.map((item) => ({
            label: item?.name,
            value: item?.name,
          }))}
          placeholder="Select Access"
          name={"access"}
          value={formik?.values?.access}
          error={formik.errors.access && formik.touched.access}
          errorMsg={formik.errors.access}
          onChange={(selectedOption) => {
            formik.setFieldValue(
              "access",
              selectedOption ? selectedOption.value : ""
            );
          }}
        />
        <div className="flex self-end justify-between w-full">
          <SubmitBtn type="reset" title={"Reset"} buttonClassName={"!ml-0"} />
          <SubmitBtn title={"Apply"} buttonClassName={"!ml-0"} />
          <SubmitBtn
            onClickFunc={() => handleClose()}
            type={"button"}
            title={"Cancel"}
            buttonClassName={"!ml-0"}
          />
        </div>
      </div>
    </form>
  );
};

export default RolesFilter;
