import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeToast } from "store/slices/common/toast.slice";
import { Alert, Snackbar, Slide, LinearProgress } from "@mui/material";
import MuiTypography from "../MuiTypography";
import MuiBox from "../MuiBox";

const ToastWrapper = ({ time = 5000 }) => {
  const dispatch = useDispatch();
  const { isOpen, message, type } = useSelector((state) => state.toast);
  const timeoutRef = useRef();

  useEffect(() => {
    if (isOpen) {
      timeoutRef.current = setTimeout(() => {
        handleClose();
      }, time);
    }

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [isOpen]);

  const handleClose = () => {
    dispatch(closeToast());
  };

  function SlideTransition(props) {
    return <Slide {...props} direction="left" />;
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={isOpen}
      autoHideDuration={time}
      onClose={handleClose}
      TransitionComponent={SlideTransition}
      sx={{
        zIndex: 2000,
        mt: "2rem",
        "& .MuiAlert-action": {
          padding: "0 0 0 16px !important",
        },
        "& .MuiPaper-root": {
          padding: "8px 16px 6px 16px !important",
        },
        overflow: "hidden",
        borderRadius: "12px",
      }}
    >
      <Alert icon={false} severity={type} onClose={handleClose}>
        <MuiBox
          sx={{
            bgColor: "#ddd",
          }}
        >
          <LinearProgress variant={type} value={100} />
        </MuiBox>
        <MuiTypography variant="body2" fontWeight={"600"}>
          {message}
        </MuiTypography>
      </Alert>
    </Snackbar>
  );
};

export default ToastWrapper;
