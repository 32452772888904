import { Button, FormControl, Slider } from "@mui/material";
import { WebInput, MuiBox, MuiTypography } from "components/atoms";
import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";

import Location from "assets/images/SVG/LocationIcon.svg";
import Navigator from "assets/images/SVG/Navigator.svg";
import Star from "assets/images/SVG/Star.svg";
import Dollar from "assets/images/SVG/dollar.svg";
import { useDispatch } from "react-redux";
import { closeDrawer } from "store/slices/common/drawer.slice";

const ProfessionalFilterModal = ({ onClick, params }) => {
  const dispatch = useDispatch();

  const [value, setValue] = useState(params?.filterByMiles ? params?.filterByMiles : 0);

  const handleSliderChange = (event, newValue) => {
    if (newValue !== null) {
      setValue(newValue);
    }
  };

  // const handleSliderChangeCommitted = (newValue) => {
  //     if (newValue !== null) {
  //         setValue(newValue);
  //     }
  // };

  const [selectedReview, setSelectedReview] = useState(null);
  const reviews = ["1", "2", "3", "4", "5"];

  const [selectedDay, setSelectedDay] = useState(null);
  const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  return (
    <MuiBox sx={{ p: { xs: "16px 10px", sm: "16px 32px" } }}>
      <MuiTypography variant="h6" fontWeight={"bold"} align={"center"}>
        Filter
      </MuiTypography>
      <MuiBox
        component={"form"}
        sx={{ display: "flex", flexDirection: "column", gap: 4 }}
      >
        <FormControl>
          <WebInput
            variant="plain"
            placeholder={"Enter here"}
            label={"Location"}
            icon={<img src={Location} alt="Location" />}
            endIcon={<img src={Navigator} alt="Navigator" />}
          />
        </FormControl>
        <FormControl>
          <MuiBox
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <MuiTypography variant="baseFont" fontWeight={"bold"}>
              Distance
            </MuiTypography>
            <MuiBox
              sx={{
                width: "fit-content",
                borderRadius: "6px",
                border: "1px solid #000000",
                p: "0 6px",
              }}
            >
              <MuiTypography sx={{ fontSize: "14px" }}>
                {value}+ Miles
              </MuiTypography>
            </MuiBox>
          </MuiBox>
          <MuiBox
            sx={{
              bgcolor: "#fff",
              p: "12px 25px 6px 25px",
              borderRadius: "12px",
              mt: "8px",
            }}
          >
            <Slider
              className="distance-slider"
              min={5}
              step={5}
              onChange={handleSliderChange}
              // onChangeCommitted={handleSliderChangeCommitted}
              value={value}
              aria-labelledby="distance-slider"
            />
          </MuiBox>
        </FormControl>
        <FormControl>
          <MuiTypography variant="baseFont" fontWeight={"bold"}>
            Reviews
          </MuiTypography>
          <MuiBox
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: { xs: "12px 30px", sm: "19px" },
              mt: "8px",
              flexWrap: "wrap",
            }}
          >
            {reviews.map((item, index) => (
              <MuiBox
                key={index}
                sx={{
                  width: "fit-content",
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  bgcolor: "#fff",
                  p: "6px 15px",
                  borderRadius: "12px",
                  border: "1px solid transparent",
                  borderColor:
                    selectedReview === item ? "#0a1e41" : "transparent",
                  cursor: "pointer",
                }}
                onClick={() => setSelectedReview(item)}
              >
                <img
                  src={Star}
                  style={{
                    height: "25px",
                    width: "25px",
                    objectFit: "contain",
                    objectPosition: "center",
                  }}
                />
                <MuiTypography variant="subtitle1">{item}</MuiTypography>
              </MuiBox>
            ))}
          </MuiBox>
        </FormControl>
        <FormControl>
          <MuiTypography variant="baseFont" fontWeight={"bold"}>
            Availability
          </MuiTypography>
          <MuiBox
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: { xs: "12px 21px", sm: "11px" },
              mt: "8px",
              flexWrap: "wrap",
            }}
          >
            {days.map((item, index) => (
              <MuiBox
                key={index}
                sx={{
                  width: "fit-content",
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  bgcolor: "#fff",
                  p: "6px 15px",
                  borderRadius: "12px",
                  border: "1px solid transparent",
                  borderColor: selectedDay === item ? "#0a1e41" : "transparent",
                  cursor: "pointer",
                }}
                onClick={() => setSelectedDay(item)}
              >
                <MuiTypography variant="subtitle1">{item}</MuiTypography>
              </MuiBox>
            ))}
          </MuiBox>
        </FormControl>
        <Grid container spacing={2}>
          <Grid xs={12} sm={6}>
            <WebInput
              variant="plain"
              placeholder={"Min"}
              label={"Price (min)"}
              icon={<img src={Dollar} alt="Location" />}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <WebInput
              variant="plain"
              placeholder={"Max"}
              label={"Price (max)"}
              icon={<img src={Dollar} alt="Location" />}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid xs={12} sm={6}>
            <Button
              variant="darkButton"
              onClick={() => dispatch(closeDrawer())}
            >
              Cancel
            </Button>
          </Grid>
          <Grid xs={12} sm={6}>
            <Button
              variant="lightButton"
              onClick={() => onClick({ filterByMiles: value })}
            >
              Apply
            </Button>
          </Grid>
        </Grid>
      </MuiBox>
    </MuiBox>
  );
};

export default ProfessionalFilterModal;
