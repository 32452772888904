import * as Yup from "yup";
import {
  emailRegex,
  onlySpaceRegExp,
  phoneRegExp,
  spaceRegExp,
  startsWithSpaceRegExp,
} from "utils/regex";

export const email = Yup.string()
  .email("Please enter valid email address")
  .required("Please enter email address")
  .matches(emailRegex, "Please enter valid email address");

export const confirmEmail = Yup.string()
  .email("Please enter valid confirm email address")
  .oneOf([Yup.ref("email"), null], "Email & Confirm email must be same")
  .required("Please enter confirm email address");

export const Password = Yup.string()
  .min(6, "Password should be at least 6 characters long")
  .matches(spaceRegExp, "Please enter valid password")
  .max(50, "Password should not contain more than 50 characters")
  .required("Please enter password");

export const confirmPassword = Yup.string()
  .min(6, "Password should be at least 6 characters long")
  .matches(spaceRegExp, "Please enter valid password")
  .max(50, "Password should not contain more than 50 characters")
  .oneOf(
    [Yup.ref("password"), null],
    "Password and confirm password must be same"
  )
  .required("Please enter confirm password");

export const File = Yup.mixed()
  .required("Please upload picture")
  .test(
    "fileSize",
    "Please select a valid image file with size up to 3MB",
    (value) => {
      return value && value.size <= 3 * 1024 * 1024; // 3MB limit
    }
  );

export const Name = Yup.string()
  .required("Please enter hospital name")
  .matches(startsWithSpaceRegExp, "Name cannot start with a space")
  .matches(onlySpaceRegExp, "Name should contain at least one character")
  .max(15, "Name should not have more than 15 characters");

export const ProfessionalName = Yup.string()
  .required("Please enter first name")
  .matches(startsWithSpaceRegExp, "Name cannot start with a space")
  .matches(onlySpaceRegExp, "Name should contain at least one character")
  .max(15, "Name should not have more than 15 characters");
export const fName = Yup.string()
  .required("Please enter first name")
  .matches(startsWithSpaceRegExp, "First name cannot start with a space")
  .matches(onlySpaceRegExp, "First name should contain at least one character")
  .max(15, "First Name should not have more than 15 characters");
export const lName = Yup.string()
  .required("Please enter last name")
  .matches(startsWithSpaceRegExp, "Last name cannot start with a space")
  .matches(onlySpaceRegExp, "Last Name should contain at least one character")
  .max(15, "Last Name should not have more than 15 characters");
export const Nickname = Yup.string()
  .nullable()
  .max(15, "Nickname should not have more than 15 characters");
export const AboutMe = Yup.string()
  .required("Please enter about your self")
  .matches(startsWithSpaceRegExp, "About me cannot start with a space")
  .matches(onlySpaceRegExp, "About me should contain at least one character");
export const DUNS = Yup.string()
  .required("Please enter DUNS Number")
  .length(9, "DUNS Number should be 9 digits")
  .matches(spaceRegExp, "Please enter valid DUNS Number");
export const Facility = Yup.array()
  .of(Yup.string().required("Please select facility type"))
  .min(1, "Please select facility type")
  .required("Please select facility type");
export const jobTitle = Yup.string().required("Please select job title");
export const zipCode = Yup.string().required("Please enter Zip Code").matches(onlySpaceRegExp, "Zip Code should contain at least one character");
export const country = Yup.string().required("Please enter country").matches(onlySpaceRegExp, "Country should contain at least one character");
export const state = Yup.string().required("Please enter state").matches(onlySpaceRegExp, "State should contain at least one character");
export const city = Yup.string().required("Please enter city").matches(onlySpaceRegExp, "City should contain at least one character");
export const Landmark = Yup.string()
  .required("Please enter landmark")
  .matches(startsWithSpaceRegExp, "Landmark cannot start with a space");
export const unitId = Yup.string()
  .required("Please enter unit id")
  .matches(onlySpaceRegExp, "Unit id should contain at least one character");

export const firstAddress = Yup.string()
  .required("Please enter Address Line 1")
  .matches(
    onlySpaceRegExp,
    "Address Line 1 should contain at least one character"
  );

export const secondAddress = Yup.string().nullable();
export const Title = Yup.string().required("Please select title");
export const Phone = Yup.string()
  .required("Please enter phone number")
  .matches(phoneRegExp, "Phone number must contain exactly 10 digits");
export const Speciality = Yup.array()
  .of(Yup.string().required("Speciality is required"))
  .min(1, "Please select speciality")
  .required("Speciality is required");
export const CountryCode = Yup.string().required("Country code is required");
