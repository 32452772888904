import * as Yup from "yup";
import { onlySpaceRegExp, startsWithSpaceRegExp } from "utils/regex";

export const name = Yup.string()
  .required("Please enter Name")
  .matches(startsWithSpaceRegExp, "Name cannot start with a space")
  .matches(onlySpaceRegExp, "Name should contain at least one character");
export const certificateNumber = Yup.number().required("Please enter Number");

export const expiryDate = Yup.string().required("Please enter Expiry Date");

export const File = Yup.mixed()
  .required("Please upload picture")
  .test(
    "fileSize",
    "Please select a valid image file with size up to 3MB",
    (value) => {
      return value && value.size <= 3 * 1024 * 1024; // 3MB limit
    }
  );
