import { createSlice } from "@reduxjs/toolkit";
import {
  AddPlansAction,
  DeletePlansAction,
  GetAllPlansAction,
  UpdatePlansAction,
  UpdateVisiblityPlansAction,
} from "store/middlewares/plans";
import {
  AddFeaturesAction,
  DeleteFeaturesAction,
  GetAllFeaturesAction,
  UpdateFeaturesAction,
  UpdateVisiblityFeaturesAction,
} from "store/middlewares/features";
import { GetAllSubscriptionsAction } from "store/middlewares/subscriptions";

export const subscriptionManagementSlice = createSlice({
  name: "subscriptionManagement",
  initialState: {
    plansLoading: false,
    plans: [],
    plansFilterProps: {},
    subscriptionsLoading: false,
    subscriptions: [],
    featuresLoading: false,
    features: [],
  },
  reducers: {
    setPlanFilterProps: (state, action) => {
      state.plansFilterProps = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(GetAllSubscriptionsAction.pending, (state) => {
        state.subscriptionsLoading = true;
        state.subscriptions = [];
      })
      .addCase(GetAllSubscriptionsAction.fulfilled, (state, action) => {
        state.subscriptionsLoading = false;
        state.subscriptions = action.payload.response.data
          ? action.payload.response.data
          : action.payload.response;
      })
      .addCase(GetAllSubscriptionsAction.rejected, (state) => {
        state.subscriptionsLoading = false;
        state.subscriptions = [];
      })
      .addCase(GetAllPlansAction.pending, (state) => {
        state.plansLoading = true;
        state.plans = [];
      })
      .addCase(GetAllPlansAction.fulfilled, (state, action) => {
        state.plansLoading = false;
        state.plans = action.payload.response.data
          ? action.payload.response.data
          : action.payload.response;
      })
      .addCase(GetAllPlansAction.rejected, (state) => {
        state.plansLoading = false;
        state.plans = [];
      })
      // Delete plan
      .addCase(DeletePlansAction.fulfilled, (state, action) => {
        let { id } = action.payload;
        let tempArray = state.plans?.filter((title) => title.id !== id);
        state.plansLoading = false;
        state.plans = tempArray;
      })
      //Update plan
      .addCase(UpdatePlansAction.fulfilled, (state, action) => {
        let obj = action.payload.response?.data
          ? action.payload.response?.data
          : action.payload.response;
        let { id, ...rest } = obj;
        let tempArray = state.plans?.map((title) => {
          if (title.id === id) {
            return { ...title, ...rest };
          }
          return { ...title };
        });
        state.plansLoading = false;
        state.plans = tempArray;
      })
      //Add plan
      .addCase(AddPlansAction.fulfilled, (state, action) => {
        state.plansLoading = false;
        let Obj = action.payload.response.newcode
          ? action.payload.response.newcode
          : action.payload.response;
        state.plans = [...state.plans, Obj];
      })
      .addCase(GetAllFeaturesAction.pending, (state) => {
        state.featuresLoading = true;
        state.features = [];
      })
      .addCase(GetAllFeaturesAction.fulfilled, (state, action) => {
        state.featuresLoading = false;
        state.features = action.payload.response.data
          ? action.payload.response.data
          : action.payload.response;
      })
      .addCase(GetAllFeaturesAction.rejected, (state) => {
        state.featuresLoading = false;
        state.features = [];
      })
      // Delete plan
      .addCase(DeleteFeaturesAction.fulfilled, (state, action) => {
        let { id } = action.payload;
        let tempArray = state.features?.filter((title) => title.id !== id);
        state.featuresLoading = false;
        state.features = tempArray;
      })
      //Update plan
      .addCase(UpdateFeaturesAction.fulfilled, (state, action) => {
        let obj = action.payload.response.data
          ? action.payload.response.data
          : action.payload.response;
        let { id, ...rest } = obj;
        let tempArray = state.features?.map((title) => {
          if (title.id === id) {
            return { ...title, ...rest };
          }
          return { ...title };
        });
        state.featuresLoading = false;
        state.features = tempArray;
      })
      //Add city
      .addCase(AddFeaturesAction.fulfilled, (state, action) => {
        state.featuresLoading = false;
        let Obj = action.payload.response.newcode
          ? action.payload.response.newcode
          : action.payload.response;
        state.features = [Obj, ...state.features];
      })

      //Update Visiblity Plans
      .addCase(UpdateVisiblityFeaturesAction.fulfilled, (state, action) => {
        state.featuresLoading = false;
        let { id } = action.payload;
        let tempArray = state.features?.map((title) => {
          if (title.id === id) {
            return { ...title, isActive: !title.isActive };
          }
          return { ...title };
        });
        state.features = tempArray;
      })

      //Update Visiblity Plans
      .addCase(UpdateVisiblityPlansAction.fulfilled, (state, action) => {
        state.plansLoading = false;
        let { id } = action.payload;

        let tempArray = state.plans?.map((title) => {
          if (title.id === id) {
            return { ...title, isActive: !title.isActive };
          }
          return { ...title };
        });
        state.plans = tempArray;
      });
  },
});

export const { setPlanFilterProps } = subscriptionManagementSlice.actions;

export default subscriptionManagementSlice.reducer;
