import React, { useEffect } from "react";
import { Checkbox } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { GetNotificationForProfessional } from "store/middlewares/users/professionals";
import { CustomLoader, NoDataFound } from "components/atoms";

const notificationTab = ({ id }) => {
  const dispatch = useDispatch();
  const { notification, notificationLoading } = useSelector(
    (state) => state.user
  );

  useEffect(() => {
    dispatch(GetNotificationForProfessional({ id }));
  }, [dispatch, id]);

  const TableItem = ({ icon, content }) => {
    return (
      <tr>
        <td
          className={`px-3.5 py-2.5 pl-0 last:pr-0 border-y border-transparent text-vertical-bg font-semibold`}
        >
          {content}
        </td>
        <td className="px-3.5 py-2.5 pl-0 text-center last:pr-0 border-y border-transparent text-vertical-bg">
          {icon}
        </td>
      </tr>
    );
  };

  return (
    <>
      {!notificationLoading ? (
        notification && Object.keys(notification).length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-5">
            <div className="border border-dashed card p-5 border-custom-200">
              <table className="w-full mb-0">
                <tbody>
                  <tr>
                    <td
                      colSpan={2}
                      className="px-3.5 pb-2.5 pl-0 last:pr-0 border-b font-semibold border-vertical-bg text-vertical-bg"
                    >
                      <p className="underline">Notification</p>
                    </td>
                  </tr>
                  <TableItem
                    icon={
                      <Checkbox
                        checked={notification?.inAppNotification}
                        sx={{ p: "0" }}
                        inputProps={{ "aria-readonly": true }}
                      />
                    }
                    content={`In-app notifications`}
                  />
                  <TableItem
                    icon={
                      <Checkbox
                        checked={notification?.inAppMessaging}
                        sx={{ p: "0" }}
                        inputProps={{ "aria-readonly": true }}
                      />
                    }
                    content={`In-app messaging`}
                  />
                  <TableItem
                    icon={
                      <Checkbox
                        checked={notification?.text}
                        sx={{ p: "0" }}
                        inputProps={{ "aria-readonly": true }}
                      />
                    }
                    content={`Text`}
                  />
                  <TableItem
                    icon={
                      <Checkbox
                        checked={notification?.email}
                        sx={{ p: "0" }}
                        inputProps={{ "aria-readonly": true }}
                      />
                    }
                    content={`Email`}
                  />
                  <TableItem
                    icon={
                      <Checkbox
                        checked={notification?.pushNotification}
                        sx={{ p: "0" }}
                        inputProps={{ "aria-readonly": true }}
                      />
                    }
                    content={`Push Notification`}
                  />
                  <TableItem
                    icon={
                      <Checkbox
                        checked={notification?.webBrowserNotification}
                        sx={{ p: "0" }}
                        inputProps={{ "aria-readonly": true }}
                      />
                    }
                    content={`Web Browser notifications`}
                  />
                  <TableItem
                    icon={
                      <Checkbox
                        checked={notification?.combinationOfAnyAbove}
                        sx={{ p: "0" }}
                        inputProps={{ "aria-readonly": true }}
                      />
                    }
                    content={`Combination of any above`}
                  />
                </tbody>
              </table>
            </div>
            <div className="border border-dashed card p-5 border-custom-200">
              <table className="w-full mb-0">
                <tbody>
                  <tr>
                    <td
                      colSpan={2}
                      className="px-3.5 pb-2.5 pl-0 last:pr-0 border-b font-semibold border-vertical-bg text-vertical-bg"
                    >
                       <p className="underline">Alert</p>
                    </td>
                  </tr>
                  <TableItem
                    icon={
                      <Checkbox
                        checked={notification?.systemAlert}
                        sx={{ p: "0" }}
                        inputProps={{ "aria-readonly": true }}
                      />
                    }
                    content={`System Alert`}
                  />
                  <TableItem
                    icon={
                      <Checkbox
                        checked={notification?.newMessageAlert}
                        sx={{ p: "0" }}
                        inputProps={{ "aria-readonly": true }}
                      />
                    }
                    content={`New Message`}
                  />
                  <TableItem
                    icon={
                      <Checkbox
                        checked={notification?.newAvailableShiftAlert}
                        sx={{ p: "0" }}
                        inputProps={{ "aria-readonly": true }}
                      />
                    }
                    content={`New Available Shift`}
                  />
                  <TableItem
                    icon={
                      <Checkbox
                        checked={notification?.shiftStatus}
                        sx={{ p: "0" }}
                        inputProps={{ "aria-readonly": true }}
                      />
                    }
                    content={`Shift Status`}
                  />
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <div className="mt-5">
            <NoDataFound />
          </div>
        )
      ) : (
        <CustomLoader />
      )}
    </>
  );
};

export default notificationTab;
