import React from "react";
import { MuiBox, MuiTypography } from "components/atoms";
import { Checkbox, FormControlLabel, IconButton } from "@mui/material";
import { useDispatch } from "react-redux";
import { openDrawer } from "store/slices/common/drawer.slice";

import ConfirmModal from "../ConfirmModal";

import LocationPng from "assets/images/SVG/Location svg.svg";
import CheckboxBorder from "assets/images/SVG/checkbox border.svg";
import CheckboxIcon from "assets/images/SVG/check-square.svg";
import EditIcon from "assets/images/SVG/edit icon white.svg";
import DeleteIcon from "assets/images/SVG/delete icon black.svg";
import {
  DeleteAddressForWebAction,
  SetAddressDefault,
} from "store/middlewares/address";
import {
  DeleteCredentialPhysicalAddressWebAction,
  SetDetfaultCredentialPhysicalAddressWebAction,
} from "store/middlewares/credential/web";
import { resetActionSuccess } from "store/slices/admin/credentials";
import CommentBox from "../CommentBox";
import { credentialStatusColorObj, credentialStatusTextObj } from "utils/credentialStatus";
const AddressCard = ({
  address,
  setSelectedAddress,
  setActiveProfileTab = () => {},
  showStatus,
  forCredential = false,
}) => {

  const dispatch = useDispatch();
  return (
    <MuiBox
      sx={{
        p: "15px",
        borderRadius: "12px",
        border: "1px solid #D5D4D7",
        position: "relative",
        display: "flex",
        height: "100%",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      {showStatus ? (
        <MuiBox
          sx={{
            width: "fit-content",
            p: "5px 10px",
            borderRadius: "12px",
            bgcolor: credentialStatusColorObj[address?.status],
            position: "absolute",
            top: "-16px",
            right: "15px",
            zIndex: 1,
          }}
        >
          <MuiTypography fontSize="14px" fontWeight="bold" color="#fff">
            {credentialStatusTextObj[address?.status]}
          </MuiTypography>
        </MuiBox>
      ) : null}
      <MuiBox sx={{ display: "flex", alignItems: "flex-start", gap: "15px" }}>
        <MuiBox
          sx={{
            height: "50px",
            width: "50px",
            borderRadius: "50%",
            overflow: "hidden",
            flexShrink: 0,
          }}
        >
          <img src={LocationPng} />
        </MuiBox>
        <MuiBox
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            overflow: "hidden",
          }}
        >
          <MuiTypography
            variant="baseFont"
            fontWeight="bold"
            sx={{ width: "100%", overflow: "hidden" }}
          >
            <CommentBox
              comment={[
                address?.firstAddress,
                address?.secondAddress,
                address?.landmark,
                address?.unitId,
                address?.city,
                address?.state,
                address?.country,
                address?.zipCode,
              ]
                .filter(Boolean)
                .join(", ")}
              // isExpanded={expandedIndex === index}
              // onToggle={() => handleToggle(index)}
              variant="baseFont"
              sx={{
                width: "100%",
                fontWeight: "bold",
                overflow: "hidden",
                wordBreak: "break-all",
              }}
              maxChar={70}
            />
          </MuiTypography>
          <MuiBox
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              flexWrap: "wrap",
            }}
          >
            {(address?.jobTitle || address?.jobTitleid) && (
              <MuiBox
                sx={{
                  width: "fit-content",
                  bgcolor: "#CCEEFA",
                  borderRadius: "15px",
                  p: "4px 10px",
                }}
              >
                <MuiTypography fontSize="14px" fontWeight="bold">
                  {address?.jobTitle}
                </MuiTypography>
              </MuiBox>
            )}
            {address?.floorData?.map((floor) => {
              return (
                <>
                  <MuiBox
                    sx={{
                      width: "fit-content",
                      bgcolor: "#CCEEFA",
                      borderRadius: "15px",
                      p: "4px 10px",
                    }}
                    key={floor?.id}
                  >
                    <MuiTypography fontSize="14px" fontWeight="bold">
                      Floor {floor?.name}
                    </MuiTypography>
                  </MuiBox>
                </>
              );
            })}
          </MuiBox>
        </MuiBox>
      </MuiBox>
      <MuiBox
        sx={{
          mt: "15px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "15px",
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              icon={<img src={CheckboxBorder} className="checkbox-icon" />}
              checkedIcon={<img src={CheckboxIcon} className="checkbox-icon" />}
              checked={address?.isDefault}
              onChange={() => {
                if (forCredential) {
                  dispatch(
                    SetDetfaultCredentialPhysicalAddressWebAction({
                      id: address?.id,
                    })
                  );
                } else {
                  dispatch(SetAddressDefault({ id: address?.id }));
                }
              }}
            />
          }
          label={address?.isDefault ? "Current Address" : "Use This Address"}
        />

        <MuiBox
          sx={{
            width: "fit-content",
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <IconButton
            sx={{ borderRadius: "8px", bgcolor: "#E8F0F3" }}
            onClick={() => {
              dispatch(resetActionSuccess());
              setSelectedAddress(address);
              setActiveProfileTab("Add Physical Address");
            }}
          >
            <img src={EditIcon} />
          </IconButton>
          {!address?.isDefault && (
            <IconButton
              sx={{ borderRadius: "8px", bgcolor: "#E8F0F3" }}
              onClick={() =>
                dispatch(
                  openDrawer({
                    content: (
                      <ConfirmModal
                        type="delete"
                        title="Delete Address"
                        message={
                          <>
                            Are you sure you want to delete your address? <br />
                            This action is permanent and cannot be undone.
                          </>
                        }
                        onClick={() => {
                          if (forCredential) {
                            dispatch(
                              DeleteCredentialPhysicalAddressWebAction({
                                id: address?.id,
                              })
                            );
                          } else {
                            dispatch(
                              DeleteAddressForWebAction({ id: address?.id })
                            );
                          }
                        }}
                        btnText={"Delete"}
                      />
                    ),
                  })
                )
              }
            >
              <img src={DeleteIcon} />
            </IconButton>
          )}
        </MuiBox>
      </MuiBox>
    </MuiBox>
  );
};

export default AddressCard;
