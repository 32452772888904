import axiosClient from "utils/api";

export const getNOtificationSettings = async () => {
  try {
    const response = await axiosClient().request({
      url: "/notification/notification",
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error at Get Notifications", error);
    throw error;
  }
};

// For get all notifications
export const getAllNotification = async (payload) => {
  try {
    const response = await axiosClient().request({
      url: `/notification/getAllNotifications`,
      method: "get",
      data: payload
    });
    return response.data;
  } catch (error) {
    console.error("Error at get all Notifications", error);
    throw error;
  }
};

export const updateNotificationSettings = async (payload) => {
  try {
    const response = await axiosClient().request({
      url: `/notification/notification`,
      method: "put",
      data: payload
    });
    return response.data;
  } catch (error) {
    console.error("Error at Update Notifications", error);
    throw error;
  }
};
