import React, { useContext } from "react";
import * as Yup from "yup";
// import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { SelectComponent, SubmitBtn } from "components/atoms";
import { FilterPopoverContext } from "context/PopoverContext";
import { useDispatch, useSelector } from "react-redux";
import { setShiftFilterProps } from "store/slices/admin/users/user";

const onGoingShiftFilter = () => {
  const dispatch = useDispatch();
  const { handleClose } = useContext(FilterPopoverContext);
  const { shift, shiftFilterProps } = useSelector((state) => state.user);

  const intitalVal = {
    professionalId: "",
    hospitalId: "",
  };

  const hospitalOptions = shift?.map((item) => ({
    label: item.hospitalTimeSchedule.name,
    value: item.hospitalTimeSchedule.id,
  }));

  const professionalOptions = shift?.map((item) => ({
    label: item.professionalTimeSchedule.name,
    value: item.professionalTimeSchedule.id,
  }));

  const JobTitleFilterSchema = Yup.object().shape({
    professionalId: Yup.string().optional(),
    hospitalId: Yup.string().optional(),
  });

  const handleSubmit = (values) => {
    let tempObj = {};
    Object.keys(values)?.forEach((item) => {
      if(values[`${item}`]) {
        tempObj[`${item}`] = values[`${item}`]
      }
    })
    dispatch(setShiftFilterProps(tempObj));
  };

  const formik = useFormik({
    initialValues: { ...intitalVal, ...shiftFilterProps },
    validationSchema: JobTitleFilterSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
      onReset={() => {
        dispatch(setShiftFilterProps(null));
      }}
    >
      <div className="p-4 flex flex-col gap-2">
        <p className="font-semibold">Filter </p>
        <SelectComponent
          selectclassName={"mt-2"}
          className={"px-0"}
          label={"Hospital"}
          isRequired={false}
          options={hospitalOptions}
          placeholder="Select Hospital"
          name={"hospitalId"}
          value={formik.values.hospitalId}
          error={formik.errors.hospitalId && formik.touched.hospitalId}
          errorMsg={formik.errors.hospitalId}
          onChange={(selectedOption) => {
            formik.setFieldValue(
              "hospitalId",
              selectedOption ? selectedOption.value : ""
            );
          }}
        />
        <SelectComponent
          selectclassName={"mt-2"}
          className={"px-0"}
          label={"Professional"}
          isRequired={false}
          options={professionalOptions}
          placeholder="Select Professional"
          name={"professionalId"}
          value={formik.values.professionalId}
          error={formik.errors.professionalId && formik.touched.professionalId}
          errorMsg={formik.errors.professionalId}
          onChange={(selectedOption) => {
            formik.setFieldValue(
              "professionalId",
              selectedOption ? selectedOption.value : ""
            );
          }}
        />
        <div className="flex self-end justify-between w-full">
          <SubmitBtn type="reset" title={"Reset"} buttonClassName={"!ml-0"} />
          <SubmitBtn title={"Apply"} buttonClassName={"!ml-0"} />
          <SubmitBtn
            onClickFunc={() => handleClose()}
            type={"button"}
            title={"Cancel"}
            buttonClassName={"!ml-0"}
          />
        </div>
      </div>
    </form>
  );
};

export default onGoingShiftFilter;
