import { MuiBox, MuiTypography } from "components/atoms";
import React from "react";
import { Checkbox, FormControlLabel, IconButton } from "@mui/material";
import ConfirmModal from "../ConfirmModal";
import { openDrawer } from "store/slices/common/drawer.slice";
import { useDispatch } from "react-redux";
import CheckboxBorder from "assets/images/SVG/checkbox border.svg";
import CheckboxIcon from "assets/images/SVG/check-square.svg";
import ResumeIcon from "assets/images/SVG/resume icon.svg";
import EditIcon from "assets/images/SVG/edit icon white.svg";
import DeleteIcon from "assets/images/SVG/delete icon black.svg";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import {
  DeleteCredentialResumeForWeb,
  SetDetfaultCredentialResumeWebAction,
} from "store/middlewares/credential/web";
import { credentialStatusColorObj, credentialStatusTextObj } from "utils/credentialStatus";
dayjs.extend(relativeTime);
const ResumeCard = ({ resume, index, onClickEdit, onClick, isSelected }) => {


  const dispatch = useDispatch();
  return (
    <MuiBox
      sx={{
        p: "10px",
        py: "20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "10px",
        border: `1px solid ${isSelected ? "#0A1E41" : "#D5D4D7"}`,
        borderRadius: "12px",
        cursor: "pointer",
        mt: 1,
        position: "relative",
      }}
      onClick={onClick}
    >
      {["rejected", "accepted", "pending"].includes(resume?.status) ? (
        <MuiBox
          sx={{
            width: "fit-content",
            p: "5px 10px",
            borderRadius: "12px",
            bgcolor: credentialStatusColorObj[resume?.status],
            position: "absolute",
            top: "-16px",
            right: "15px",
            zIndex: 1,
          }}
        >
          <MuiTypography fontSize="14px" fontWeight="bold" color="#fff">
            {credentialStatusTextObj[resume?.status]}
          </MuiTypography>
        </MuiBox>
      ) : null}
      <MuiBox
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          mt: 1,
        }}
        onClick={onClick}
      >
        <img src={ResumeIcon} />
        <MuiBox sx={{ display: "flex", flexDirection: "column" }}>
          <MuiTypography variant="baseFont" fontWeight="bold">
            Resume {index + 1}
          </MuiTypography>
          <MuiTypography fontSize="14px" color="#ABA8AE">
            Last Update {dayjs().to(dayjs(resume?.updateDateTime))}
          </MuiTypography>
        </MuiBox>
      </MuiBox>
      <MuiBox
        sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}
      >
        <FormControlLabel
          control={
            <Checkbox
              icon={<img src={CheckboxBorder} className="checkbox-icon" />}
              checkedIcon={<img src={CheckboxIcon} className="checkbox-icon" />}
              checked={resume?.isDefault}
              sx={{ py: 0 }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                dispatch(
                  SetDetfaultCredentialResumeWebAction({
                    id: resume?.id,
                  })
                );
              }}
            />
          }
          label={"Use as Default"}
        />
        <MuiBox
          sx={{
            width: "fit-content",
            alignSelf: "flex-end",
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <IconButton
            sx={{
              width: "30px",
              height: "30px",
              borderRadius: "8px",
              bgcolor: "#E8F0F3",
              p: "5px",
            }}
            onClick={onClickEdit}
          >
            <img src={EditIcon} style={{ width: "16px", height: "16px" }} />
          </IconButton>
          <IconButton
            sx={{
              width: "30px",
              height: "30px",
              borderRadius: "8px",
              bgcolor: "#E8F0F3",
              p: "5px",
            }}
            onClick={() =>
              dispatch(
                openDrawer({
                  content: (
                    <ConfirmModal
                      type="delete"
                      title="Delete Resume"
                      message={
                        <>
                          Are you sure you want to delete your resume? <br />
                          This action is permanent and cannot be undone.
                        </>
                      }
                      btnText={"Delete Resume"}
                      onClick={() =>
                        dispatch(
                          DeleteCredentialResumeForWeb({ id: resume?.id })
                        )
                      }
                    />
                  ),
                })
              )
            }
          >
            <img src={DeleteIcon} style={{ width: "16px", height: "16px" }} />
          </IconButton>
        </MuiBox>
      </MuiBox>
    </MuiBox>
  );
};

export default ResumeCard;
