import { createSlice } from "@reduxjs/toolkit";
import {
  AddJobForWebAction,
  DeleteJobForWebAction,
  GetAllJobsForWebAction,
  GetJobByIdForWebAction,
  GetJobDetails,
  GetJobsAction,
  GetJobSummaryForWebAction,
  GetJobSummeryByIdAction,
  GetProfessionalJobsAction,
  UpdateScheduleOfJobForWebAction,
} from "store/middlewares/jobs";
import {
  ApplyHospitalJobAction,
  GetHospitalJobByIdAction,
  GetHospitalJobDetailsAction,
} from "store/middlewares/users/professionals";
const initialState = {
  jobs: { items: [], totalPages: null },
  jobsLoading: true,
  singleJob: null,
  singleJobLoading: false,
  jobSummery: {},
  jobSummeryLoading: false,
};

export const jobsSlice = createSlice({
  name: "jobs",
  initialState,
  extraReducers(builder) {
    builder
      // For Get all jobs
      .addCase(GetJobsAction.pending, (state) => {
        state.jobs = null;
        state.jobsLoading = true;
      })
      .addCase(GetJobsAction.fulfilled, (state, action) => {
        state.jobs = { items: action.payload.response };
        state.jobsLoading = false;
      })
      .addCase(GetJobsAction.rejected, (state) => {
        state.jobs = null;
        state.jobsLoading = false;
      })

      // For Get Professionl jobs
      .addCase(GetProfessionalJobsAction.pending, (state) => {
        state.jobs = null;
        state.jobsLoading = true;
      })
      .addCase(GetProfessionalJobsAction.fulfilled, (state, action) => {
        state.jobs = { items: action.payload.response };
        state.jobsLoading = false;
      })
      .addCase(GetProfessionalJobsAction.rejected, (state) => {
        state.jobs = null;
        state.jobsLoading = false;
      })

      // For Get jobDetails
      .addCase(GetJobDetails.pending, (state) => {
        state.singleJob = null;
        state.singleJobLoading = true;
      })
      .addCase(GetJobDetails.fulfilled, (state, action) => {
        state.singleJob = action.payload.response;
        state.singleJobLoading = false;
      })
      .addCase(GetJobDetails.rejected, (state) => {
        state.singleJob = null;
        state.singleJobLoading = false;
      })

      // For Get All Hospital's Job List By Id
      .addCase(GetHospitalJobByIdAction.pending, (state) => {
        state.jobsLoading = state?.jobs?.items?.length > 0 ? false : true;
      })
      .addCase(GetHospitalJobByIdAction.fulfilled, (state, action) => {
        state.jobsLoading = false;
        state.jobs = action.payload.response;
      })
      .addCase(GetHospitalJobByIdAction.rejected, (state, action) => {
        state.jobsLoading = false;
        state.jobs = action.payload.response;
      })

      // For apply to job
      .addCase(ApplyHospitalJobAction.fulfilled, (state, action) => {
        let tempArray = state.jobs.items.map((item) => {
          if (item.id === action.payload.id) {
            return { ...item, isApplied: true, type: "applied" };
          }
          return item;
        });
        state.jobs = { ...state.jobs, items: tempArray };
        state.singleJobLoading = false;
        state.singleJob = {
          ...state.singleJob,
          isApplied: true,
          type: "applied",
        };
      })

      // For Get Hospital Job Details
      .addCase(GetHospitalJobDetailsAction.pending, (state) => {
        state.singleJobLoading = true;
        state.singleJob = null;
      })
      .addCase(GetHospitalJobDetailsAction.fulfilled, (state, action) => {
        state.singleJobLoading = false;
        state.singleJob = action.payload.response;
      })
      .addCase(GetHospitalJobDetailsAction.rejected, (state) => {
        state.singleJobLoading = false;
        state.singleJob = null;
      })

      // For Get all jobs For Web
      .addCase(GetAllJobsForWebAction.pending, (state) => {
        state.jobsLoading = state?.jobs?.items?.length > 0 ? false : true;
      })
      .addCase(GetAllJobsForWebAction.fulfilled, (state, action) => {
        state.jobs = action.payload.response;
        state.jobsLoading = false;
      })
      .addCase(GetAllJobsForWebAction.rejected, (state, action) => {
        state.jobs = action?.payload?.response;
        state.jobsLoading = false;
      })

      // For Get all jobs For Web
      .addCase(GetJobByIdForWebAction.pending, (state) => {
        state.singleJob = null;
        state.singleJobLoading = true;
      })
      .addCase(GetJobByIdForWebAction.fulfilled, (state, action) => {
        state.singleJob = action.payload.response;
        state.singleJobLoading = false;
      })
      .addCase(GetJobByIdForWebAction.rejected, (state) => {
        state.singleJob = null;
        state.singleJobLoading = false;
      })

      .addCase(DeleteJobForWebAction.fulfilled, (state, action) => {
        state.jobs = {
          ...state.jobs,
          items: state.jobs.items.filter(
            (item) => item?.id !== action.payload.id
          ),
        };
        state.jobsLoading = false;
      })

      .addCase(AddJobForWebAction.fulfilled, (state, action) => {
        state.jobs = {
          ...state.jobs,
          items: [action.payload.response, ...state.jobs.items],
        };
        state.jobsLoading = false;
      })

      .addCase(UpdateScheduleOfJobForWebAction.fulfilled, (state, action) => {
        let scheduleArray = state.singleJob.JobDateSchedule?.map((item) => {
          if (item?.id === action.payload.scheduleId) {
            return { ...item, JobTimeSchedule: action.payload.JobTimeSchedule };
          }
          return item;
        });

        state.singleJob = {
          ...state.singleJob,
          JobDateSchedule: scheduleArray,
        };
        state.jobsLoading = false;
      })

      .addCase(GetJobSummaryForWebAction.pending, (state) => {
        state.singleJobLoading = true;
      })
      .addCase(GetJobSummaryForWebAction.fulfilled, (state, action) => {
        state.singleJob = { ...state.singleJob, ...action.payload.response };
        state.singleJobLoading = false;
      })
      .addCase(GetJobSummaryForWebAction.rejected, (state) => {
        state.singleJobLoading = false;
      })

      .addCase(GetJobSummeryByIdAction.pending, (state) => {
        state.jobSummeryLoading = true;
        state.jobSummery = {};
      })
      .addCase(GetJobSummeryByIdAction.fulfilled, (state, action) => {
        state.jobSummery = action.payload.response;
        state.jobSummeryLoading = false;
      })
      .addCase(GetJobSummeryByIdAction.rejected, (state) => {
        state.jobSummeryLoading = false;
        state.jobSummery = {};
      });
  },
});

export default jobsSlice.reducer;
