import axiosClient from "utils/api";
const URI = "/admin/jobCancellation";
const WEB = "/jobCancellation";

export const getJobCancellation = async (payload) => {
  const URL = `${URI}/jobCancellation`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
      params: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Get All Job Cancellation:", error);
    throw error;
  }
};

export const getJobCancellationForWeb = async () => {
  const URL = `${WEB}/jobCancellation`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Get All Job Cancellation:", error);
    throw error;
  }
};

export const deleteJobCancellation = async (payload) => {
  const URL = `${URI}/jobCancellation/${payload.id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "delete",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Delete Job Cancellation:", error);
    throw error;
  }
};

export const updateJobCancellation = async (payload) => {
  let { id, ...rest } = payload;
  const URL = `${URI}/jobCancellation/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Update Job Cancellation:", error);
    throw error;
  }
};

export const updateJobCancellationVisiblity = async (payload) => {
  let { id, ...rest } = payload;
  const URL = `${URI}/visibility/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Update Job Cancellation:", error);
    throw error;
  }
};

export const addJobCancellation = async (payload) => {
  const URL = `${URI}/jobCancellation`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Add Job Cancellation:", error);
    throw error;
  }
};
