import React, { useContext } from "react";
import * as Yup from "yup";
// import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { DateInput, SelectComponent, SubmitBtn } from "components/atoms";
import { FilterPopoverContext } from "context/PopoverContext";
import { useDispatch, useSelector } from "react-redux";
import { setEmailFilterProps } from "store/slices/admin/helpAndSupportManagement";


const EmailFilter = () => {
  const dispatch = useDispatch();
  const { handleClose } = useContext(FilterPopoverContext);
  const { emailsFilterProps } = useSelector((state) => state.helpAndSupport);

  const intitalVal = {
    role: "",
    status: "",
    startDate: "",
    endDate: "",
  };

  const typeOptions = [
    { label: "Hospital", value: "hospital" },
    { label: "Professional", value: "professional" },
  ];

  const StatusOptions = [
    { label: "Resolve", value: "resolve" },
    { label: "Pending", value: "pending" },
  ];

  const FaqFilterSchema = Yup.object().shape({
    role: Yup.string().nullable(),
    status: Yup.string().nullable(),
  });

  const handleSubmit = (values) => {
    dispatch(
      setEmailFilterProps({
        endDate: values.endDate === "" ? null : values.endDate,
        startDate:values.startDate === "" ? null : values.startDate,
        status: values.status === "" ? null : values.status,
        role: values.role === "" ? null : values.role,
      })
    );
  };

  const formik = useFormik({
    initialValues: { ...intitalVal ,...emailsFilterProps},
    validationSchema: FaqFilterSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
      onReset={() => {
        dispatch(setEmailFilterProps(null));
      }}
    >
      <div className="p-4 flex flex-col gap-2">
        <p className="font-semibold">Filter </p>

        <SelectComponent
          selectclassName={"mt-2"}
          className={"px-0"}
          label={"Role Type"}
          isRequired={false}
          options={typeOptions}
          placeholder="Select plan type"
          name={"role"}
          value={formik.values.role}
          error={formik.errors.role && formik.touched.role}
          errorMsg={formik.errors.role}
          onChange={(selectedOption) => {
            formik.setFieldValue(
              "role",
              selectedOption ? selectedOption.value : ""
            );
          }}
        />
        <SelectComponent
          selectclassName={"mt-2"}
          className={"px-0"}
          label={"Votes Type"}
          isRequired={false}
          options={StatusOptions}
          placeholder="Select Visiblity"
          name={"status"}
          value={formik.values.status}
          error={formik.errors.status && formik.touched.status}
          errorMsg={formik.errors.status}
          onChange={(selectedOption) => {
            formik.setFieldValue(
              "status",
              selectedOption ? selectedOption.value : ""
            );
          }}
        />

        <DateInput
          inputClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 mt-2"
          label={"Start Date"}
          type="date"
          isRequired={false}
          name="startDate"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          max={formik.values.endDate} 
          error={formik.errors.startDate && formik.touched.startDate}
          errorMsg={formik.errors.startDate}
          value={formik.values.startDate}
          placeholder="Enter Start Date"
        />
        <DateInput
          inputClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 mt-2"
          label={"End Date"}
          type="date"
          isRequired={false}
          name="endDate"
          min={formik.values.startDate}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.endDate && formik.touched.endDate}
          errorMsg={formik.errors.endDate}
          value={formik.values.endDate}
          placeholder="Enter Start Date"
        />

        <div className="flex self-end justify-between w-full">
          <SubmitBtn type="reset" title={"Reset"} buttonClassName={"!ml-0"} />
          <SubmitBtn title={"Apply"} buttonClassName={"!ml-0"} />
          <SubmitBtn
            onClickFunc={() => handleClose()}
            type={"button"}
            title={"Cancel"}
            buttonClassName={"!ml-0"}
          />
        </div>
      </div>
    </form>
  );
};

export default EmailFilter;
