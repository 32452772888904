import React, { useRef, useState, useEffect } from 'react';
import { MuiBox } from 'components/atoms';
import { IconButton } from '@mui/material';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
// import required modules
import { Thumbs } from 'swiper/modules';

import LeftSlideBlue from 'assets/images/SVG/leftSlideBlue.svg';
import LeftSlideDark from 'assets/images/SVG/leftSlideDark.svg';
import RightSlideBlue from 'assets/images/SVG/rightSlideBlue.svg';
import RightSlideDark from 'assets/images/SVG/rightSlideDark.svg';

const HospitalDetailSwiper = ({ swiperSlides }) => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [isBeginning, setIsBeginning] = useState(true);
    const [isEnd, setIsEnd] = useState(false);
    const swiperRef = useRef(null);

    useEffect(() => {
        if (swiperRef.current?.swiper) {
            const swiper = swiperRef.current.swiper;

            // Update buttons on initialization
            const updateNavigationState = () => {
                setIsBeginning(swiper.isBeginning);
                setIsEnd(swiper.isEnd);
            };

            updateNavigationState(); // Call initially
            swiper.on('slideChange', updateNavigationState); // Listen to changes
            swiper.on('update', updateNavigationState); // Listen to updates (e.g., resizing)

            return () => {
                swiper.off('slideChange', updateNavigationState);
                swiper.off('update', updateNavigationState);
            };
        }
    }, [swiperRef.current?.swiper]);

    const handleNext = () => {
        swiperRef.current?.swiper.slideNext();
    };

    const handlePrev = () => {
        swiperRef.current?.swiper.slidePrev();
    };

    return (
        <MuiBox sx={{ px: 1 }}>
            {/* Main Swiper */}
            <MuiBox sx={{ position: 'relative' }}>
                <Swiper
                    spaceBetween={10}
                    thumbs={thumbsSwiper ? { swiper: thumbsSwiper } : undefined}
                    modules={[Thumbs]}
                    className="hospital-detail-swiper"
                    ref={swiperRef}
                >
                    {swiperSlides?.map((item, index) => (
                        <SwiperSlide key={index}>
                            <MuiBox className="hospital-swiper-slide">
                                <img src={item.imageUrl} className="img-cover" alt={`Slide ${index}`} />
                            </MuiBox>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <IconButton
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        left: '-23px',
                        zIndex: 1,
                    }}
                    aria-label="previous"
                    onClick={handlePrev}
                    disabled={isBeginning}
                >
                    <img src={isBeginning ? LeftSlideDark : LeftSlideBlue} alt="Previous" />
                </IconButton>
                <IconButton
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        right: '-23px',
                        zIndex: 1,
                    }}
                    aria-label="next"
                    onClick={handleNext}
                    disabled={isEnd}
                >
                    <img src={isEnd ? RightSlideDark : RightSlideBlue} alt="Next" />
                </IconButton>
            </MuiBox>
            {/* Thumbnail Swiper */}
            <Swiper
                onSwiper={setThumbsSwiper}
                spaceBetween={10}
                slidesPerView={5}
                watchSlidesProgress
                modules={[Thumbs]}
                className="hospital-detail-swiper-thumbnail"
            >
                {swiperSlides?.map((item, index) => (
                    <SwiperSlide key={index}>
                        <MuiBox className="hospital-swiper-thumbnail-slide">
                            <img src={item.imageUrl} className="img-cover" alt={`Thumbnail ${index}`} />
                        </MuiBox>
                    </SwiperSlide>
                ))}
            </Swiper>
        </MuiBox>
    );
};

export default HospitalDetailSwiper;
