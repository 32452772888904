import React from "react";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { Input, SelectComponent, SubmitBtn } from "components/atoms";
import { Name, Country } from "utils/validation/AddProvince";
import {
  AddProvinceAction,
  UpdateProvinceAction,
} from "store/middlewares/provinces";

function ModalEditProvinces({ row }) {
  const dispatch = useDispatch();

  const { countries } = useSelector((state) => state.countries);

  const EditCategorySchema = Yup.object({ name: Name, countryId: Country });

  const handleSubmit = (values) => {
    if (row) {
      dispatch(UpdateProvinceAction({ id: row.id, ...values }));
    } else {
      dispatch(AddProvinceAction(values));
    }
  };

  const formik = useFormik({
    initialValues: {
      name: row?.name ? row?.name : "",
      countryId: row?.country_id ? row?.country_id : "",
    },
    validationSchema: EditCategorySchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Input
        inputClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 mt-2"
        label={"Name"}
        type="text"
        name="name"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.name && formik.touched.name}
        errorMsg={formik.errors.name}
        value={formik.values.name}
        placeholder="Enter Name"
      />
      <SelectComponent
        className={"px-0"}
        label={"Country"}
        options={countries?.map((country) => {
          return {
            label: country?.name,
            value: country?.id,
          };
        })}
        placeholder="Select Country"
        name={"coutryId"}
        value={formik.values.countryId}
        error={formik.errors.countryId && formik.touched.countryId}
        errorMsg={formik.errors.countryId}
        onChange={(selectedOption) => {
          formik.setFieldValue(
            "countryId",
            selectedOption ? selectedOption.value : ""
          );
        }}
      />
      <SubmitBtn title={row ? "Update" : "Add"} />
    </form>
  );
}

export default ModalEditProvinces;
