import axiosClient from "utils/api";
const URI = "/admin/badges";
const WEB = "/badges";

export const getBadges = async (payload) => {
  const URL = `${URI}/badgeDesignation`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
      params: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Get All Badges:", error);
    throw error;
  }
};

export const getBadgesForWeb = async () => {
  const URL = `${WEB}/badgeDesignation`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Get All Badges:", error);
    throw error;
  }
};

export const addBadgesForWeb = async (payload) => {
  const URL = `${WEB}/badge`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Get All Badges:", error);
    throw error;
  }
};

export const deleteBadges = async (payload) => {
  const URL = `${URI}/badgeDesignation/${payload.id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "delete",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Delete Badges :", error);
    throw error;
  }
};

export const updateBadges = async (payload) => {
  let { id, ...rest } = payload;
  const URL = `${URI}/badgeDesignation/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Update Badges :", error);
    throw error;
  }
};

export const addBadges = async (payload) => {
  const URL = `${URI}/badgeDesignation`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Add Badges :", error);
    throw error;
  }
};

export const updateVisiblityBadges = async (payload) => {
  let { id, ...rest } = payload;
  const URL = `${URI}/visibility/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Update visiblity Badges:", error);
    throw error;
  }
};

// For Get Professional Request Badges
export const getProfessionalRequestBadges = async () => {
  const URL = `${URI}/professionalBadges`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Get Professional Request Badges:", error);
    throw error;
  }
};

