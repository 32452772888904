import React from "react";
import MuiBox from "../MuiBox";
import MuiTypography from "../MuiTypography";

import Nodate from "assets/images/SVG/no-date.svg";

const NoSchedule = () => {
  return (
    <MuiBox sx={{ display: "flex", alignItems: "center", gap: 2 }}>
      <MuiBox sx={{ width: "79.95px", height: "73.28px" }}>
        <img src={Nodate} />
      </MuiBox>
      <MuiTypography variant="baseFont" color="sliderText.light">
        No Schedule Available
      </MuiTypography>
    </MuiBox>
  );
};

export default NoSchedule;
