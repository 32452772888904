import * as Yup from "yup";
import { onlySpaceRegExp, startsWithSpaceRegExp } from "utils/regex";

export const ShiftTitle = Yup.string()
  .required("Please enter shift title")
  .matches(startsWithSpaceRegExp, "Shift Title cannot start with a space")
  .matches(
    onlySpaceRegExp,
    "Shift Title should contain at least one character"
  );

export const jobTitle = Yup.string().required("Please select job title");
export const skillId = Yup.array().min(1, "Please select skill");
export const shiftType = Yup.string().required("Please select shift type");
export const employeeHourRate = Yup.number().min(0, "Employee rate cannot be negative").required(
  "Please enter employee rate"
);
export const burden = Yup.number().required("Please enter burden");
export const agencyCost = Yup.number().required("Please enter agency cost");
export const totalCostPerHr = Yup.number().required(
  "Please enter total cost per hour"
);
export const address = Yup.string().required("Please select Address");
export const floor = Yup.string().required("Please select floor");
export const estimatedMileage = Yup.string().required(
  "Please select estimated mileage"
);
export const comment = Yup.string().required("Please select comment");
export const schedule = Yup.array().min(1, "Please select schedule");
export const additionalBonus = Yup.array().min(1, "Please select schedule");

export const startDate = Yup.string().required("Please enter start date");
export const endDate = Yup.string().required("Please enter end date");
export const startTime = Yup.string().required("Please enter start time");
export const endTime = Yup.string().required("Please enter end time");
