import React from "react";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { Input, SubmitBtn } from "components/atoms";
import { Name, Type } from "utils/validation/AddAccess";
import {
  AddFeaturesAction,
  UpdateFeaturesAction,
} from "store/middlewares/features";

function ModalEditFeatures({ row }) {
  const dispatch = useDispatch();

  const AddAccessSchema = Yup.object({ name: Name, type: Type });

  const handleSubmit = (values) => {
    if (row) {
      dispatch(UpdateFeaturesAction({ id: row?.id, ...values }));
    } else {
      dispatch(AddFeaturesAction(values));
    }
  };

  const formik = useFormik({
    initialValues: {
      name: row?.name ? row?.name : "",
      type: row?.type ? row?.type : "",
    },
    validationSchema: AddAccessSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Input
        inputClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 mt-2"
        label={"Name"}
        error={formik.errors.name && formik.touched.name}
        errorMsg={formik.errors.name}
        value={formik.values.name}
        type="text"
        name="name"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        placeholder="Enter Name"
      />
      <Input
        inputClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 mt-2"
        label={"Type"}
        error={formik.errors.type && formik.touched.type}
        errorMsg={formik.errors.type}
        value={formik.values.type}
        type="text"
        name="type"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        placeholder="Enter Type"
      />
      <SubmitBtn title={row ? "Update" : "Add"} />
    </form>
  );
}

export default ModalEditFeatures;
