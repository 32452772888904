import React from "react";
import { Calendar, Clock } from "lucide-react";
import dayjs from "dayjs";

const notificationTab = ({ dateSchedule }) => {
  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-5">
        {dateSchedule.map((schedule) => (
          <div
            key={schedule.id} // Ensure a unique key for each item
            className="border border-dashed card p-5 border-custom-200"
          >
            <div className="flex justify-between items-center">
              <Calendar />
              <p>
                {dayjs(schedule.startDate).format("DD/MM/YYYY")} -{" "}
                {dayjs(schedule.endDate).format("DD/MM/YYYY")}
              </p>
            </div>
            <div className="mt-2">
              {schedule.JobTimeSchedule.map((tschedule) => (
                <div
                  key={tschedule?.id}
                  className="flex justify-between items-center"
                >
                  <Clock />
                  <p>
                    {" "}
                    {dayjs(tschedule?.startTime).format("hh:mm A")} -{" "}
                    {dayjs(tschedule?.endTime).format("hh:mm A")}
                  </p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default notificationTab;
