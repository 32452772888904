import { createSlice } from "@reduxjs/toolkit";
import {
  AddPaymentPeriodAction,
  DeletePaymentPeriodAction,
  GetPaymentPeriodsAction,
  UpdatePaymentPeriodAction,
  UpdateDefaultPaymentPeriodAction,
  UpdateVisiblityPaymentPeriodAction,
  GetPaymentPeriodsForWebAction,
} from "store/middlewares/paymentPeriods";

export const paymentPeriodsSlice = createSlice({
  name: "paymentPeriods",
  initialState: {
    paymentPeriodsLoading: false,
    paymentPeriods: [],
    paymentPeriodsFilterProps: [],
  },
  reducers: {
    setPaymentPeriodsFilterProps: (state, action) => {
      state.paymentPeriodsFilterProps = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(GetPaymentPeriodsAction.pending, (state) => {
        state.paymentPeriodsLoading = true;
        state.paymentPeriods = [];
      })
      .addCase(GetPaymentPeriodsAction.fulfilled, (state, action) => {
        state.paymentPeriodsLoading = false;
        state.paymentPeriods = action.payload.response.data
          ? action.payload.response.data
          : action.payload.response;
      })
      .addCase(GetPaymentPeriodsAction.rejected, (state) => {
        state.paymentPeriodsLoading = false;
        state.paymentPeriods = [];
      })
      .addCase(GetPaymentPeriodsForWebAction.pending, (state) => {
        state.paymentPeriodsLoading = true;
        state.paymentPeriods = [];
      })
      .addCase(GetPaymentPeriodsForWebAction.fulfilled, (state, action) => {
        state.paymentPeriodsLoading = false;
        state.paymentPeriods = action.payload.response.data
          ? action.payload.response.data
          : action.payload.response;
      })
      .addCase(GetPaymentPeriodsForWebAction.rejected, (state) => {
        state.paymentPeriodsLoading = false;
        state.paymentPeriods = [];
      })
      // Delete Payment Periods
      .addCase(DeletePaymentPeriodAction.fulfilled, (state, action) => {
        let { id } = action.payload;
        let tempArray = state.paymentPeriods?.filter(
          (title) => title.id !== id
        );
        state.paymentPeriodsLoading = false;
        state.paymentPeriods = tempArray;
      })
      //Update Payment Periods
      .addCase(UpdatePaymentPeriodAction.fulfilled, (state, action) => {
        let obj = action.payload.response.data
          ? action.payload.response.data
          : action.payload.response;
        let { id, ...rest } = obj;
        let tempArray = state.paymentPeriods?.map((title) => {
          if (title.id === id) {
            return { ...title, ...rest };
          }
          return { ...title };
        });
        state.paymentPeriodsLoading = false;
        state.paymentPeriods = tempArray;
      })
      //Add Payment Periods
      .addCase(AddPaymentPeriodAction.fulfilled, (state, action) => {
        state.paymentPeriodsLoading = false;
        state.paymentPeriods = [
          action.payload.response,
          ...state.paymentPeriods,
        ];
      })

      //Update Visiblity Badges
      .addCase(
        UpdateVisiblityPaymentPeriodAction.fulfilled,
        (state, action) => {
          state.paymentPeriodsLoading = false;
          let { id } = action.payload;
          let tempArray = state.paymentPeriods?.map((title) => {
            if (title.id === id) {
              return { ...title, isVisible: !title.isVisible };
            }
            return { ...title };
          });
          state.paymentPeriods = tempArray;
        }
      )

      .addCase(UpdateDefaultPaymentPeriodAction.fulfilled, (state, action) => {
        state.paymentPeriodsLoading = false;
        let { id } = action.payload;
        let tempArray = state.paymentPeriods?.map((title) => {
          if (title.id === id) {
            return { ...title, isDefault: true };
          }
          return { ...title, isDefault: false };
        });
        state.paymentPeriods = tempArray;
      });
  },
});

export const {setPaymentPeriodsFilterProps} = paymentPeriodsSlice.actions;

export default paymentPeriodsSlice.reducer;
