import React from "react";
import { MuiBox } from "components/atoms";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

const HeroSwiper = ({ banners }) => {
  return (
    <Swiper
      spaceBetween={20}
      // pagination={true}
      modules={[Pagination]}
      className="hero-swiper"
      pagination={{
        clickable: true,
      }}
    >
      {banners?.map((item, index) => (
        <SwiperSlide className="hero-swiper-slide" key={index}>
          <MuiBox className="hero-slide-box">
            <MuiBox className="hero-slide-img">
              {item?.fileType === "image" ? (
                <img
                  src={item.bannerUrl}
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "100%",
                    height: "100%",
                    objectFit:
                      "cover" /* Makes the video cover the container */,
                  }}
                />
              ) : (
                <video
                  src={item.bannerUrl}
                  controls="controls"
                  // autoPlay={true}
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "100%",
                    height: "100%",
                    objectFit:
                      "cover" /* Makes the video cover the container */,
                  }}
                />
              )}
            </MuiBox>
          </MuiBox>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default HeroSwiper;
