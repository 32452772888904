import React from "react";
import { Input, SelectComponent } from "components/atoms";

const PhoneWithCountryCodeInput = ({
  selectName,
  selectValue,
  selectPlaceholder,
  options,
  selectOnChange,
  selectFieldClassName,
  selectWrapperClassName,
  inputName,
  inputOnChange,
  inputOnBlur,
  inputPlaceholder,
  inputValue,
  inputFieldClassName,
  inputWrapperClassName,
  className,
  wrapperclassName,
  error,
  errorMsg,
  disabled = false,
  label,
  errorDivClassName,
  isRequired = true,
}) => {
  return (
    <div className={`p-5 py-2 ${className} `}>
      {typeof label === "string" ? (
        <>
          <label className="font-medium">
            {label} {isRequired && <span className="text-[#FF5C5C]">*</span>}
          </label>
          <br />
        </>
      ) : (
        label
      )}
      <div
        className={`flex rounded-lg plain-select border border-solid mt-2 bg-white overflow-hidden ${
          error ? "border-red-500" : "border-[#e2e8f0]"
        }  ${wrapperclassName} ${disabled && "bg-slate-100"}`}
      >
        <SelectComponent
          options={options}
          placeholder={selectPlaceholder}
          name={selectName}
          value={selectValue}
          disabled={disabled}
          onChange={(selectedOption) => {
            if (selectOnChange) {
              selectOnChange(selectedOption);
            }
          }}
          isClearable={false}
          selectclassName={`mt-0 focus:outline-none focus:border-none shadow-none ${selectFieldClassName}`}
          className={`!p-0 ${selectWrapperClassName}`}
        />
        <Input
          inputClassName={`rounded-lg w-full px-2 py-2 ${inputFieldClassName} border-none-importat`}
          type="number"
          disabled={disabled}
          name={inputName}
          onChange={(e) => {
            if (inputOnChange) {
              inputOnChange(e);
            }
          }}
          onBlur={(e) => {
            if (inputOnBlur) {
              inputOnBlur(e);
            }
          }}
          value={inputValue}
          placeholder={inputPlaceholder}
          className={`!p-0 w-9/12 flex-grow ${inputWrapperClassName}`}
        />
      </div>
      {error ? (
        <div className={`mt-1 text-sm text-red-500 ${errorDivClassName}`}>
          {errorMsg}
        </div>
      ) : null}
    </div>
  );
};

export default PhoneWithCountryCodeInput;
