import { createSlice } from "@reduxjs/toolkit";
import {
  AddFaqAction,
  DeleteFaqAction,
  GetAllEmailsAction,
  GetAllFaqsAction,
  GetAllFaqsVotesAction,
  UpdateFaqAction,
  UpdateVisiblityFaqAction,
  GetAllChatListAction,
  UpdateStatusAction,
  AddChatAction,
  GetUserMessagesAction,
  AddMessageAction,
  DeleteMessageAction,
  FinishChatAction,
  GetAllFaqsWebAction,
  VoteFaqWebAction,
  GetAllChatListForWebAction,
  AddChatForWebAction,
  GetUserMessagesForWebAction,
  AddMessageForWebAction,
  AddReviewForChatAction,
} from "store/middlewares/HelpAndSupport";

const initalVal = {
  faqs: [],
  faqsLoading: false,
  votes: [],
  votesLoading: false,
  emails: [],
  emailsLoading: false,
  emailSent: false,
  userchat: [],
  userchatLoading: false,
  chatList: [],
  chatListLoading: false,
  addChat: {},
  faqsFilterProps: {},
  emailsFilterProps: {},
  addChatLoading: false,
  getUserMessages: [],
  getUserMessagesLoading: false,
  isChatFinished: false,
};

export const helpAndSupportSlice = createSlice({
  name: "helpAndSupport",
  initialState: initalVal,
  reducers: {
    setFaqFilterProps: (state, action) => {
      state.faqsFilterProps = action.payload;
    },
    setEmailFilterProps: (state, action) => {
      state.emailsFilterProps = action.payload;
    },
    resetReducer: (state) => {
      state.addChat = {};
      state.addChatLoading = false;
      state.getUserMessages = [];
      state.getUserMessagesLoading = false;
    },
    setEmailSentStatus: (state, action) => {
      state.emailSent = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      // For Fetch All FAQs
      .addCase(GetAllFaqsAction.pending, (state) => {
        // state.faqsLoading = state.faqs.length > 0 ? false : true;
        state.faqsLoading = true;
        state.faqs = [];
      })
      .addCase(GetAllFaqsAction.fulfilled, (state, action) => {
        state.faqsLoading = false;
        state.faqs = action.payload.response;
      })
      .addCase(GetAllFaqsAction.rejected, (state) => {
        state.faqsLoading = false;
        state.faqs = [];
      })

      .addCase(GetAllFaqsWebAction.pending, (state) => {
        state.faqsLoading = true;
        state.faqs = [];
      })
      .addCase(GetAllFaqsWebAction.fulfilled, (state, action) => {
        state.faqsLoading = false;
        state.faqs = action.payload.response;
      })
      .addCase(GetAllFaqsWebAction.rejected, (state) => {
        state.faqsLoading = false;
        state.faqs = [];
      })

      // For Fetch All FAQ Votes
      .addCase(GetAllFaqsVotesAction.pending, (state) => {
        state.votesLoading = true;
        state.votes = [];
      })
      .addCase(GetAllFaqsVotesAction.fulfilled, (state, action) => {
        state.votesLoading = false;
        state.votes = action.payload;
      })
      .addCase(GetAllFaqsVotesAction.rejected, (state) => {
        state.votesLoading = false;
        state.votes = [];
      })

      // For Add FAQ
      .addCase(AddFaqAction.fulfilled, (state, action) => {
        state.faqsLoading = false;
        state.faqs = [action.payload.response, ...state.faqs];
      })

      // For Update FAQ
      .addCase(UpdateFaqAction.fulfilled, (state, action) => {
        state.faqsLoading = false;
        state.faqs = state.faqs.map((faq) =>
          faq.id == action.payload.response.id ? action.payload.response : faq
        );
      })

      // For Delete FAQ
      .addCase(DeleteFaqAction.fulfilled, (state, action) => {
        let { id } = action.payload;
        let tempArray = state.faqs?.filter((faq) => faq.id !== id);
        state.faqsLoading = false;
        state.faqs = tempArray;
      })

      // For Update Visibility
      .addCase(UpdateVisiblityFaqAction.fulfilled, (state, action) => {
        state.faqsLoading = false;
        state.faqs = state.faqs.map((faq) =>
          faq.id === action.payload.id
            ? { ...faq, isVisible: !faq.isVisible }
            : faq
        );
      })

      //Up/Down Vote
      .addCase(VoteFaqWebAction.fulfilled, (state, action) => {
        state.faqsLoading = false;
        state.faqs = state.faqs?.map((que) => {
          if (que?.id === action.payload.faqId) {
            let tempObj = { ...que };
            if (action.payload.voteType.toLowerCase() === "up") {
              if (tempObj.isDownVote) {
                tempObj.downVotes -= 1;
              }
              tempObj.upVotes += 1;
              tempObj.isDownVote = false;
              tempObj.isUpVote = true;
            }
            if (action.payload.voteType.toLowerCase() === "down") {
              if (tempObj.isUpVote) {
                tempObj.upVotes -= 1;
              }
              tempObj.downVotes += 1;
              tempObj.isUpVote = false;
              tempObj.isDownVote = true;
            }

            return tempObj;
          }
          return que;
        });
      })

      // For Fetch Emails
      .addCase(GetAllEmailsAction.pending, (state) => {
        state.emailsLoading = true;
        state.emails = [];
      })
      .addCase(GetAllEmailsAction.fulfilled, (state, action) => {
        state.emailsLoading = false;
        state.emails = action.payload.response;
      })
      .addCase(GetAllEmailsAction.rejected, (state) => {
        state.emailsLoading = false;
        state.emails = [];
      })

      // For Update Emails
      .addCase(UpdateStatusAction.fulfilled, (state, action) => {
        let { id, status } = action.payload;
        state.emails = state.emails.map((email) => {
          if (email.id === id) {
            return { ...email, isArchived: true, status: status };
          }
          return { ...email };
        });
      })

      // For Fetch h&s chat list
      .addCase(GetAllChatListAction.pending, (state) => {
        state.chatListLoading = true;
        state.chatList = [];
      })
      .addCase(GetAllChatListAction.fulfilled, (state, action) => {
        state.chatListLoading = false;
        state.chatList = action.payload.response;
      })
      .addCase(GetAllChatListAction.rejected, (state) => {
        state.chatListLoading = false;
        state.chatList = [];
      })

      // For Add Chat
      .addCase(AddChatAction.pending, (state) => {
        state.addChatLoading = true;
        state.addChat = {};
      })
      .addCase(AddChatAction.fulfilled, (state, action) => {
        state.addChatLoading = false;
        state.addChat = action.payload.response;
      })
      .addCase(AddChatAction.rejected, (state) => {
        state.addChatLoading = false;
        state.addChat = {};
      })

      // For Get Messages
      .addCase(GetUserMessagesAction.pending, (state) => {
        state.getUserMessagesLoading = true;
        state.getUserMessages =
          state.getUserMessages.length > 0 ? state.getUserMessages : [];
      })
      .addCase(GetUserMessagesAction.fulfilled, (state, action) => {
        state.getUserMessagesLoading = false;
        state.getUserMessages = action.payload.response.reverse();
      })
      .addCase(GetUserMessagesAction.rejected, (state) => {
        state.getUserMessagesLoading = false;
        state.getUserMessages = [];
      })

      // For Add Message
      .addCase(AddMessageAction.fulfilled, (state, action) => {
        if (Array.isArray(state.getUserMessages)) {
          state.getUserMessages.push(action.payload.response);
        } else {
          state.getUserMessages = [action.payload.response];
        }
      })

      // For delete Message
      .addCase(DeleteMessageAction.fulfilled, (state, action) => {
        state.addChat = {};
        state.chatList = state.chatList.filter(
          (chat) => chat.id !== action.payload.id
        );
      })

      // For Finish Chat
      .addCase(FinishChatAction.fulfilled, (state) => {
        state.addChat = {
          ...state.addChat,
          isChatFinished: !state.addChat.isChatFinished,
        };
      })

      // For Fetch h&s chat list
      .addCase(GetAllChatListForWebAction.pending, (state) => {
        state.chatListLoading = true;
        state.chatList = [];
      })
      .addCase(GetAllChatListForWebAction.fulfilled, (state, action) => {
        state.chatListLoading = false;
        state.chatList = action.payload.response;
      })
      .addCase(GetAllChatListForWebAction.rejected, (state) => {
        state.chatListLoading = false;
        state.chatList = [];
      })

      // For Add Chat
      .addCase(AddChatForWebAction.fulfilled, (state, action) => {
        state.addChatLoading = false;
        state.addChat = action.payload.response;
      })

      // For Get Messages
      .addCase(GetUserMessagesForWebAction.pending, (state) => {
        state.getUserMessagesLoading = true;
        state.getUserMessages =
          state.getUserMessages.length > 0 ? state.getUserMessages : [];
      })
      .addCase(GetUserMessagesForWebAction.fulfilled, (state, action) => {
        state.getUserMessagesLoading = false;
        state.getUserMessages = action.payload.response.messages.reverse();
        state.isChatFinished = action.payload.response.isChatFinished;
      })
      .addCase(GetUserMessagesForWebAction.rejected, (state) => {
        state.getUserMessagesLoading = false;
        state.getUserMessages = [];
      })

      // For Add Message
      .addCase(AddMessageForWebAction.fulfilled, (state, action) => {
        if (Array.isArray(state.getUserMessages)) {
          state.getUserMessages.push(action.payload.response);
        } else {
          state.getUserMessages = [action.payload.response];
        }
      })

      // For Add Message
      .addCase(AddReviewForChatAction.fulfilled, (state, action) => {
        state.chatList = state.chatList.map((item) => {
          if (item?.id === action.payload.chatId) {
            return {
              ...item,
              expeirence: action.payload.expeirence,
              notes: action.payload.notes,
            };
          }
          return { ...item };
        });
      });
  },
});

export const { resetReducer, setEmailSentStatus,setEmailFilterProps,setFaqFilterProps } = helpAndSupportSlice.actions;

export default helpAndSupportSlice.reducer;
