import { Button, IconButton } from "@mui/material";
import { MuiBox, MuiTypography } from "components/atoms";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { openToast } from "store/slices/common/toast.slice";
import CameraIcon from "assets/images/SVG/CameraIcon.svg";
import { ImagesFileTypes } from "utils/ImagesFileTypes";
import { Trash2Icon } from "lucide-react";
import {
  ProfessionalClockInAction,
  ProfessionalClockOutAction,
} from "store/middlewares/users/schedule";

const ClockInOutModal = ({ jobTimeScheduleId, clockIn = false }) => {
  const dispatch = useDispatch();
  const [selectedImg, setSelectedImg] = useState("");
  const [latLongObj, setlatLongObj] = useState(null);

  useEffect(() => {
    getLocation();
  }, []);

  const getLocation = () => {
    if (!navigator.geolocation) {
      setlatLongObj(null);
    } else {
      setlatLongObj(null);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setlatLongObj({
            latitude: `${position.coords.latitude}`,
            longitude: `${position.coords.longitude}`,
          });
        },
        () => {
          setlatLongObj(null);
        }
      );
    }
  };

  const handleSubmit = (values) => {
    if (!clockIn) {
      dispatch(
        ProfessionalClockInAction({
          ...values,
          jobTimeScheduleId,
          ...latLongObj,
        })
      );
    } else {
      dispatch(
        ProfessionalClockOutAction({
          id: jobTimeScheduleId,
          ...values,
        })
      );
    }
  };

  const formik = useFormik({
    initialValues: { file: null },
    validationSchema: null,
    onSubmit: handleSubmit,
  });

  const handleChange = (event) => {
    const file = event.target.files[0];
    if (file && ImagesFileTypes.includes(file.type)) {
      formik.setFieldValue("file", file);
      setSelectedImg(URL.createObjectURL(file));
      event.target.value = "";
    } else {
      dispatch(
        openToast({
          type: "error",
          message:
            "File has an unsupported file type. Allowed file types are: .jpeg, .jpg, .png, .gif, .bmp, .tiff, .webp, .heif, .heic, .cr2, .nef, .arw, .jfif, .avif, .bpg",
        })
      );
      event.target.value = "";
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <MuiBox
        sx={{
          px: { xs: "10px", sm: "20px", md: "30px" },
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <MuiTypography variant="h6" fontWeight="bold">
          Clock In Selfie Picture
        </MuiTypography>
        <Button
          component={"label"}
          disableRipple
          sx={{
            bgcolor: "#fff !important",
            p: "30px 12px",
            borderRadius: "12px",
            gap: 2,
          }}
        >
          <MuiTypography
            fontWeight="bold"
            fontSize="14px"
            sx={{ color: "#000" }}
          >
            Upload Picture
          </MuiTypography>
          <MuiBox
            sx={{
              height: "50px",
              width: "50px",
              bgcolor: "#0CA8DF",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "50%",
            }}
          >
            <img src={CameraIcon} />
          </MuiBox>
          <input
            type="file"
            onChange={(e) => handleChange(e)}
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
            }}
            accept="image/png, image/jpeg"
            hidden
          />
        </Button>
        {selectedImg && (
          <MuiBox sx={{ height: "600px", width: "100%", position: "relative" }}>
            <img src={selectedImg} className="img-cover" />
            <IconButton
              disableRipple
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                padding: "8px",
                bgcolor: "error.main",
                color: "#fff",
                "& :hover": {
                  bgcolor: "error.dark",
                },
              }}
              onClick={() => {
                setSelectedImg("");
                formik.setFieldValue("file", null);
              }}
            >
              <Trash2Icon />
            </IconButton>
          </MuiBox>
        )}
        <Button type="submit" disabled={!formik.values.file} variant="lightButton" sx={{ py: "12px" }}>
          Submit
        </Button>
      </MuiBox>
    </form>
  );
};

export default ClockInOutModal;
