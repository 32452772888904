import { createSlice } from "@reduxjs/toolkit";
import {
  AddBannerAction,
  DeleteBannerAction,
  GetAllBannersAction,
  UpdateBannerAction,
} from "store/middlewares/banner";

export const bannersSlice = createSlice({
  name: "banners",
  initialState: {
    bannersLoading: false,
    banners: [],
    bannersFilterProps: {},
  },
  reducers: {
    setBannersFilterProps: (state, action) => {
      state.bannersFilterProps = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(GetAllBannersAction.pending, (state) => {
        state.bannersLoading = true;
        state.banners = [];
      })
      .addCase(GetAllBannersAction.fulfilled, (state, action) => {
        state.bannersLoading = false;
        state.banners = action.payload.response;
      })
      .addCase(GetAllBannersAction.rejected, (state) => {
        state.bannersLoading = false;
        state.banners = [];
      })
      // Delete banner
      .addCase(DeleteBannerAction.fulfilled, (state, action) => {
        let { id } = action.payload;
        let tempArray = state.banners?.filter((title) => title.id !== id);
        state.bannersLoading = false;
        state.banners = tempArray;
      })
      //Update banner
      .addCase(UpdateBannerAction.fulfilled, (state, action) => {
        let tempArray = state.banners?.map((title) => {
          if (title.id === action?.payload?.response?.id) {
            return {
              ...title,
              ...action.payload?.response,
              bannerSignedUrl: action.payload?.response?.signedUrl,
            };
          }
          return { ...title };
        });
        state.bannersLoading = false;
        state.banners = tempArray;
      })
      //Add banner
      .addCase(AddBannerAction.fulfilled, (state, action) => {
        state.bannersLoading = false;
        state.banners = [action.payload.response, ...state.banners];
      });
  },
});
export const { setBannersFilterProps } = bannersSlice.actions;
export default bannersSlice.reducer;
