import { Button } from "@mui/material";
import React from "react";

const ScheduleDetailPill = ({ status }) => {
  const pillColor = {
    cancelled: "#F8585A",
    completed: "#07B67C",
  };
  return (
    <Button
      disabled
      sx={{
        bgcolor: pillColor[status.toLowerCase()],
        color: "#fff !important",
      }}
    >
      {status}
    </Button>
  );
};

export default ScheduleDetailPill;
