import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  addBanner,
  deleteBanner,
  getBanners,
  updateBanner,
  updateVisiblityBanner,
} from "store/services/banner";
import { closeAdminModal } from "store/slices/common/adminModal.slice";
import { openToast } from "store/slices/common/toast.slice";
import { handleAuthError } from "utils/handleAuthError";
import { addUploadFile } from "store/services/users/professionals";

export const GetAllBannersAction = createAsyncThunk(
  "banners/getAllBanners",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await getBanners(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(res);
      }
      return rejectWithValue(res);
    } catch (error) {
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

export const DeleteBannerAction = createAsyncThunk(
  "banners/deleteBanner",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await deleteBanner(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(openToast({ type: "success", message: res?.message }));
        return fulfillWithValue({ ...res, ...payload });
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue({ message: res?.message });
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

export const UpdateBannerAction = createAsyncThunk(
  "banners/updateBanner",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      let { file, thumbnail,oldUrl, ...rest } = payload;
      if (file) {
        const uploadFileResponse = await addUploadFile({
          file,
          uploadFor: "banner",
        });
        if (uploadFileResponse.status === 1) {
          rest = { ...rest, banner: uploadFileResponse?.response?.urls[0] };
        }
        if (thumbnail) {
          const thumbnailFileResponse = await addUploadFile({
            file: thumbnail,
            uploadFor: "banner",
          });
          if (thumbnailFileResponse.status === 1) {
            rest = {
              ...rest,
              thumbnail: thumbnailFileResponse?.response?.urls[0],
            };
          }
        }
        
      }else{
        rest = {
          ...rest,
          banner: oldUrl,
        };
      }

      let res = await updateBanner(rest);
      if (res.status === 1) {
        dispatch(closeAdminModal());
        dispatch(openToast({ type: "success", message: res?.message }));
        return fulfillWithValue(res);
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue({ message: res?.message });
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

export const AddBannerAction = createAsyncThunk(
  "banners/addBanner",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      let { file, thumbnail, ...rest } = payload;
      const uploadFileResponse = await addUploadFile({
        file,
        uploadFor: "banner",
      });

      if (uploadFileResponse.status === 1) {
        rest = { ...rest, banner: uploadFileResponse?.response?.urls[0] };
      }

      if (thumbnail) {
        const thumbnailFileResponse = await addUploadFile({
          file: thumbnail,
          uploadFor: "banner",
        });
        if (thumbnailFileResponse.status === 1) {
          rest = {
            ...rest,
            thumbnail: thumbnailFileResponse?.response?.urls[0],
          };
        }
      }

      let res = await addBanner(rest);
      if (res.status === 1) {
        dispatch(closeAdminModal());
        dispatch(openToast({ type: "success", message: res?.message }));
        return fulfillWithValue(res);
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue({ message: res?.message });
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

//Update Visiblity banner
export const UpdateVisiblityBannerAction = createAsyncThunk(
  "banners/updateVisiblityBanner",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await updateVisiblityBanner(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue({ ...res, ...payload });
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);
