import React, { useEffect, useState } from "react";
import { Button, IconButton } from "@mui/material";
import {
  CustomLoader,
  JobDetailsScheduleCard,
  MuiBox,
  MuiTypography,
} from "components/atoms";

import Eye from "assets/images/SVG/Eye blue.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  ApproveApplicationForWebAction,
  GetJobSummaryForWebAction,
} from "store/middlewares/jobs";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { deductionTitleObj } from "utils/deductionTitleObj";

// Enable the custom parse format plugin
dayjs.extend(customParseFormat);
const HireMeModal = ({ jobId, applicantId }) => {
  const dispatch = useDispatch();
  const { singleJob, singleJobLoading } = useSelector((state) => state.jobs);
  useEffect(() => {
    if (jobId) {
      dispatch(GetJobSummaryForWebAction({ id: jobId }));
    }
  }, [jobId]);

  const [scheduleOpen, setScheduleOpen] = useState(false);
  const handleScheduleBtn = () => setScheduleOpen(!scheduleOpen);
  return (
    <MuiBox
      sx={{
        px: { xs: "10px", sm: "20px", md: "30px" },
        py: 5,
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      <MuiTypography variant="h6" fontWeight={"bold"} align={"center"}>
        Hire Me
      </MuiTypography>
      {singleJobLoading ? (
        <CustomLoader />
      ) : (
        <>
          <MuiBox
            sx={{
              p: { xs: "15px 10px", sm: 2 },
              bgcolor: "#fff",
              borderRadius: "12px",
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <MuiBox
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 1,
              }}
            >
              <MuiBox sx={{ width: "fit-content" }}>
                <MuiTypography variant="baseFont" color="sliderText.light">
                  Shift ID
                </MuiTypography>
              </MuiBox>
              <MuiBox sx={{ width: "fit-content" }}>
                <MuiTypography variant="baseFont" fontWeight="bold">
                  XYZ
                </MuiTypography>
              </MuiBox>
            </MuiBox>
            <MuiBox
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 1,
              }}
            >
              <MuiBox sx={{ width: "fit-content" }}>
                <MuiTypography variant="baseFont" color="sliderText.light">
                  Shift Date and Hours
                </MuiTypography>
              </MuiBox>
              <MuiBox
                sx={{
                  width: "fit-content",
                  display: "flex",
                  gap: 1,
                  alignItems: "center",
                }}
              >
                <MuiBox
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    justifyContent: "center",
                  }}
                >
                  <MuiTypography
                    variant="baseFont"
                    fontWeight="bold"
                    sx={{
                      display: "flex",
                      gap: "6px",
                      flexWrap: "wrap",
                      justifyContent: "flex-end",
                    }}
                  >
                    <span>
                      {dayjs(singleJob?.JobDateSchedule?.[0]?.startDate).format(
                        "DD/MM/YYYY"
                      )}
                    </span>
                    <span>To</span>
                    <span>
                      {dayjs(singleJob?.JobDateSchedule?.[0]?.endDate).format(
                        "DD/MM/YYYY"
                      )}
                    </span>
                  </MuiTypography>
                  <MuiTypography
                    variant="baseFont"
                    fontWeight="bold"
                    sx={{
                      display: "flex",
                      gap: "6px",
                      flexWrap: "wrap",
                      justifyContent: "flex-end",
                    }}
                  >
                    <span>
                      {dayjs(
                        singleJob?.JobDateSchedule?.[0]?.JobTimeSchedule?.[0]
                          ?.startTime,
                        "HH:mm"
                      ).format("hh:mm A")}
                    </span>
                    <span>To</span>
                    <span>
                      {dayjs(
                        singleJob?.JobDateSchedule?.[0]?.JobTimeSchedule?.[0]
                          ?.endTime,
                        "HH:mm"
                      ).format("hh:mm A")}
                    </span>
                  </MuiTypography>
                </MuiBox>
                <MuiBox sx={{ width: "fit-content" }}>
                  <IconButton
                    sx={{ bgcolor: "#E8F0F3", borderRadius: "6px" }}
                    onClick={handleScheduleBtn}
                  >
                    <img src={Eye} />
                  </IconButton>
                </MuiBox>
              </MuiBox>
            </MuiBox>
            <MuiBox
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 1,
              }}
            >
              <MuiBox sx={{ width: "fit-content" }}>
                <MuiTypography variant="baseFont" color="sliderText.light">
                  Rate
                </MuiTypography>
              </MuiBox>
              <MuiBox sx={{ width: "fit-content" }}>
                <MuiTypography variant="baseFont" fontWeight="bold">
                  ${singleJob?.perHour?.toFixed(2)}/hr
                </MuiTypography>
              </MuiBox>
            </MuiBox>
          </MuiBox>
          {scheduleOpen && (
            <MuiBox
              sx={{
                borderRadius: "12px",
                bgcolor: "#fff",
                p: { xs: "15px 10px", sm: 2 },
                mt: 2,
              }}
            >
              <MuiTypography variant="subtitle1">
                Schedule Date & Time
              </MuiTypography>
              <MuiBox
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "25px",
                  height: "23em",
                  overflowY: "auto",
                  pr: 1,
                }}
              >
                {singleJob?.JobDateSchedule?.map((schedule) => (
                  <JobDetailsScheduleCard
                    key={schedule?.id}
                    schedule={schedule}
                    fromHire={true}
                    jobId={jobId}
                    applicantId={applicantId}
                  />
                ))}
              </MuiBox>
            </MuiBox>
          )}

          <MuiBox sx={{ bgcolor: "#fff", borderRadius: "12px" }}>
            <MuiBox
              sx={{
                background:
                  "linear-gradient(91.68deg, #0CA8DF 7.75%, #413BE3 107.71%)",
                p: { xs: "15px 10px", sm: "20px 26px" },
                borderRadius: "12px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <MuiTypography variant="h6" fontWeight={"bold"} color="#fff">
                Total Spend hours
              </MuiTypography>
              <MuiBox sx={{ width: "fit-content" }}>
                <MuiTypography variant="h6" fontWeight={"bold"} color="#fff">
                  {singleJob?.totalHours} hrs
                </MuiTypography>
                <MuiTypography fontSize="14px" color="#fff">
                  ${singleJob?.perHour?.toFixed(2)}/hr
                </MuiTypography>
              </MuiBox>
            </MuiBox>
            {singleJob?.deductions && (
              <MuiBox
                sx={{
                  p: { xs: "13px 10px", sm: "20px 26px" },
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                {Object.keys(singleJob?.deductions).map((key) => (
                  <MuiBox
                    key={key}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: 1,
                    }}
                  >
                    <MuiBox sx={{ width: "fit-content" }}>
                      <MuiTypography variant="baseFont">
                        {deductionTitleObj[`${key}`]}
                      </MuiTypography>
                    </MuiBox>
                    <MuiBox sx={{ width: "fit-content" }}>
                      <MuiTypography variant="baseFont" fontWeight="bold">
                        - ${singleJob?.deductions?.[`${key}`]?.toFixed(2)}
                      </MuiTypography>
                    </MuiBox>
                  </MuiBox>
                ))}
              </MuiBox>
            )}
            <MuiBox
              sx={{
                background: "#0CA8DF",
                p: { xs: "13px 10px", sm: "20px 26px" },
                borderRadius: "12px",
              }}
            >
              <MuiBox
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: 1,
                }}
              >
                <MuiBox sx={{ width: "fit-content" }}>
                  <MuiTypography variant="subtitle1" color="#fff">
                    Total Deductions
                  </MuiTypography>
                </MuiBox>
                <MuiBox sx={{ width: "fit-content" }}>
                  <MuiTypography
                    variant="subtitle1"
                    fontWeight="bold"
                    color="#fff"
                  >
                    ${singleJob?.totalDeductions?.toFixed(2)}
                  </MuiTypography>
                </MuiBox>
              </MuiBox>
              <MuiBox
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: 1,
                }}
              >
                <MuiBox sx={{ width: "fit-content" }}>
                  <MuiTypography variant="subtitle1" color="#fff">
                    Estimated Net Pay
                  </MuiTypography>
                </MuiBox>
                <MuiBox sx={{ width: "fit-content" }}>
                  <MuiTypography
                    variant="subtitle1"
                    fontWeight="bold"
                    color="#fff"
                  >
                    ${singleJob?.estimatedNetPay?.toFixed(2)}
                  </MuiTypography>
                </MuiBox>
              </MuiBox>
            </MuiBox>
          </MuiBox>
          <MuiBox sx={{ textAlign: "center", mt: "48px" }}>
            <Button
              variant="lightButton"
              sx={{ maxWidth: "350px" }}
              onClick={() => {
                dispatch(ApproveApplicationForWebAction({ id: applicantId, status: "accepted" }));
              }}
            >
              Pay Now
            </Button>
          </MuiBox>
        </>
      )}
    </MuiBox>
  );
};

export default HireMeModal;
