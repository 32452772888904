import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { MuiBox, MuiContainer, MuiTypography } from "components/atoms";
import "swiper/css";
import "swiper/css/pagination";
import { Outlet } from "react-router-dom";
import SubscriptionBg from "assets/images/SVG/SubscriptionBg.svg";

const SubscriptionLayout = () => {
  return (
    <MuiContainer className="auth-layout-container">
      <Grid container sx={{ minHeight: "100dvh" }}>
        <Grid sm={12} md={4} lg={4} className="auth-layout-grid-item">
          <MuiBox
            sx={{
              p: 2,
              backgroundColor: "background.dark",
            }}
            className="auth-swiper-slide"
          >
            <MuiBox sx={{ height: "auto" }} className="auth-swiper-img">
              <img
                src={SubscriptionBg}
                alt={"Subscription"}
                className="img-cover"
              />
            </MuiBox>
            <MuiBox sx={{ height: "auto", maxWidth: "360px" }}>
              <MuiTypography
                variant="h2"
                sx={{ color: "darkButton.light", mb: 2 }}
                className="text-center"
              >
                Your Health, Our Priority: Get Exclusive Access
              </MuiTypography>
            </MuiBox>
          </MuiBox>
        </Grid>
        <Grid sm={12} md={8} lg={8} className="auth-layout-grid-item">
          <MuiContainer className="auth-box">
            <Outlet />
          </MuiContainer>
        </Grid>
      </Grid>
    </MuiContainer>
  );
};

export default SubscriptionLayout;
