import {
  CustomLoader,
  JobDetailsScheduleCard,
  MuiBox,
  MuiTypography,
} from "components/atoms";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SimpleBar from "simplebar-react";
import {
  GetAllScheduleDateAndTimeAction,
  GetHospitalJobDetailsAction,
} from "store/middlewares/users/professionals";

const ViewScheduleModal = ({ jobId, scheduleFor, shiftData }) => {
  const { singleJob, singleJobLoading } = useSelector((state) => state.jobs);

  const dispatch = useDispatch();
  useEffect(() => {
    if (scheduleFor === "shift") {
      if (shiftData) {
        dispatch(
          GetAllScheduleDateAndTimeAction({
            currentShiftId: shiftData?.id,
            userId: shiftData?.user?.id,
          })
        );
        // Data storation of above API is pending
      }
    } else {
      if (jobId) {
        dispatch(GetHospitalJobDetailsAction({ id: jobId }));
      }
    }
  }, [jobId, scheduleFor]);
  return (
    <MuiBox
      sx={{
        borderRadius: "12px",
        p: { xs: 1, sm: 2 },
      }}
    >
      <MuiTypography
        align={"center"}
        variant="subtitle1"
        fontWeight={"600"}
        sx={{ my: 2 }}
      >
        Schedule Date & Time
      </MuiTypography>
      {singleJobLoading ? (
        <CustomLoader />
      ) : (
        <SimpleBar style={{ maxHeight: "23em", overflowX: "hidden", mt: 2 }}>
          <MuiBox
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "25px",
              height: "23em",
            }}
          >
            {singleJob?.JobDateSchedule?.map((schedule) => (
              <JobDetailsScheduleCard
                key={schedule?.id}
                schedule={schedule}
                editable={false}
              />
            ))}
          </MuiBox>
        </SimpleBar>
      )}
    </MuiBox>
  );
};

export default ViewScheduleModal;
