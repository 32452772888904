import { createAsyncThunk } from "@reduxjs/toolkit";
import { HireMeModal, SuccessModal } from "components/molecules";
import React from "react";
import {
  addJobForWeb,
  approveApplicationForWeb,
  deleteJobForWeb,
  editJobForWeb,
  getApplicantDetail,
  getJobApplicantsForWeb,
  getJobByIdForWeb,
  getJobsForWeb,
  getJobSummary,
  getJobSummeryById,
  updateScheduleOfJobForWeb,
} from "store/services/jobPost";
import {
  getJobDetailsAction,
  getJobs,
  getProfessionalJobs,
} from "store/services/jobs";
import { closeDrawer, openDrawer } from "store/slices/common/drawer.slice";
import { handleAuthError } from "utils/handleAuthError";

// For Get All Jobs
export const GetJobsAction = createAsyncThunk(
  "jobs/getAllJobs",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getJobs(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

// For Get Professional Jobs
export const GetProfessionalJobsAction = createAsyncThunk(
  "jobs/getProfessionalJobs",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getProfessionalJobs(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

// For Get Professional Jobs
export const GetJobDetails = createAsyncThunk(
  "jobs/getJobDetails",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getJobDetailsAction(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      handleAuthError(error?.response?.status, true);
      return rejectWithValue(error);
    }
  }
);

// For Get All Jobs
export const GetAllJobsForWebAction = createAsyncThunk(
  "jobs/getAllJobsForWeb",
  async (payload, { rejectWithValue, getState, fulfillWithValue }) => {
    try {
      const response = await getJobsForWeb(payload);
      const { jobs } = getState();
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        let tempArray = response.response;
        if (payload.page > 1) {
          tempArray = [...jobs.jobs.items, ...tempArray];
        }
        let responseObj = { items: tempArray, totalPages: null };
        return fulfillWithValue({response: responseObj});
      }
      let responseObj = {
        items: jobs.jobs.items,
        totalPages: payload.page,
      };
      let tempObj = { message: response?.message, response: responseObj };
      return rejectWithValue(tempObj);
    } catch (error) {
      handleAuthError(error?.response?.status, true);
      return rejectWithValue(error);
    }
  }
);

// For Get All Jobs
export const GetJobByIdForWebAction = createAsyncThunk(
  "jobs/getJobByIdForWeb",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getJobByIdForWeb(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      handleAuthError(error?.response?.status, true);
      return rejectWithValue(error);
    }
  }
);

// For Get All Jobs
export const DeleteJobForWebAction = createAsyncThunk(
  "jobs/deleteJobForWeb",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await deleteJobForWeb(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeDrawer());
        return fulfillWithValue(payload);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      handleAuthError(error?.response?.status, true);
      return rejectWithValue(error);
    }
  }
);

// For Get All Jobs
export const GetJobApplicantsForWebAction = createAsyncThunk(
  "jobs/getJobApplicantsForWeb",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getJobApplicantsForWeb(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        let tempArray = [];
        response?.response.forEach((item) => {
          tempArray = [...tempArray, ...item.ApplyForJob];
        });
        return fulfillWithValue({
          response: tempArray,
        });
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      handleAuthError(error?.response?.status, true);
      return rejectWithValue(error);
    }
  }
);

// For Get All Jobs
export const AddJobForWebAction = createAsyncThunk(
  "jobs/addJobForWeb",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await addJobForWeb(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        // dispatch(closeDrawer());
        dispatch(
          openDrawer({
            content: (
              <SuccessModal
                viewMyJobs={true}
                btnText="View my jobs"
                title="Congratulations"
                message={
                  <>
                    Your Job has been
                    <br />
                    successfully Posted
                  </>
                }
              />
            ),
          })
        );
        return fulfillWithValue(payload);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      handleAuthError(error?.response?.status, true);
      return rejectWithValue(error);
    }
  }
);

// For Get All Jobs
export const UpdateJobForWebAction = createAsyncThunk(
  "jobs/updateJobForWeb",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await editJobForWeb(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        // dispatch(closeDrawer());
        dispatch(
          openDrawer({
            content: (
              <SuccessModal
                viewMyJobs={true}
                btnText="View my jobs"
                title="Congratulations"
                message={
                  <>
                    Your Job has been
                    <br />
                    successfully Updated
                  </>
                }
              />
            ),
          })
        );
        return fulfillWithValue(payload);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      handleAuthError(error?.response?.status, true);
      return rejectWithValue(error);
    }
  }
);

// For Get All Jobs
export const UpdateScheduleOfJobForWebAction = createAsyncThunk(
  "jobs/updateScheduleOfJobForWeb",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      let { fromHire, jobId, applicantId, ...rest } = payload;
      const response = await updateScheduleOfJobForWeb(rest);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeDrawer());
        if (fromHire) {
          dispatch(
            openDrawer({
              content: <HireMeModal jobId={jobId} applicantId={applicantId} />,
            })
          );
        }
        return fulfillWithValue({
          ...response,
          JobTimeSchedule: payload.timeSlots,
          scheduleId: payload.id,
        });
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      handleAuthError(error?.response?.status, true);
      return rejectWithValue(error);
    }
  }
);

// For Get All Jobs
export const GetJobSummaryForWebAction = createAsyncThunk(
  "jobs/getJobSummaryForWeb",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getJobSummary(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      handleAuthError(error?.response?.status, true);
      return rejectWithValue(error);
    }
  }
);

// For Get All Jobs
export const GetApplicantDetailForWebAction = createAsyncThunk(
  "jobs/getApplicantDetail",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getApplicantDetail(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        let tempObj = response.response;
        let user = {
          aboutMe: tempObj?.user?.aboutMe,
          cancelShift: tempObj?.cancelShift,
          completedShift: tempObj?.completedShift,
          inProgressShift: tempObj?.inProgressShift,
          upcomingShift: tempObj?.upcomingShift,
          fullName: `${tempObj?.user?.name}  ${tempObj?.user?.lastName}`,
          certifications: tempObj?.user?.generalCredentials,
          resume: tempObj?.user?.Resume?.[0],
          licenceNo: tempObj?.user?.ServiceLicenceCredentials?.[0]?.licenceNo,
          jobTitle: tempObj?.user?.jobTitles?.title,
          profileImageUrl: tempObj?.user?.profileImageUrl,
          status: tempObj?.status,
          jobId: tempObj?.jobId,
        };
        return fulfillWithValue(user);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      handleAuthError(error?.response?.status, true);
      return rejectWithValue(error);
    }
  }
);

// For Get All Jobs
export const ApproveApplicationForWebAction = createAsyncThunk(
  "jobs/approveApplicationForWeb",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await approveApplicationForWeb(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(
          openDrawer({
            content: (
              <SuccessModal
                tempRedirect={true}
                btnText="View Map"
                title={"Congratulations"}
                message={
                  <>
                    Your Hire request has been send <br /> successfully to{" "}
                    <strong>Aspen Press.</strong>
                  </>
                }
              />
            ),
          })
        );
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

// For Get Payment Details Summery For User
export const GetJobSummeryByIdAction = createAsyncThunk(
  "schedule/getJobSummeryById",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getJobSummeryById(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);
