import LocalstorageService from "./localstorage-services";

export function handleAuthError(status, isWeb = false) {
  if (status === 401 || status === 402) {
    if (isWeb) {
      LocalstorageService.removeFromLocalStorage("aboutcare-web");
      window.location = "/";
    } else {
      LocalstorageService.removeFromLocalStorage("aboutcare-admin");
      window.location = "/admin";
    }
  }
}
