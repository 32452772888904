import { Close } from "@mui/icons-material";
import { Box, Drawer, IconButton } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeDrawer } from "store/slices/common/drawer.slice";
import ArrowRightAlt from "assets/images/SVG/right arrow modal.svg";
import { resetErrorMessage } from "store/slices/common/errorMessage.slice";
const DrawerRight = () => {
  const {
    isOpen,
    backButton,
    content,
    width,
    anchor,
    borderRadiusLeft,
    borderRadiusRight,
  } = useSelector((state) => state.drawer);

  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(resetErrorMessage());
    dispatch(closeDrawer());
  };

  const PaperProps = {
    style: {
      width: "100%",
      borderTopLeftRadius: borderRadiusLeft ? borderRadiusLeft : "35px",
      borderBottomLeftRadius: borderRadiusLeft ? borderRadiusLeft : "35px",
      borderTopRightRadius: borderRadiusRight ? borderRadiusRight : "0",
      borderBottomRightRadius: borderRadiusRight ? borderRadiusRight : "0",
      maxWidth: width ? width : "503px",
      backgroundColor: "#E8F0F3",
      height: "100dvh",
    },
  };

  return (
    <Drawer
      anchor={anchor ? anchor : "right"}
      open={isOpen}
      onClose={onClose}
      PaperProps={PaperProps}
      className="main-modal"
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: backButton ? "space-between" : "flex-end",
          padding: "26px 30px",
        }}
      >
        {backButton && (
          <IconButton
            disableRipple
            sx={{
              backgroundColor: "darkButton.light",
              width: "30px",
              height: "30px",
              borderRadius: "12px !important",
            }}
            onClick={onClose}
          >
            <img src={ArrowRightAlt} />
          </IconButton>
        )}
        <IconButton
          onClick={onClose}
          disableRipple
          sx={{
            backgroundColor: "button.dark",
            width: "30px",
            height: "30px",
            borderRadius: "12px !important",
          }}
        >
          <Close sx={{ color: "darkButton.light" }} />
        </IconButton>
      </Box>
      {content}
    </Drawer>
  );
};

export default DrawerRight;
