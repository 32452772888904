import React, { useEffect } from "react";
import { CustomLoader, TableContainer } from "components/atoms";
import { Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { GetProfessionalUserAllShifts } from "store/middlewares/users/schedule";
import { ScheduleActionbar } from "components/molecules";
import dayjs from "dayjs";

const UserShiftTab = ({ id }) => {
  const { schedules, schedulesLoading } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetProfessionalUserAllShifts({ id }));
  }, [dispatch, id]);


  const colorTypeObj = {
    upcoming: `bg-orange-100 text-orange-500`,
    inProgress: `bg-yellow-100 text-yellow-500`,
    completed: `bg-green-100 text-green-500`,
    cancelled: `bg-red-100 text-red-500`,
  }

  const columns = React.useMemo(
    () => [
      {
        header: "No.",
        cell: ({ row }) => <Typography variant="p">{row.index + 1}</Typography>,
      },
      {
        header: "Hospital Name",
        accessorKey: "name",
        enableColumnFilter: false,
        enableSorting: true,
        cell: ({ row }) => (
          <p className="capitalize">
            {row.original?.JobTimeSchedule?.[0]?.jobPost?.users?.name}
          </p>
        ),
      },
      {
        header: "Shift Date",
        accessorKey: "startDate",
        enableColumnFilter: false,
        enableSorting: true,
        cell: ({ row }) => <p>{dayjs(row.original?.startDate).format("DD/MM/YYYY")}</p>,
      },
      {
        header: "End Date",
        accessorKey: "endDate",
        enableColumnFilter: false,
        enableSorting: true,
        cell: ({ row }) => <p>{dayjs(row.original?.endDate).format("DD/MM/YYYY")}</p>,
      },
      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
        enableSorting: true,
        cell: ({ row }) => (
          // <p className="capitalize">{row?.original?.status}</p>
        <span className={`px-2.5 py-0.5 capitalize inline-block text-xs font-medium rounded border 
          ${colorTypeObj[row?.original?.status] || 'bg-gray-200'} 
          border-transparent`}>{row?.original?.status}</span>
        ),
      },
      {
        id: "action",
        header: <p className="text-right pr-[12px]">Action</p>,
        enableColumnFilter: false,
        cell: ({ row }) => <ScheduleActionbar row={row.original} />,
      },
    ],
    []
  );
  return (
    <>
      {!schedulesLoading ? (
        <div className="card mt-5">
          <div className="card-body p-3">
            <TableContainer
              isPagination={true}
              isSelect={true}
              isGlobalFilter={true}
              columns={columns}
              data={schedules}
              customPageSize={10}
              divclassName="my-2 col-span-12 overflow-x-auto lg:col-span-12"
              tableclassName="hover group dataTable w-full text-sm align-middle whitespace-nowrap no-footer"
              theadclassName="border-b border-slate-200 "
              trclassName="group-[.stripe]:even:bg-slate-50 group-[.stripe]:dark:even:bg-zink-600 transition-all duration-150 ease-linear group-[.hover]:hover:bg-slate-50 [&.selected]:bg-custom-500  [&.selected]:text-custom-50 "
              thclassName="p-3 group-[.bordered]:border group-[.bordered]:border-slate-200 sorting px-3 py-4 text-slate-900 bg-slate-200/50 font-semibold text-left"
              tdclassName="p-3 group-[.bordered]:border group-[.bordered]:border-slate-200 max-w-[200px] overflow-hidden text-wrap overflow-break-word"
              PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
            />
          </div>
        </div>
      ) : (
        <CustomLoader />
      )}
    </>
  );
};

export default UserShiftTab;
