import { MuiBox, MuiTypography } from "components/atoms";
import React from "react";
import { Link } from "react-router-dom";

import Professional from "assets/images/PNG/professional2.png";
import Beg from "assets/images/SVG/beg.svg";
import Star from "assets/images/SVG/Star.svg";
import Calender from "assets/images/SVG/Calendar black.svg";
import Clock from "assets/images/SVG/clock 2.svg";
import Clock2 from "assets/images/SVG/clockShift.svg";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

// Enable the custom parse format plugin
dayjs.extend(customParseFormat);

const ScheduleCard = ({ type, role, schedule }) => {
  const redirectUrl = () => {
    let redirectUrlString = [
      "upcoming",
      "inprogress",
      "completed",
      "cancelled",
    ].includes(type)
      ? `/${role}/schedule/${type}/schedule-details/${
          ["inprogress", "completed"].includes(type)
            ? schedule?.dateScheduleId
            : schedule?.id
        }`
      : `/${role}/schedule`;
    // Fallback to a default route if `type` is invalid
    return redirectUrlString;
  };

  let user = null;

  if (role === "professional") {
    user = schedule?.hospitalTimeSchedule;
  } else {
    user = schedule?.professionalTimeSchedule;
  }

  return (
    <MuiBox
      component={Link}
      to={redirectUrl()}
      sx={{
        background: "#fff",
        borderRadius: "12px",
        p: { xs: 1, sm: 2 },
        display: "flex",
        flexDirection: "column",
        gap: "12px",
      }}
    >
      <MuiBox sx={{ borderRadius: "9px", overflow: "hidden", height: "206px" }}>
        <img
          src={user?.profileImageUrl ? user?.profileImageUrl : Professional}
          alt={
            role === "professional"
              ? user?.name
              : `${user?.name} ${user?.lastName}`
          }
          style={{
            height: "100%",
            width: "100%",
            objectFit: "cover",
            objectPosition: "center",
          }}
        />
      </MuiBox>
      <MuiBox>
        <MuiTypography
          color="text.main"
          variant="subtitle1"
          fontWeight="bold"
          sx={{ overflow: "hidden", whiteSpace: "nowrap" }}
        >
          {role === "professional"
            ? user?.name
            : `${user?.name} ${user?.lastName} `}
        </MuiTypography>
        <MuiTypography
          color="text.contrastText"
          sx={{ fontSize: "14px", overflow: "hidden", whiteSpace: "nowrap" }}
        >
          {user?.jobTitle}
        </MuiTypography>
      </MuiBox>
      <MuiBox
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 1,
          flexWrap: "wrap",
        }}
      >
        {schedule?.userType === "hospital" && (
          <MuiBox
            sx={{
              width: "fit-content",
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <MuiBox
              sx={{
                display: "flex",
                width: "fit-content",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <MuiBox
                sx={{
                  width: "26px",
                  height: "26px",
                  borderRadius: "6px",
                  bgcolor: "#E8F0F3",
                  p: "5px",
                }}
              >
                <img
                  src={Beg}
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                />
              </MuiBox>
              <MuiTypography
                color="text.main"
                fontWeight="bold"
                sx={{ fontSize: "14px" }}
              >
                {user?.totalYearsOfExperience} years
              </MuiTypography>
            </MuiBox>
            <MuiBox
              sx={{
                display: "flex",
                width: "fit-content",
                alignItems: "center",
                gap: "5px",
                bgcolor: "#FFF7E7",
                borderRadius: "6px",
                p: "5px",
              }}
            >
              <MuiBox sx={{ width: "18px", height: "18px" }}>
                <img
                  src={Star}
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                />
              </MuiBox>
              <MuiTypography
                color="text.contrastText"
                sx={{ fontSize: "14px" }}
              >
                {user?.averageRating} ({user?.reviewCount})
              </MuiTypography>
            </MuiBox>
          </MuiBox>
        )}
        <MuiBox
          sx={{
            width: "fit-content",
            bgcolor: "#E8F0F3",
            borderRadius: "6px",
            p: "5px 10px",
          }}
        >
          <MuiTypography
            color="text.dark"
            sx={{ fontSize: "14px" }}
            fontWeight="bold"
          >
            {schedule?.shiftId}
          </MuiTypography>
        </MuiBox>
      </MuiBox>

      <MuiBox
        sx={{
          bgcolor: "#CCEEFA",
          borderRadius: "12px",
          p: "8px 16px",
          display: "flex",
          flexDirection: "column",
          gap: "7px",
        }}
      >
        <MuiBox
          sx={{ display: "flex", gap: 1, justifyContent: "space-between" }}
        >
          <MuiBox sx={{ width: "fit-content", display: "flex", gap: 1 }}>
            <img
              src={Calender}
              style={{ height: "20px", width: "24px", objectFit: "contain" }}
            />
            <MuiTypography fontSize="14px" color="text.main">
              {dayjs(schedule?.scheduleCurrentDate).format("DD/MM/YYYY")}
            </MuiTypography>
          </MuiBox>

          {schedule?.status === "inProgress" && (
            <MuiBox
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
                bgcolor: "#fff",
                width: "fit-content",
                p: "5px 10px",
                borderRadius: "6px",
              }}
            >
              <img
                src={Clock2}
                style={{ height: "20px", width: "24px", objectFit: "contain" }}
              />
              <MuiTypography
                color="#07B67C"
                fontSize="12px"
                sx={{ textWrap: "nowrap" }}
              >
                {user?.isClockOut ? "Clocked Out" : user?.isClockIn ? "Clocked In" : "Clock In"}
              </MuiTypography>
            </MuiBox>
          )}
          {schedule?.status === "completed" && (
            <MuiBox
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
                bgcolor: "#07B67C",
                width: "fit-content",
                p: "5px 10px",
                borderRadius: "6px",
              }}
            >
              <MuiTypography
                color="#fff"
                fontSize="12px"
                sx={{ textWrap: "nowrap" }}
              >
                Completed
              </MuiTypography>
            </MuiBox>
          )}
          {schedule?.status === "cancelled" && (
            <MuiBox
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
                bgcolor: "#FF575A",
                width: "fit-content",
                p: "5px 10px",
                borderRadius: "6px",
              }}
            >
              <MuiTypography
                color="#fff"
                fontSize="12px"
                sx={{ textWrap: "nowrap" }}
              >
                Cancelled
              </MuiTypography>
            </MuiBox>
          )}
        </MuiBox>
        <MuiBox sx={{ width: "fit-content", display: "flex", gap: 1 }}>
          <img
            src={Clock}
            style={{ height: "20px", width: "24px", objectFit: "contain" }}
          />
          <MuiTypography fontSize="14px" color="text.main">
            {dayjs(schedule?.startTime).format("hh:mm A")} -{" "}
            {dayjs(schedule?.endTime).format("hh:mm A")}
          </MuiTypography>
        </MuiBox>
      </MuiBox>
    </MuiBox>
  );
};

export default ScheduleCard;
