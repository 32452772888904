import { MuiBox, MuiTypography } from "components/atoms";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import { formatTimeAMPM } from "utils/formatTime";
import { useDispatch } from "react-redux";
import { ApplyHospitalJobAction } from "store/middlewares/users/professionals";

import DollarBlue from "assets/images/SVG/DollarBlue.svg";
import Target from "assets/images/SVG/target.svg";
import shiftTypeObj from "utils/shiftTypeObj";
import { applicationStatusColor, statusText } from "utils/jobApplicationStatus";

const MyJobsCardVerticle = ({ job }) => {
  const dispatch = useDispatch();


  // For job applied
  const [applied, setApplied] = useState(false);
  const handleApplyClick = (e) => {
    e.preventDefault();
    if (!applied) {
      dispatch(ApplyHospitalJobAction({ jobId: job?.id }));
    }
  };
  useEffect(() => {
    if (job?.isApplied) {
      setApplied(true);
    } else {
      setApplied(false);
    }
  }, [job]);

  return (
    <MuiBox
      component={Link}
      to={`/professional/application-details/${job?.id}`}
      sx={{
        borderRadius: "12px",
        bgcolor: "#fff",
        p: { xs: "8px", sm: "12px" },
        display: "flex",
        flexDirection: "column",
        gap: "12px",
        borderTop: "2px solid #0CA8DF",
        height: "100%",
      }}
    >
      <MuiTypography variant="h6" color="text.main">
        {job?.JobPostDetails?.shiftTitle}
      </MuiTypography>
      <MuiBox
        sx={{
          maxWidth: "11em",
          display: "flex",
          gap: "5px",
          mt: 1,
          alignItems: "center",
        }}
      >
        <img
          src={DollarBlue}
          style={{ height: "24px", width: "24px", objectFit: "contain" }}
        />
        <MuiTypography color="text.main" variant="baseFont">
          {job?.JobPostDetails?.employeeHourRate.toFixed(2)}/hr
        </MuiTypography>
      </MuiBox>
      <MuiBox
        sx={{
          width: "fit-content",
          display: "flex",
          alignItems: "center",
          gap: "5px",
        }}
      >
        <img src={Target} />
        <MuiBox>
          <MuiTypography color="text.main" variant="baseFont">
            {formatTimeAMPM(
              job?.JobDateSchedule?.[0]?.JobTimeSchedule?.[0]?.startTime,
              false
            )}{" "}
            -{" "}
            {formatTimeAMPM(
              job?.JobDateSchedule?.[0]?.JobTimeSchedule?.[0]?.endTime,
              false
            )}
          </MuiTypography>
        </MuiBox>
      </MuiBox>
      <MuiBox
        sx={{
          width: "fit-content",
          display: "flex",
          alignItems: "center",
          gap: 1,
          flexWrap: "wrap",
        }}
      >
        {job?.jobSkills?.map((item) => {
          return (
            <MuiBox
              sx={{
                width: "fit-content",
                p: "5px 12px",
                bgcolor: "#007AFF26",
                borderRadius: "30px",
              }}
              key={item?.id}
            >
              <MuiTypography
                color="#2D2635"
                fontSize="15px"
                sx={{ whiteSpace: "nowrap" }}
              >
                {item?.name}
              </MuiTypography>
            </MuiBox>
          );
        })}
        <MuiBox
          sx={{
            width: "fit-content",
            p: "5px 12px",
            bgcolor: "#007AFF26",
            borderRadius: "30px",
          }}
        >
          <MuiTypography
            color="#2D2635"
            fontSize="15px"
            sx={{ whiteSpace: "nowrap" }}
          >
            {shiftTypeObj[job?.JobPostDetails?.shiftType]}
          </MuiTypography>
        </MuiBox>
      </MuiBox>
      <Button
        variant={"lightButton"}
        sx={{
          width: "fit-content",
          p: "5px 12px",
          mt: "auto",
          bgcolor: applicationStatusColor[job?.type],
          "&:hover": {
            bgcolor: applicationStatusColor[job?.type],
          },
        }}
        onClick={handleApplyClick}
      >
        {statusText[job?.type]}
      </Button>
    </MuiBox>
  );
};

export default MyJobsCardVerticle;
