import * as Yup from "yup";
import { onlySpaceRegExp, startsWithSpaceRegExp } from "utils/regex";

export const Name = Yup.string()
  .required("Please enter hospital name")
  .matches(startsWithSpaceRegExp, "Name cannot start with a space")
  .matches(onlySpaceRegExp, "Hospital name should have atleast one character");

export const Role = Yup.string()
  .required("Please enter role")
  .matches(onlySpaceRegExp, "Roles cannot start with a space")
  .matches(
    startsWithSpaceRegExp,
    "Hospital name should have atleast one character"
  );

export const description = Yup.string()
  .nullable()
  .matches(startsWithSpaceRegExp, "Description cannot start with a space")
  .matches(onlySpaceRegExp, "Description should have atleast one character");

export const startDate = Yup.string().required("Please enter start date");
export const endDate = Yup.string().required("Please enter end date");
