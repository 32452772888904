import React, { Fragment, useEffect, useState } from "react";
import { WebInput, MuiBox, MuiTypography, SearchInput } from "components/atoms";
import blankImg from "assets/images/blank.png";
import { Link, NavLink, Outlet } from "react-router-dom";
import {
  Badge,
  IconButton,
  Drawer,
  Toolbar,
  AppBar,
  Divider,
  Button,
  Popover,
  Tooltip,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Close } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { GetMeForWebAction } from "store/middlewares/me";
import { GetNotificationSettings } from "store/middlewares/notifications";
import { ChevronDown, ChevronUp, LogOut, UserRound } from "lucide-react";
import { openDrawer } from "store/slices/common/drawer.slice";
import ConfirmModal from "../ConfirmModal";

import CloseIcon from "@mui/icons-material/Close";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import ModeCommentIcon from "@mui/icons-material/ModeComment";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import WorkIcon from "@mui/icons-material/Work";
import HomeIcon from "@mui/icons-material/Home";
import MenuIcon from "@mui/icons-material/Menu";
import AboutCareLogo from "assets/images/PNG/aboutcare-logo.png";
import AboutCareLogoWhite from "assets/images/PNG/aboutcare-logo-white.png";
import AboutcareSmall from "assets/images/PNG/aboutcare.png";
import BellIcon from "assets/images/SVG/bellicon.svg";
import ProfileIcon from "assets/images/PNG/profile.png";
import SearchIcon from "assets/images/SVG/SearchIconBlack.svg";
import { GetWebSettingsAction } from "store/middlewares/settings/settings";
import { GetWebAllDynamicMenu } from "store/middlewares/dynamicMenu";
import NotificationPopover from "../NotificationPopover";

const footerLinks = [
  { path: "about", name: "About" },
  { path: "careers", name: "Careers" },
  { path: "customercare", name: "Customer Care" },
  { path: "services", name: "Services" },
];

const privacyLinks = [
  { name: "Terms & Conditions", path: "/terms" },
  { name: "Privacy Policy", path: "/privacy" },
];

const WebLayout = () => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const { me } = useSelector((state) => state.me);

  useEffect(() => {
    dispatch(GetMeForWebAction());
    dispatch(GetNotificationSettings());
    dispatch(GetWebSettingsAction());
    dispatch(GetWebAllDynamicMenu());
  }, []);

  // for dynamic menu
  const { dynamicMenu } = useSelector((state) => state.dynamicMenu);
  const isHospitalJobVisible = dynamicMenu.find(
    (item) => item.name === "hospital_job"
  )?.isVisible;
  const isHospitalMessage = dynamicMenu.find(
    (item) => item.name === "message"
  )?.isVisible;
  // for dynamic menu end

  const typeProfessional = me?.role === "professional";
  const navLinks = typeProfessional
    ? [
      { path: "home", name: "Home", icon: <HomeIcon /> },
      { path: "direct-hire", name: "Direct Hire", icon: <WorkIcon /> },
      {
        path: "credentials",
        name: "Credentials",
        icon: <EventAvailableIcon />,
      },
      { path: "schedule", name: "Schedule", icon: <ModeCommentIcon /> },
      isHospitalMessage && {
        path: "message",
        name: "Message",
        icon: <AccountBalanceWalletIcon />,
      },
    ].filter(Boolean)
    : [
      { path: "home", name: "Home", icon: <HomeIcon /> },
      isHospitalJobVisible && {
        path: "jobs",
        name: "Jobs",
        icon: <WorkIcon />,
      },
      { path: "schedule", name: "Schedule", icon: <EventAvailableIcon /> },
      isHospitalMessage && {
        path: "message",
        name: "Message",
        icon: <ModeCommentIcon />,
      },
      {
        path: "revenue",
        name: "Revenue",
        icon: <AccountBalanceWalletIcon />,
      },
    ].filter(Boolean);
  const isMdDown = useMediaQuery(
    typeProfessional ? "(max-width:986px)" : "(max-width:920px)"
  );

  // For sidebar drawer
  const [drawerOpen, setDrawerOpen] = useState(false);
  const toggleDrawer = () => {
    if (anchorElProfile) {
      handleCloseProfile();
    }
    if (anchorEl) {
      handleClose();
    }
    setDrawerOpen(true);
  };
  const toggleDrawerClose = () => {
    setDrawerOpen(false);
  };

  // For search drawer
  const [searchDrawerOpen, setSearchDrawerOpen] = useState(false);
  const toggleSearchDrawer = () => {
    setSearchDrawerOpen(!searchDrawerOpen);
  };

  // For notification popover
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  // For profile popover
  const [anchorElProfile, setAnchorElProfile] = useState(null);
  const handleClickProfile = (event) => {
    setAnchorElProfile(event.currentTarget);
  };
  const handleCloseProfile = () => {
    setAnchorElProfile(null);
  };
  const openProfile = Boolean(anchorElProfile);

  return (
    <MuiBox sx={{ backgroundColor: "background.main" }}>
      {/* HEADER ----------Start */}
      <AppBar
        position="static"
        sx={{
          backgroundColor: "#ffff",
          height: "75px",
          boxShadow: "none",
          overflow: "hidden",
          position: "relative",
          zIndex: anchorEl || anchorElProfile ? "9999999" : "0",
        }}
      >
        <Toolbar
          sx={{
            width: "100%",
            maxWidth: "1400px",
            height: "100%",
            margin: "0 auto",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 2,
          }}
        >
          {isMdDown ? (
            <MuiBox sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => {
                  // dispatch(openAdminModal({
                  //     anchor: 'left', borderRadiusLeft: '0',
                  //     content: <NavbarMobileModal />
                  // }))
                  toggleDrawer();
                }}
              >
                <MenuIcon sx={{ color: "black" }} />
              </IconButton>
              <MuiBox sx={{ maxWidth: "60px" }}>
                <img src={AboutcareSmall} />
              </MuiBox>
            </MuiBox>
          ) : (
            <>
              <MuiBox sx={{ maxWidth: "158px", flexShrink: 0 }}>
                <Link to="home">
                  <img
                    src={AboutCareLogo}
                    alt="about care"
                    className="img-contain"
                  />
                </Link>
              </MuiBox>
              <nav
                className="navbar-container"
                style={{ maxWidth: typeProfessional ? "460px" : "410px" }}
              >
                <ul className="navbar">
                  {navLinks.map((item) => (
                    <li key={item.path} className="navbar-item">
                      <NavLink
                        to={item.path}
                        onClick={() => {
                          if (anchorEl) {
                            handleClose();
                          }
                          if (anchorElProfile) {
                            handleCloseProfile();
                          }
                        }}
                        className={({ isActive }) =>
                          `navbar-link ${isActive ? "active" : ""}`
                        }
                      >
                        <MuiTypography
                          variant="baseFont"
                          sx={{ textWrap: "nowrap" }}
                        >
                          {item.name}
                        </MuiTypography>
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </nav>
            </>
          )}

          <MuiBox
            sx={{
              maxWidth: "488px",
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              gap: 2,
            }}
          >
            {isMdDown ? (
              <IconButton
                onClick={toggleSearchDrawer}
                aria-label="search"
                size="large"
                sx={{
                  width: "40px",
                  height: "40px",
                  bgcolor: "#E8F0F3",
                  borderRadius: "12px",
                  p: "9px",
                }}
              >
                <img src={SearchIcon} alt="search" />
              </IconButton>
            ) : (
              <MuiBox sx={{ maxWidth: "377px" }}>
                <SearchInput bgColor="#E8F0F3" />
              </MuiBox>
            )}
            <Tooltip title="Notification">
              <IconButton
                aria-label="notification"
                size="large"
                onClick={handleClick}
                sx={{
                  width: "40px",
                  height: "40px",
                  bgcolor: "#E8F0F3",
                  borderRadius: "12px",
                }}
              >
                <Badge color="info" variant="dot" badgeContent=" ">
                  <img src={BellIcon} alt="notification" />
                </Badge>
              </IconButton>
            </Tooltip>
            <Tooltip title="Profile">
              <IconButton
                aria-label="profile"
                size="large"
                disableRipple
                onClick={
                  anchorElProfile ? handleCloseProfile : handleClickProfile
                }
                sx={{ padding: "0" }}
              >
                <MuiBox
                  sx={{
                    width: "40px",
                    height: "40px",
                    bgcolor: "#E8F0F3",
                    borderRadius: "12px",
                    p: 0,
                    overflow: "hidden",
                  }}
                >
                  <MuiBox sx={{ height: "100%" }}>
                    <img
                      src={
                        me?.profileImageUrl ? me?.profileImageUrl : ProfileIcon
                      }
                      alt="profile"
                      className="img-cover"
                    />
                  </MuiBox>
                </MuiBox>
                {anchorElProfile ? (
                  <ChevronUp style={{ marginLeft: "8px" }} />
                ) : (
                  <ChevronDown style={{ marginLeft: "8px" }} />
                )}
              </IconButton>
            </Tooltip>
          </MuiBox>
        </Toolbar>
      </AppBar>
      {/* NAVBAR DRAWER MENU -----Start */}
      {isMdDown ? (
        <>
          {drawerOpen ? (
            <Drawer
              anchor="left"
              open={drawerOpen}
              className="navbar-drawer"
              onClose={toggleDrawerClose}
            >
              <MuiBox>
                <MuiBox sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <IconButton
                    onClick={toggleDrawerClose}
                    sx={{
                      backgroundColor: "button.dark",
                      width: "30px",
                      height: "30px",
                      borderRadius: "12px !important",
                      margin: "10px",
                    }}
                  >
                    <Close sx={{ color: "darkButton.light" }} />
                  </IconButton>
                </MuiBox>
                <MuiBox
                  sx={{ maxWidth: "200px", m: "0 auto", p: "0 10px 16px 10px" }}
                >
                  <img
                    src={AboutCareLogo}
                    alt="about care"
                    className="img-contain"
                  />
                </MuiBox>
                <Divider />
                <List>
                  {navLinks.map((item, index) => (
                    <ListItem key={index} disablePadding>
                      <ListItemButton
                        component={NavLink}
                        to={item.path}
                        onClick={toggleDrawerClose}
                        className="navbar-link"
                      >
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.name} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </MuiBox>
            </Drawer>
          ) : null}
        </>
      ) : null}
      {/* NAVBAR DRAWER MENU -----End */}

      {/* SEARCH DRAWER -----Start */}
      {isMdDown ? (
        <Drawer
          anchor="top"
          open={searchDrawerOpen}
          onClose={toggleSearchDrawer}
        >
          <MuiBox
            sx={{
              p: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <SearchInput bgColor="#E8F0F3" autoFocus={true} />
            <IconButton onClick={toggleSearchDrawer}>
              <CloseIcon />
            </IconButton>
          </MuiBox>
        </Drawer>
      ) : null}
      {/* SEARCH DRAWER -----End */}

      {/* NOTIFICATION -----Start */}
      <NotificationPopover anchorEl={anchorEl} handleClose={handleClose} open={open} hospitalId={me?.id} />
      {/* NOTIFICATION -----End */}

      {/* PROFILE -----Start */}
      <Popover
        className="profile-dropdown"
        open={openProfile}
        anchorEl={anchorElProfile}
        onClose={handleCloseProfile}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MuiBox
          sx={{
            bgcolor: "#E8F0F3",
            p: { xs: "15px 0", sm: "28px 0" },
            borderBottomLeftRadius: "12px",
            borderBottomRightRadius: "12px",
          }}
        >
          <MuiBox sx={{ display: "flex", flexDirection: "column" }}>
            <MuiBox
              sx={{
                display: "flex",
                gap: "20px",
                p: { xs: "0 15px", sm: "0 20px" },
                marginBottom: "20px",
              }}
            >
              <MuiBox
                sx={{
                  width: "60px",
                  height: "60px",
                  borderRadius: "50%",
                  overflow: "hidden",
                  flexShrink: 0,
                }}
              >
                <img
                  src={me?.profileImageUrl ? me?.profileImageUrl : blankImg}
                  className="img-cover"
                />
              </MuiBox>
              <MuiBox
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flexShrink: 1,
                  maxWidth: "200px",
                }}
              >
                <MuiTypography
                  variant="h2"
                  lineHeight="40px"
                  sx={{ overflow: "hidden", whiteSpace: "nowrap" }}
                >
                  {me?.role === "hospital"
                    ? me?.name
                    : `${me?.name} ${me?.lastName}`}
                </MuiTypography>
                <MuiTypography
                  variant="baseFont"
                  color="sliderText.light"
                  lineHeight="20px"
                  sx={{ overflow: "hidden" }}
                >
                  {me?.email}
                </MuiTypography>
              </MuiBox>
            </MuiBox>
            <ul>
              <li
                onClick={() => {
                  window.location = `/${me?.role}/profile`;
                  handleClose();
                }}
              >
                <MuiBox
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: "#000 !important",
                    gap: "20px",
                    p: { xs: "10px 15px", sm: "10px 28px" },
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "#fff",
                    },
                  }}
                >
                  <UserRound fontSize="14px" />
                  <MuiTypography variant="body2">Profile</MuiTypography>
                </MuiBox>
              </li>
              <li onClick={handleCloseProfile}>
                <MuiBox
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                    p: { xs: "10px 15px", sm: "10px 28px" },
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "#fff",
                    },
                  }}
                  onClick={() => {
                    dispatch(
                      openDrawer({
                        content: (
                          <ConfirmModal
                            type="logout"
                            title="Logout Account"
                            message={
                              <>
                                Are you sure you want to logout? <br />
                                Unsaved changes will be lost. <br />
                                You can log back in anytime.
                              </>
                            }
                            btnText={"Logout Account"}
                          />
                        ),
                      })
                    );

                    handleCloseProfile();
                  }}
                >
                  <LogOut fontSize="14px" />
                  <MuiTypography variant="body2">Log Out</MuiTypography>
                </MuiBox>
              </li>
            </ul>
          </MuiBox>
        </MuiBox>
      </Popover>
      {/* PROFILE -----End */}
      {/* HEADER ----------End */}

      {/* MAIN CONTENT -----Start */}
      <MuiBox component="main" sx={{ backgroundColor: "background.main" }}>
        <MuiBox
          sx={{
            minHeight: "calc(100dvh - 75px)",
            maxWidth: "1424px",
            margin: "0 auto",
            p: {
              xs: "8px 8px 60px 8px",
              sm: "16px 16px 60px 16px",
              md: "24px 24px 60px 24px",
            },
          }}
        >
          <Outlet />
        </MuiBox>
      </MuiBox>
      {/* MAIN CONTENT -----End */}

      {/* FOOTER -----Start */}
      <MuiBox
        component="footer"
        sx={{
          backgroundColor: "button.dark",
          borderTopLeftRadius: "50px",
          borderTopRightRadius: "50px",
        }}
      >
        <MuiBox
          sx={{
            maxWidth: "1400px",
            margin: "0 auto",
            p: { xs: "12px", sm: "24px" },
          }}
        >
          <MuiBox sx={{ maxWidth: "158px", m: { xs: "0 auto", md: "0" } }}>
            <img
              src={AboutCareLogoWhite}
              alt="about care"
              className="img-contain"
            />
          </MuiBox>
          <MuiBox
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: { xs: "column", sm: "column", md: "row" },
              justifyContent: { sm: "center", md: "space-between" },
              gap: 4,
              pt: { xs: 2, sm: 4, md: 1 },
              pb: 2,
            }}
          >
            <MuiBox className="footer-links-list">
              <ul>
                {footerLinks.map((item, index) => (
                  <li key={index}>
                    <Link to={item.path}>
                      <MuiTypography
                        variant="baseFont"
                        sx={{ color: "text.light" }}
                      >
                        {item.name}
                      </MuiTypography>
                    </Link>
                  </li>
                ))}
              </ul>
            </MuiBox>
            <MuiBox
              sx={{
                maxWidth: "400px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <MuiTypography variant="baseFont" sx={{ color: "text.light" }}>
                Get the latest news from us
              </MuiTypography>
              <form autoComplete="off" className="footer-form">
                <WebInput
                  className="footer-input"
                  placeholder="Your email address"
                  required={false}
                />
                <Button
                  variant="contained"
                  sx={{
                    mt: "8px",
                    borderRadius: "12px",
                    bgcolor: "button.main",
                    px: "30px",
                  }}
                >
                  Subscribe
                </Button>
              </form>
            </MuiBox>
          </MuiBox>
          <Divider sx={{ bgcolor: "#71777D" }} />
          <MuiBox
            className="privacy-links"
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: { xs: "column", sm: "column", md: "row" },
              justifyContent: { sm: "center", md: "space-between" },
              gap: { xs: 2, sm: 4 },
              pt: { xs: 2, sm: 4 },
            }}
          >
            <ul>
              {privacyLinks.map((link, index) => (
                <Fragment key={index}>
                  <li>
                    <Link to={`/${me?.role}${link.path}`}>
                      <MuiTypography
                        variant="baseFont"
                        sx={{ color: "text.light" }}
                      >
                        {link.name}
                      </MuiTypography>
                    </Link>
                  </li>
                  {index < privacyLinks.length - 1 && (
                    <Divider
                      sx={{ bgcolor: "background.main" }}
                      orientation="vertical"
                      flexItem
                    />
                  )}
                </Fragment>
              ))}
            </ul>

            <MuiTypography color="#92989F" sx={{ textAlign: "center" }}>
              Design with love © Wedefineapps 2024. All right reserved
            </MuiTypography>
          </MuiBox>
        </MuiBox>
      </MuiBox>
      {/* FOOTER -----End */}
    </MuiBox>
  );
};

export default WebLayout;
