import React, { useContext } from "react";
import * as Yup from "yup";
// import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { DateInput, SelectComponent, SubmitBtn } from "components/atoms";
import { FilterPopoverContext } from "context/PopoverContext";
import { useDispatch, useSelector } from "react-redux";
import { setBannersFilterProps } from "store/slices/admin/banner";

const BannerFilter = () => {
  const dispatch = useDispatch();
  const { handleClose } = useContext(FilterPopoverContext); 
   const { bannersFilterProps } = useSelector((state) => state.banners);
  const intitalVal = {
    isVisible: "",
    startDate: "",
    endDate: "",
    platform: "",
    fileType: "",
  };

  const visiblityOptions = [
    { label: "On", value: "true" },
    { label: "Off", value: "false" },
  ];
  const fileTypeOptions = [
    { label: "Image", value: "image" },
    { label: "Video", value: "video" },
  ];
  const platformOptions = [
    { label: "Web", value: "Web" },
    { label: "App", value: "App" },
  ];

  const BannerFilterSchema = Yup.object().shape({
    isVisible: Yup.string().nullable(),
    fileType: Yup.string().nullable(),
    platform: Yup.string().nullable(),
  });

  const handleSubmit = (values) => {
    dispatch(
      setBannersFilterProps({
        isVisible: values.isVisible === "" ? null : values.isVisible,
        endDate: values.endDate === "" ? null : values.endDate,
        startDate:values.startDate === "" ? null : values.startDate,
        platform: values.platform === "" ? null : values.platform,
        fileType: values.fileType === "" ? null : values.fileType,
      })
    );
  };

  const formik = useFormik({
    initialValues: {...intitalVal,...bannersFilterProps},
    validationSchema: BannerFilterSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
      onReset={() => {
        dispatch(setBannersFilterProps(null));
      }}
    >
      <div className="p-4 flex flex-col gap-2">
        <p className="font-semibold">Filter </p>
        <SelectComponent
          selectclassName={"mt-2"}
          className={"px-0"}
          label={"By Visiblity"}
          isRequired={false}
          options={visiblityOptions}
          placeholder="Select Visiblity"
          name={"isVisible"}
          value={formik.values.isVisible}
          error={formik.errors.isVisible && formik.touched.isVisible}
          errorMsg={formik.errors.isVisible}
          onChange={(selectedOption) => {
            formik.setFieldValue(
              "isVisible",
              selectedOption ? selectedOption.value : ""
            );
          }}
        />
        <SelectComponent
          selectclassName={"mt-2"}
          className={"px-0"}
          label={"Platform"}
          isRequired={false}
          options={platformOptions}
          placeholder="Select Visiblity"
          name={"platform"}
          value={formik.values.platform}
          error={formik.errors.platform && formik.touched.platform}
          errorMsg={formik.errors.platform}
          onChange={(selectedOption) => {
            formik.setFieldValue(
              "platform",
              selectedOption ? selectedOption.value : ""
            );
          }}
        />
        <SelectComponent
          selectclassName={"mt-2"}
          className={"px-0"}
          label={"FileType"}
          isRequired={false}
          options={fileTypeOptions}
          placeholder="Select Visiblity"
          name={"fileType"}
          value={formik.values.fileType}
          error={formik.errors.fileType && formik.touched.fileType}
          errorMsg={formik.errors.fileType}
          onChange={(selectedOption) => {
            formik.setFieldValue(
              "fileType",
              selectedOption ? selectedOption.value : ""
            );
          }}
        />
         <DateInput
          inputClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 mt-2"
          label={"Start Date"}
          type="date"
          isRequired={false}
          name="startDate"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          max={formik.values.endDate} 
          error={formik.errors.startDate && formik.touched.startDate}
          errorMsg={formik.errors.startDate}
          value={formik.values.startDate}
          placeholder="Enter Start Date"
        />
        <DateInput
          inputClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 mt-2"
          label={"End Date"}
          type="date"
          isRequired={false}
          name="endDate"
          min={formik.values.startDate}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.endDate && formik.touched.endDate}
          errorMsg={formik.errors.endDate}
          value={formik.values.endDate}
          placeholder="Enter Start Date"
        />
        
        <div className="flex self-end justify-between w-full">
          <SubmitBtn type="reset" title={"Reset"} buttonClassName={"!ml-0"} />
          <SubmitBtn title={"Apply"} buttonClassName={"!ml-0"} />
          <SubmitBtn
          onClickFunc={() => handleClose()}
            type={"button"}
            title={"Cancel"}
            buttonClassName={"!ml-0"}
          />
        </div>
      </div>
    </form>
  );
};

export default BannerFilter;
