import React from "react";
import {
  Award,
  BadgeDollarSign,
  ClipboardList,
  Component,
  DatabaseBackup,
  Globe,
  Hospital,
  House,
  LayoutList,
  LifeBuoy,
  NotebookText,
  Settings,
  Users,
} from "lucide-react";
const menuData = [
  {
    id: "dashboard",
    label: "Dashboard",
    link: "/admin/dashboard",
    icon: <House />,
    action: "read",
    title: "dashboard",
  },
  {
    id: "hospital",
    label: "Hospital",
    link: "/#",
    icon: <Hospital />,
    action: "read",
    subItems: [
      {
        id: "active",
        label: "Active",
        link: "/admin/hospital/active",
        parentId: "hospital",
        isStatic: true,
      },
      {
        id: "inactive",
        label: "Inactive",
        link: "/admin/hospital/inactive",
        parentId: "hospital",
        isStatic: true,
      },
      {
        id: "deleted",
        label: "Deleted",
        link: "/admin/hospital/deleted",
        parentId: "hospital",
        isStatic: true,
      },
    ],
  },
  {
    id: "professional",
    label: "Professional",
    link: "/#",
    icon: <Users />,
    action: "read",
    subItems: [
      {
        id: "pending",
        label: "Pending",
        link: "/admin/professional/pending",
        parentId: "professional",
        isStatic: true,
      },
      {
        id: "active",
        label: "Active",
        link: "/admin/professional/active",
        parentId: "professional",
        isStatic: true,
      },
      {
        id: "inactive",
        label: "Inactive",
        link: "/admin/professional/inactive",
        parentId: "professional",
        isStatic: true,
      },
      {
        id: "deleted",
        label: "Deleted",
        link: "/admin/professional/deleted",
        parentId: "professional",
        isStatic: true,
      },
      {
        id: "request_badges",
        label: "Request Badges",
        link: "/admin/professional/request-badges",
        parentId: "professional",
        isStatic: true,
      },
    ],
  },
  {
    id: "data_management",
    label: "Data Management",
    action: "read",
    icon: <DatabaseBackup />,
    subItems: [
      {
        id: "job_title",
        label: "Job Title",
        link: "/admin/job-title",
        parentId: "data_management",
        action: "read",
      },
      {
        id: "specialities",
        label: "Specialities",
        link: "/admin/specialities",
        parentId: "data_management",
        action: "read",
      },
      {
        id: "facilities",
        label: "Facilities",
        link: "/admin/facilities",
        parentId: "data_management",
        action: "read",
      },
      {
        id: "skills",
        label: "Skills",
        link: "/admin/skills",
        parentId: "data_management",
        action: "read",
      },
      {
        id: "job_cancellation",
        label: "Job Cancellation",
        link: "/admin/job-cancellation",
        parentId: "data_management",
        action: "read",
      },
      {
        id: "incident_report",
        label: "Incident Report",
        link: "/admin/incident-report",
        parentId: "data_management",
        action: "read",
      },
      {
        id: "delete_reason",
        label: "Delete Reason",
        link: "/admin/delete-reason",
        parentId: "data_management",
        action: "read",
      },
      {
        id: "block_reason",
        label: "Block Reason",
        link: "/admin/block-reason",
        parentId: "data_management",
        action: "read",
      },
      {
        id: "reject_reason",
        label: "Reject Reason",
        link: "/admin/reject-reason",
        parentId: "data_management",
        action: "read",
      },
      {
        id: "payment_period",
        label: "Payment Period",
        link: "/admin/payment-period",
        parentId: "data_management",
        action: "read",
      },
      {
        id: "language",
        label: "Language",
        link: "/admin/language",
        parentId: "data_management",
        action: "read",
      },
      {
        id: "banner",
        label: "Banner",
        link: "/admin/banner",
        isStatic: true,
        parentId: "data_management",
      },
      {
        id: "tax",
        label: "Tax",
        link: "/admin/tax",
        parentId: "data_management",
        action: "read",
      },
      {
        id: "badges",
        label: "Badges",
        link: "/admin/badges",
        parentId: "data_management",
        action: "read",
      },
    ],
  },
  {
    id: "access_management",
    label: "Access Management",
    action: "read",
    icon: <Globe />,
    subItems: [
      {
        id: "roles",
        label: "Roles",
        link: "/admin/roles",
        parentId: "access_management",
        action: "read",
      },
      {
        id: "access",
        label: "Access",
        link: "/admin/access",
        parentId: "access_management",
        action: "read",
      },
      {
        id: "users",
        label: "Users",
        link: "/admin/users",
        parentId: "access_management",
        action: "read",
      },
    ],
  },
  {
    id: "subscription_management",
    label: "Subscription Management",
    action: "read",
    icon: <Component />,
    subItems: [
      {
        id: "plans",
        label: "Plans",
        link: "/admin/plans",
        parentId: "subscription_management",
        action: "read",
      },
      {
        id: "features",
        label: "Features",
        link: "/admin/features",
        parentId: "subscription_management",
        action: "read",
      },
      {
        id: "subscriptions",
        label: "Subscriptions",
        link: "/admin/subscriptions",
        parentId: "subscription_management",
        action: "read",
      },
    ],
  },
  {
    id: "help_and_support",
    label: "Help and Support",
    link: "/admin/help-and-support",
    action: "read",
    icon: <LifeBuoy />,
    subItems: [
      {
        id: "faq",
        label: "FAQ",
        link: "/admin/help-and-support/faq",
        parentId: "help_and_support",
        action: "read",
      },
      {
        id: "email",
        label: "Email",
        link: "/admin/help-and-support/email",
        parentId: "help_and_support",
        action: "read",
      },
      {
        id: "chat",
        label: "Chat",
        link: "/admin/help-and-support/chat",
        parentId: "helpAndsupport",
        action: "read",
      },
    ],
  },
  {
    id: "cms",
    label: "CMS",
    icon: <ClipboardList />,
    action: "read",
    subItems: [
      {
        id: "hospital-cms",
        label: "Hospital",
        parentId: "cms",
        isStatic: true,
        subItems: [
          {
            id: "terms",
            label: "Terms & Conditions",
            link: "/admin/cms/hospital/terms",
            parentId: "hospital-cms",
            isStatic: true,
          },
          {
            id: "privacy",
            label: "Privacy Policy",
            link: "/admin/cms/hospital/privacy",
            parentId: "hospital-cms",
            isStatic: true,
          },
        ],
      },
      {
        id: "professional-cms",
        label: "Professional",
        parentId: "cms",
        isStatic: true,
        subItems: [
          {
            id: "terms",
            label: "Terms & Conditions",
            link: "/admin/cms/professional/terms",
            parentId: "professional-cms",
            isStatic: true,
          },
          {
            id: "privacy",
            label: "Privacy Policy",
            link: "/admin/cms/professional/privacy",
            parentId: "professional-cms",
            isStatic: true,
          },
        ],
      },
      {
        id: "location_management",
        label: "Location Management",
        icon: <Globe />,
        parentId: "cms",
        isStatic: true,
        subItems: [
          {
            id: "countries",
            label: "Countries",
            link: "/admin/countries",
            parentId: "location_management",
            action: "read",
          },
          {
            id: "provinces",
            label: "Provinces",
            link: "/admin/provinces",
            parentId: "location_management",
            action: "read",
          },
          {
            id: "cities",
            label: "Cities",
            link: "/admin/cities",
            parentId: "location_management",
            action: "read",
          },
        ],
      },
    ],
  },
  {
    id: "dynamic_menu",
    label: "Dynamic Menu",
    link: "/admin/dynamic-menu",
    action: "read",
    icon: <LayoutList />,
  },
  {
    id: "jobs",
    label: "Jobs",
    link: "/admin/jobs",
    action: "read",
    icon: <NotebookText />,
  },
  {
    id: "credentials",
    label: "Credentials",
    link: "/admin/credentials",
    action: "read",
    icon: <Award />,
  },
  {
    id: "transactions",
    label: "Transactions",
    link: "/admin/transactions",
    action: "read",
    icon: <BadgeDollarSign />,
  },
  {
    id: "settings",
    label: "Settings",
    link: "/admin/settings",
    action: "read",
    icon: <Settings />,
  },
];

export { menuData };
