import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {
  CustomDatePicker,
  CustomTimePicker,
  MuiBox,
  MuiTypography,
} from "components/atoms";
import { Button, IconButton } from "@mui/material";
// import { DatePicker } from '@mui/x-date-pickers';

import PlusIcon from "assets/images/SVG/plus.svg";
import dayjs from "dayjs";
import * as Yup from "yup";
import { FieldArray, FormikProvider, useFormik } from "formik";
import { Trash2 } from "lucide-react";
import { closeDrawer } from "store/slices/common/drawer.slice";
import { useDispatch } from "react-redux";
import {
  endDate,
  endTime,
  startDate,
  startTime,
} from "utils/validation/web/JobForm";
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
const AddScheduleModal = ({ onClick, schedule, minStartDate }) => {

  // const [value, setValue] = React.useState(null);
  const dispatch = useDispatch();
  const [customErrors, setCustomErrors] = useState(false);

  const handleSubmit = async (values) => {
    let temp = values?.timeSlots?.map((item) => {
      return {
        startTime: dayjs(item?.startTime).utc().toISOString(),
        endTime: dayjs(item?.endTime).utc().toISOString()
      }
    })
    if (!customErrors) {
      dispatch(closeDrawer());
      onClick({
        ...values,
        timeSlots: temp,
      });
    }
  };


  const setMessage = (e) => {
    setCustomErrors(Boolean(e));
    let messages = "";
    if (e === "maxTime" || e === "minTime") {
      messages = "Invalid Date Range outer";
    } else {
      messages = "";
    }
    return messages;
  };

  const [intialVal, setIntialVal] = useState({
    startDate: "",
    endDate: "",
    timeSlots: [
      {
        startTime: "",
        endTime: "",
      },
    ],
  });

  const formik = useFormik({
    initialValues: intialVal,
    validationSchema: Yup.object().shape({
      startDate: startDate,
      endDate: endDate,
      timeSlots: Yup.array().of(
        Yup.object().shape({
          startTime: startTime,
          endTime: endTime,
        })
      ),
    }),
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (schedule) {
      setIntialVal({
        startDate: schedule?.startDate,
        endDate: schedule?.endDate,
        timeSlots: schedule?.timeSlots?.map((item) => {
          return {
            startTime: dayjs(item?.startTime),
            endTime: dayjs(item?.endTime),
          };
        }),
      });
    } else {
      setIntialVal({
        startDate: "",
        endDate: "",
        timeSlots: [
          {
            startTime: "",
            endTime: "",
          },
        ],
      });
    }
  }, []);


  return (
    <form onSubmit={formik.handleSubmit}>
      <MuiBox sx={{ px: { xs: "10px", sm: "20px", md: "30px" } }}>
        <MuiTypography variant="h6" fontWeight={"bold"} align={"center"}>
          {schedule ? "Edit Schedule" : "Add Schedule"}
        </MuiTypography>

        <MuiBox sx={{ mt: 5 }}>
          <MuiTypography variant="subtitle1" fontWeight={"bold"}>
            Choose Date
          </MuiTypography>
          <Grid container spacing={"15px"} sx={{ mt: "20px" }}>
            <Grid xs={6}>
              <CustomDatePicker
                label={"Start Date"}
                disablePast={schedule ? false : true}
                format="YYYY-MM-DD"
                maxDate={
                  formik?.values?.endDate
                    ? dayjs(formik?.values?.endDate)
                    : null
                }
                onChange={(e) => {
                  formik.setFieldValue(
                    `startDate`,
                    dayjs(e).format("YYYY-MM-DD")
                  );
                }}
                error={formik.touched.startDate && formik.errors.startDate}
                value={
                  formik?.values?.startDate
                    ? dayjs(formik?.values?.startDate, "YYYY-MM-DD")
                    : null
                }
                errorMsg={formik.errors.startDate}
                minDate={minStartDate ? dayjs(minStartDate) : null}
              />
            </Grid>
            <Grid xs={6}>
              <CustomDatePicker
                label={"End Date"}
                format="YYYY-MM-DD"
                disablePast={schedule ? false : true}
                minDate={
                  formik?.values?.startDate
                    ? dayjs(formik?.values?.startDate, "YYYY-MM-DD")
                    : minStartDate
                      ? dayjs(minStartDate)
                      : null
                }
                onChange={(e) => {
                  formik.setFieldValue(
                    "endDate",
                    dayjs(e).format("YYYY-MM-DD")
                  );
                }}
                value={
                  formik?.values?.endDate
                    ? dayjs(formik?.values?.endDate, "YYYY-MM-DD")
                    : null
                }
                error={formik.touched?.endDate && formik.errors?.endDate}
                errorMsg={formik.errors?.endDate}
              />
            </Grid>
          </Grid>
        </MuiBox>

        <MuiBox sx={{ mt: 5 }}>
          <FormikProvider value={formik}>
            <FieldArray name={"timeSlots"}>
              {({ push, remove }) => (
                <MuiBox>
                  <MuiBox
                    sx={{
                      display: "center",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <MuiTypography variant="subtitle1" fontWeight={"bold"}>
                      Choose Time
                    </MuiTypography>
                    <IconButton
                      className="plus-button-02 button-hover"
                      sx={{ p: "3px" }}
                      onClick={() => {
                        push({
                          startTime: "",
                          endTime: "",
                        });
                      }}
                    >
                      <img src={PlusIcon} alt="add" className="img-full" />
                    </IconButton>
                  </MuiBox>
                  {formik.values?.timeSlots?.map((_, index) => {
                    return (
                      <Grid
                        container
                        spacing={"15px"}
                        sx={{ mt: "20px" }}
                        key={index}
                      >
                        {formik.values?.timeSlots?.length > 1 && (
                          <MuiBox sx={{ position: "relative" }}>
                            <IconButton
                              onClick={() => remove(index)}
                              sx={{
                                position: "absolute",
                                right: 0,
                                top: 0,
                                color: "#000",
                              }}
                            >
                              <Trash2 />
                            </IconButton>
                          </MuiBox>
                        )}
                        <Grid item xs={6}>
                          <CustomTimePicker
                            label={"Start Time"}
                            name={`timeSlots.${index}.startTime`}
                            value={
                              formik.values?.timeSlots?.[index]?.startTime ? dayjs(formik.values.timeSlots[index].startTime)
                                : null
                            }
                            onChange={(e) => {
                              formik.setFieldValue(
                                `timeSlots.${index}.startTime`,
                                dayjs(e)
                              );
                              formik.setFieldTouched(
                                `timeSlots.${index}.startTime`,
                                true
                              );
                              setCustomErrors(false);
                            }}
                            onError={(e) => {
                              formik.setFieldError(
                                `timeSlots.${index}.startTime`,
                                setMessage(e)
                              );
                              formik.setFieldTouched(
                                `timeSlots.${index}.startTime`,
                                true
                              );
                            }}
                            error={
                              formik.errors.timeSlots?.[index]?.startTime &&
                              formik.touched.timeSlots?.[index]?.startTime
                            }
                            errorMsg={
                              formik.errors.timeSlots?.[index]?.startTime
                            }
                            minTime={
                              index === 0
                                ? null
                                : formik.values?.timeSlots?.[index - 1]?.endTime
                                  ? dayjs(
                                    formik.values.timeSlots[index - 1].endTime,
                                    "HH:mm"
                                  )
                                  : null
                            }
                            maxTime={
                              formik.values?.timeSlots?.[index]?.endTime
                                ? dayjs(
                                  formik.values.timeSlots[index].endTime,
                                  "HH:mm"
                                )
                                : null
                            } // Prevents selecting a time later than the End Time
                          />
                        </Grid>

                        {/* End Time */}
                        <Grid item xs={6}>
                          <CustomTimePicker
                            label={"End Time"}
                            name={`timeSlots.${index}.endTime`}
                            onError={(e) => {
                              formik.setFieldError(
                                `timeSlots.${index}.startTime`,
                                setMessage(e)
                              );
                              formik.setFieldTouched(
                                `timeSlots.${index}.startTime`,
                                true
                              );
                            }}
                            error={
                              formik.errors.timeSlots?.[index]?.endTime &&
                              formik.touched.timeSlots?.[index]?.endTime
                            }
                            errorMsg={formik.errors.timeSlots?.[index]?.endTime}
                            value={
                              formik.values?.timeSlots?.[index]?.endTime
                                ? dayjs(
                                  formik.values.timeSlots[index].endTime,
                                )
                                : null
                            }
                            onChange={(e) => {
                              setCustomErrors(false);
                              formik.setFieldValue(
                                `timeSlots.${index}.endTime`,
                                dayjs(e)
                              );
                              formik.setFieldTouched(
                                `timeSlots.${index}.endTime`,
                                true
                              );
                            }}
                            minTime={
                              formik.values?.timeSlots?.[index]?.startTime
                                ? dayjs(
                                  formik.values.timeSlots[index].startTime,
                                  "HH:mm"
                                )
                                : null
                            } // Prevents selecting a time earlier than the Start Time
                            maxTime={
                              index === formik.values?.timeSlots.length - 1
                                ? null
                                : formik.values?.timeSlots?.[index + 1]
                                  ?.startTime
                                  ? dayjs(
                                    formik.values.timeSlots[index + 1]
                                      .startTime,
                                    "HH:mm"
                                  )
                                  : null
                            }
                          />
                        </Grid>
                      </Grid>
                    );
                  })}
                </MuiBox>
              )}
            </FieldArray>
          </FormikProvider>
        </MuiBox>

        <MuiBox sx={{ textAlign: "center", mt: 5 }}>
          <Button
            type="submit"
            disable={Boolean(customErrors)}
            variant="lightButton"
            sx={{ maxWidth: "354px" }}
          >
            Done
          </Button>
        </MuiBox>
      </MuiBox>
    </form>
  );
};

export default AddScheduleModal;
